import CloseIcon from "@mui/icons-material/Close";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AddContactToExistingContactPointForm from "components/Forms/AddContactToExistingContactPointForm";
import CreateNewContactPointForm from "components/Forms/CreateNewContactPointForm";
import { optionsStudentContact } from "models/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

interface ModalContactPointProp {
  isOpen: boolean;
  onClose: Function;
  contactId: number;
  onSubmitModal?: () => void;
  options?: optionsStudentContact;
}

export default function ModalAddContactPoint({
  isOpen,
  onClose,
  contactId,
  onSubmitModal,
  options,
}: ModalContactPointProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isActiveAddToExistingListTab, setIsActiveAddToExistingListTab] = useState(true);
  const handleClose = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: "hidden", height: "auto" } }}
      >
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <PermContactCalendarIcon />
            <p>{t("add_contact_point")}</p>
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent className={classes.containerContent}>
          <div className={classes.containerHeaderForm}>
            <div
              className={`${classes.headerTextForm} ${
                isActiveAddToExistingListTab ? classes.activeHeaderTextForm : ""
              }`}
              onClick={() => setIsActiveAddToExistingListTab(true)}
            >
              {t("add_to_existing_contact_point")}
            </div>
            <div className={classes.headerDiviverForm}></div>
            <div
              className={`${classes.headerTextForm} ${
                !isActiveAddToExistingListTab ? classes.activeHeaderTextForm : ""
              }`}
              onClick={() => setIsActiveAddToExistingListTab(false)}
            >
              {t("create_new_contact_point")}
            </div>
          </div>
          {isActiveAddToExistingListTab && (
            <AddContactToExistingContactPointForm isOpen={isOpen} onClose={onClose} contactId={contactId} />
          )}
          {!isActiveAddToExistingListTab && (
            <CreateNewContactPointForm isOpen={isOpen} onClose={onClose} contactId={contactId} options={options} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
