import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  contentPage: {
    display: "flex",
    [theme.breakpoints.between("xs", "md")]: {
      flexDirection: "column",
    },
  },
  wrapLink: {
    backgroundColor: "#f5f5f5",
    border: "1px #DEDEDE solid",
    borderRadius: "4px",
    color: "#F2756F",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      margin: "10px 0",
      paddingLeft: "10px",
      maxWidth: "200px",
      minWidth: "150px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 10px",
      margin: "0 25px",
      maxWidth: "450px",
      minWidth: "400px",
    },
  },
  textEllipsis: {
    overflow: "hidden",
    wordBreak: "break-word",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  copyButton: {
    backgroundColor: "#6A7A83",
    borderRadius: "5px",
    [theme.breakpoints.between("xs", "md")]: {
      height: "24px",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "25px",
    },
  },
  wrapTitle: {
    minWidth: "200px",
    display: "flex",
    alignItems: "center",
  },
  wrapNotification: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      margin: "10px 0",
    },
  },
  wrapLinkAndButton: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
}));
