import { useStyles } from "./styles";

interface HeaderPageProp {
  title: string;
  children?: JSX.Element;
  className?: string;
}

export default function HeaderPage({ title, children, className }: HeaderPageProp) {
  const { classes } = useStyles();
  return (
    <div className={`${classes.container} ${className}`}>
      <p>{title}</p>
      {children}
    </div>
  );
}
