import Dropdown from "components/Dropdown";
import FilterTag from "components/FilterTag";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading/Loading";
import Table from "components/Table";
import SearchBar from "components/inputSearch";
import { LooseObject, optionsStudentTask } from "models/common";
import { useEffect, useRef, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "redux/store";
import {
  fetchStudentTaskList,
  deleteFieldSearch,
  initialSearchOptionListTask,
  setPageSize,
  updateSearch,
  updateSearchOption,
  getTaskResults,
  getTaskTypes,
  editTask,
  setContactsForTask,
  getStudentContactForOption,
  getUsers,
  initialOptions,
  getOptionsTypeForStudent,
  updateOptions,
} from "store/studentTaskListSlice";
import { formatSearchContent, sortTable } from "utils/helper";
import { LIST_TASK_STUDENT_COLUMN, ACTION_STUDENT_LIST, STUDENT_CONTACT_TYPE } from "./../../../../../utils/constant";
import { useStyles } from "./styles";
import ModalEditListTask from "components/Modal/ModalEditListTask";
import { toast } from "react-toastify";
import DropdownRadio from "components/DropdownRadio";

export default function StudentTasks() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tasks = useSelector((state: RootState) => state.studentTaskList.tasks);
  const page = useSelector((state: RootState) => state.studentTaskList.page);
  const total = useSelector((state: RootState) => state.studentTaskList.total);
  const pageSize = useSelector((state: RootState) => state.studentTaskList.limit);
  const taskResult = useSelector((state: RootState) => state.studentTaskList.taskResult);
  const taskType = useSelector((state: RootState) => state.studentTaskList.taskType);
  const users = useSelector((state: RootState) => state.studentTaskList.users);
  const searchOption = useSelector((state: RootState) => state.studentTaskList.searchOption);
  const allOptions = useSelector((state: RootState) => state.studentTaskList.options);
  const [options, setOptions] = useState<optionsStudentTask>(initialOptions);
  const [limit, setLimit] = useState<number>(pageSize);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [checkedList, setCheckedList] = useState<number[] | []>([]);
  const [isShowAction, setIsShowAction] = useState<boolean>(false);
  const [isShowClearAll, setIsShowClearAll] = useState<boolean>(false);
  const [data, setData] = useState<LooseObject[]>([]);
  const [isShowModalEditTask, setIsShowModalEditTask] = useState<boolean>(false);
  const [currentTask, setCurrentTask] = useState<LooseObject | null>(null);
  const [isSearchContact, setIsSearchContact] = useState<boolean>(false);

  const initialized = useRef(false);
  const getDataOption = async () => {
    setLoading(true);
    await dispatch(getTaskResults());
    await dispatch(getTaskTypes());
    await dispatch(getUsers(""));
    dispatch(getOptionsTypeForStudent());
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
      const firstFormGroup = document.querySelectorAll("#root")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    await dispatch(fetchStudentTaskList(page));
    setIsShowAction(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, page, pageSize, searchOption]);

  useEffect(() => {
    setData(tasks);
    setCheckedList([]);
  }, [tasks]);

  useEffect(() => {
    if (allOptions) {
      setOptions(allOptions);
    }
  }, [allOptions]);

  useEffect(() => {
    let check: boolean = false;
    if (searchOption) {
      Object.keys(searchOption).map((item) => {
        if (
          (Array.isArray(searchOption[item]) && searchOption[item].length !== 0) ||
          Object.keys(searchOption[item]).length !== 0
        ) {
          check = true;
          return null;
        }
        return null;
      });
    }
    setIsShowClearAll(check);
  }, [searchOption]);

  const handlePageClick = useCallback((page: number = 1) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    setCheckedList([]);
    setIsShowAction(false);
    dispatch(fetchStudentTaskList(page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckBox = useCallback((checkbox: number[]) => {
    setCheckedList(checkbox);
    if (checkbox.length > 0) {
      setIsShowAction(true);
    } else {
      setIsShowAction(false);
    }
  }, []);

  const handleSortTable = useCallback(
    async (field: string, type: string) => {
      const newData: LooseObject[] = sortTable(data, field, type);
      setData(newData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data]
  );

  const handleClickMenuItem = (row: LooseObject | string, field: string) => {
    if (field === "task_type_id") {
      dispatch(deleteFieldSearch("task_result_ids"));
    }
    dispatch(updateSearchOption(row, field));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleDeleteFieldSearch = useCallback((field: string) => {
    if (field === "text") {
      setSearchText("");
    }
    dispatch(deleteFieldSearch(field));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearAllTagSearch = () => {
    dispatch(updateSearch(initialSearchOptionListTask));
    let option = JSON.parse(JSON.stringify(options));
    option.result = [];
    dispatch(updateOptions(option));
    setSearchText("");
  };

  const handleSearchTask = () => {
    if (searchText === searchOption?.text) {
      return;
    }
    dispatch(updateSearchOption(searchText, "text"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleOpenModalEdit = useCallback((task: LooseObject) => {
    setCurrentTask(task);
    setIsShowModalEditTask(true);
  }, []);

  const handleEditTask = useCallback(async (id: number, data: LooseObject) => {
    setLoading(true);
    let newData = { ...data };
    newData.contact_type = STUDENT_CONTACT_TYPE;
    const response = await dispatch(editTask(id, newData));
    if (response) {
      toast.success<void>(t("edit_task_successful"));
      setIsShowModalEditTask(false);
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchContact = useCallback(async (text: string) => {
    setIsSearchContact(true);
    const contacts = await dispatch(getStudentContactForOption(text));
    if (contacts) {
      let listContact: LooseObject[] = [];
      contacts.forEach((item: LooseObject) => {
        listContact.push({ id: item?.id, label: item?.full_name });
      });
      dispatch(setContactsForTask(listContact));
    }
    setIsSearchContact(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLimit = useCallback(async (limit: number) => {
    setLimit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(async (page: number) => {
    handlePageClick(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetPageSize = useCallback(async (data: number) => {
    dispatch(setPageSize(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("student_reminder_list")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("student_reminder_list")} />
        <div>
          <div className={classes.contentPage}>
            <div className={classes.wrapSearchFilter}>
              <div className={classes.containerSearch}>
                <SearchBar
                  value={searchText}
                  className={classes.inputSearch}
                  handleChange={setSearchText}
                  onBlur={handleSearchTask}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === "Enter" || e.key === "Tab") {
                      handleSearchTask();
                      const target = e.target as HTMLButtonElement;
                      target.blur();
                    }
                  }}
                />
                <div className={classes.wrapDropdown}>
                  <DropdownRadio
                    title={t("task_type_id")}
                    className={classes.dropdown}
                    options={options.type ?? {}}
                    withSearch
                    onClickItem={handleClickMenuItem}
                    fieldSearch="task_type_id"
                    searchOption={searchOption}
                  />
                  <Dropdown
                    title={t("task_result_ids")}
                    className={classes.dropdown}
                    options={options.result ?? []}
                    withCheckbox
                    withSearch
                    onClickItem={handleClickMenuItem}
                    fieldSearch="task_result_ids"
                    searchOption={searchOption}
                  />
                </div>
              </div>
            </div>
            <div className={classes.containerFilter}>
              {Object.entries(searchOption).map(([category, options]) => {
                let text: any = category as any;
                if (Array.isArray(options) && options.length !== 0) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${formatSearchContent(options, t("or"))}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "string") {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "object" && "name" in options && options?.name) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options?.name}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                }
                return null;
              })}

              {isShowClearAll && (
                <div className={classes.clearAll} onClick={() => handleClearAllTagSearch()}>
                  <p>{t("clear_all")}</p>
                </div>
              )}
            </div>
            <div className={classes.results}>{isShowClearAll && `${total} ${t("results")}`}</div>
          </div>
          <div className={classes.contentPage}>
            <Table
              data={data}
              columns={LIST_TASK_STUDENT_COLUMN}
              total={total}
              limit={limit}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
              handleSortTable={handleSortTable}
              setPageSize={handleSetPageSize}
              handleCheckBox={handleCheckBox}
              isShowAction={isShowAction}
              actionMenu={ACTION_STUDENT_LIST as any[]}
              checkedList={checkedList}
              onClickRow={handleOpenModalEdit}
            />
          </div>
        </div>
      </div>
      {isShowModalEditTask && (
        <ModalEditListTask
          isOpen={isShowModalEditTask}
          title="edit_task"
          onClose={() => setIsShowModalEditTask(false)}
          onConfirm={handleEditTask}
          handleSearchContact={handleSearchContact}
          isSearchContact={isSearchContact}
          task={currentTask ?? {}}
          taskResult={taskResult ?? {}}
          taskType={taskType ?? {}}
          users={users ?? {}}
        />
      )}
    </>
  );
}
