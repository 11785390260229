import { createTheme } from "@mui/material";
import colors from "utils/colors";

declare module "@mui/material/styles" {
  interface Theme {
    card: {
      border: string;
      borderRadius: string;
      padding: string;
    };
  }

  interface ThemeOptions {
    card: {
      border: string;
      borderRadius: string;
      padding: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#8989",
    },
  },
  typography: {
    fontFamily: `"Roboto", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          backgroundColor: colors.selectedItem,
        },
      },
    },
  },
  card: {
    border: `1px solid ${colors.borderBlack}`,
    borderRadius: "12px",
    padding: "10px",
  },
});

export default theme;
