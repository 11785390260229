import { AssignmentOutlined, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "components/Buttons/BaseButton";
import CardCollapse from "components/CardCollapse";
import InputEmail from "components/InputEmail";
import Select from "components/Select";
import InputDate from "components/inputDate";
import { LooseObject } from "models/common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/store";
import { initialOptionsRegisterForm, optionsRegisterForm } from "store/registerFormSlice";
import { TableFields } from "types";
import { ADMISSION_FIELD, FIELD_STUDY_RESULT } from "utils";
import { useStyles } from "./styles";

interface GeneralInformationProp {
  control: any;
  register: any;
  watch: any;
  errors: any;
  handleChangeLocal: Function;
}

const GeneralInformation: React.FC<GeneralInformationProp> = (props) => {
  const { control, register, watch, errors, handleChangeLocal } = props;
  const { t } = useTranslation(["home", "register"]);
  const { classes } = useStyles();
  const theme = useTheme();

  const optionsStore = useSelector((state: RootState) => state.registerForm.options);
  const [options, setOptions] = useState<optionsRegisterForm>(initialOptionsRegisterForm);

  useEffect(() => {
    if (optionsStore) {
      setOptions(optionsStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsStore]);

  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const watchInfoAndResults = watch();

  const handleSaveGeneralInformation = () => {
    try {
      let data: LooseObject = {};
      ADMISSION_FIELD.forEach((item: LooseObject) => {
        data = {
          ...data,
          [item.name]: watchInfoAndResults[item.name],
        };
      });
      localStorage.setItem("generalInformation", JSON.stringify(data));
      toast.success<void>("Lưu thông tin chung vào bộ nhớ tạm thành công!");
    } catch (error) {
      toast.error<void>("Có lỗi xảy ra, vui lòng thử lại!");
    }
  };
  const handleSaveScore = () => {
    try {
      let dataScore: LooseObject = {};
      FIELD_STUDY_RESULT.forEach((grade) => {
        dataScore = {
          ...dataScore,
          [`${grade.performance}`]: watchInfoAndResults[`${grade.performance}`],
          [`${grade.conduct}`]: watchInfoAndResults[`${grade.conduct}`],
        };
      });
      localStorage.setItem("score", JSON.stringify(dataScore));
      toast.success<void>("Lưu kết quả học tập vào bộ nhớ tạm thành công!");
    } catch (error) {
      toast.error<void>("Có lỗi xảy ra, vui lòng thử lại!");
    }
  };

  return (
    <>
      <div className={classes.containerContent}>
        <div className={classes.containerCard}>
          <CardCollapse
            title={t("general_information").toUpperCase()}
            iconTittle={<InfoOutlined sx={{ color: "#fff", paddingRight: "5px" }} />}
            className={classes.headerCard}
            childrens={
              <div className={classes.containerCardContent}>
                <div className={classes.cardContent}>
                  {ADMISSION_FIELD.map((field: TableFields, indexL: number) => (
                    <React.Fragment key={`${indexL} - b`}>
                      <div className={`${classes.formControl}`}>
                        <div className={classes.label}>
                          {t(field.title, { ns: "home" })}
                          {field.required && <span className={classes.labelRequired}>*</span>}
                          {(field.title === "province_city" || field.title === "district") && (
                            <div>(Theo trường THPT)</div>
                          )}
                          {(field.title === "permanent_address") && (
                              <div>(Ghi đầy đủ để trường gửi giấy báo)</div>
                          )}
                        </div>
                        <div className={classes.wrapValueItem}>
                          {field.type === "email" ? (
                            <InputEmail nameRegisterEmail={field.name} control={control} />
                          ) : field.type === "select" ? (
                            <Select
                              options={options[field.option as keyof optionsRegisterForm]}
                              className={classes.inputSelect}
                              classNamePoper={classes.inputSelectPoper}
                              nameRegister={field.name}
                              control={control}
                              isForm
                              isFilterSelected={false}
                              disableClearable
                              onchange={handleChangeLocal}
                              isDisableSearch={isMobileScreen}
                            />
                          ) : field.type === "date" ? (
                            <InputDate
                              control={control}
                              nameRegister={field.name}
                              isPast={field?.isPast}
                              isFuture={field?.isFuture}
                              formatDate={"DD/MM/YYYY"}
                              isInputDateRegister
                            />
                          ) : field.title === "full_name" ? (
                            <input
                              className={classes.input}
                              style={{ textTransform: "capitalize" }}
                              type={field.type}
                              {...register(field.name)}
                            />
                          ) : field.title === "citizen_id" ? (
                            <input
                              className={classes.input}
                              min={0}
                              type={field.type}
                              {...register(field.name)}
                              inputMode="numeric"
                            />
                          ) : (
                            <input className={classes.input} type={field.type} {...register(field.name)} />
                          )}
                          <div className={classes.error}>{errors[field.name]?.message}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <Button
                  title={"Lưu vào bộ nhớ tạm"}
                  className={classes.btnsave}
                  onClick={handleSaveGeneralInformation}
                />
              </div>
            }
          />

          <CardCollapse
            title={t("academic_results").toUpperCase()}
            iconTittle={<AssignmentOutlined sx={{ color: "#fff", paddingRight: "5px" }} />}
            className={classes.headerCard}
            childrens={
              <div className={classes.containerCardContent}>
                <div className={classes.tableContainer}>
                  <TableContainer elevation={2} component={Paper} className={classes.table}>
                    <Table>
                      <TableHead>
                        <TableRow style={{ height: "30px" }}>
                          <TableCell colSpan={2} align="center" className={classes.grade}>
                            LỚP
                          </TableCell>
                          <TableCell align="center" className={classes.conduct}>
                            HẠNH KIỂM
                          </TableCell>
                          <TableCell align="center" className={classes.performance}>
                            HỌC LỰC
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {FIELD_STUDY_RESULT.map((item: LooseObject, index: number) => (
                          <TableRow key={`trường-${index}`}>
                            {item.grade && (
                              <TableCell
                                rowSpan={2}
                                className={`${
                                  item.field === 10
                                    ? classes.grade10
                                    : item.field === 11
                                    ? classes.grade11
                                    : classes.grade12
                                }`}
                              >
                                {item.grade}
                              </TableCell>
                            )}

                            {item.id === 6 ? (
                              <TableCell align="center" className={classes.semester}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    lineHeight: 1,
                                  }}
                                >
                                  {!isMobileScreen ? item.semester : item.mobileSemester}
                                  {!isMobileScreen && (
                                    <Typography variant="caption" color="error" style={{ lineHeight: 1 }}>
                                      ({t("can_be_left_blank_if_not_available")})
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                            ) : (
                              <TableCell align="center" className={classes.semester}>
                                {!isMobileScreen ? item.semester : item.mobileSemester}
                                <span className={classes.labelRequired}>*</span>
                              </TableCell>
                            )}
                            <TableCell align="center" className={classes.wrapSelectTable}>
                              <Select
                                  options={options.conduct}
                                  className={classes.inputSelect}
                                  classNamePoper={classes.inputSelectPoper}
                                  nameRegister={item.conduct}
                                  control={control}
                                  isForm
                                  isFilterSelected={false}
                                  disableClearable={item.id !== 6}
                                  isDisableSearch
                              />
                              {errors[item.performance]?.message && (
                                <div className={classes.errorScore}>{errors[item.performance]?.message}</div>
                              )}
                            </TableCell>
                            <TableCell align="center" className={classes.wrapSelectTable}>
                              <Select
                                  options={options.performance}
                                  className={classes.inputSelect}
                                  classNamePoper={classes.inputSelectPoper}
                                  nameRegister={item.performance}
                                  control={control}
                                  isForm
                                  isFilterSelected={false}
                                  disableClearable={item.id !== 6}
                                  isDisableSearch
                              />
                              {errors[item.conduct]?.message && (
                                <div className={classes.errorScore}>{errors[item.conduct]?.message}</div>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <Button title={"Lưu vào bộ nhớ tạm"} className={classes.btnsave} onClick={handleSaveScore} />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default GeneralInformation;
