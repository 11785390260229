import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
  },
  wrapContent: {
    backgroundColor: "#FAFAFA",
    borderRadius: "4px 4px 0 0",
  },
  wrapNodata: {
    backgroundColor: "#FAFAFA",
  },
  noData: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapContentLog: {
    backgroundColor: "#FDFDFD",
    border: "1px solid #C7D2DE",
    borderRadius: "4px",
    margin: "16px",
    padding: " 16px 0",
  },
  contentLog: {
    paddingLeft: "16px",
    minHeight: "300px",
    maxHeight: "800px",
    overflow: "auto",
  },
  addLog: {
    "& .ck .ck-content": {
      height: "auto !important",
      minHeight: "100px !important",
      maxHeight: "250px !important",
    },
    fontSize: "14px",
  },
}));
