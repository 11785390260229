import { Box, IconButton, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import QRCodeOnly from "../../../../assets/images/QR-Code-VJU.png";
import { isEmpty } from "lodash";
import CopyToClipboard from "components/Buttons/CopyToClipboard";
import { toast } from "react-toastify";
import { ContentCopyOutlined } from "@mui/icons-material";

interface IProps {
  admissionMethodId: string | number;
  aspirationShortedName: string;
  citizenId: string;
}

const PaymentInformation: React.FC<IProps> = (props) => {
  const { admissionMethodId, aspirationShortedName, citizenId } = props;
  const { t } = useTranslation(["register", "home"]);
  const { classes } = useStyles();

  const informationPay = useMemo(() => {
    return [
      { namePayField: "bank_name", value: t("bidv_vietnam_investment_and_development_joint_stock_company") },
      { namePayField: "branch_name", value: t("My_Dinh_branch") },
      { namePayField: "account_number", value: 2600816336 },
      { namePayField: "account_holder's_name", value: t("vietnam_japan_university") },
      {
        namePayField: "Transfer_note",
        value: `DKTSTT ${
          isEmpty(citizenId) ? "{Số CCCD}" : citizenId
        } ${aspirationShortedName?.toUpperCase()} ${admissionMethodId}`,
      },
      { namePayField: "fee", value: `${admissionMethodId === 1 ? "580.000" : "30.000"}đ` },
    ];
  }, [admissionMethodId, aspirationShortedName, t, citizenId]);

  return (
    <div className={classes.containerPayInformation}>
      <div className={classes.titlePay}>{t("transfer_information")}</div>
      <div className={classes.contentPay}>
        <Box className={classes.wrapInfoPayAndQRCode}>
          <Box>
            {informationPay.map((itemInfo) => (
              <div className={classes.wrapInformationPay} key={itemInfo.namePayField}>
                <div className={classes.namePayField}>{t(itemInfo.namePayField)}:</div>
                <div>
                  {itemInfo.namePayField === "Transfer_note" ? (
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="h6">{itemInfo.value}</Typography>
                      {!isEmpty(citizenId) && (
                        <CopyToClipboard
                          onCopy={() => {
                            toast.success<void>(t("home:copied"));
                          }}
                          value={itemInfo.value}
                        >
                          <IconButton size="small" sx={{ marginLeft: 2 }}>
                            <ContentCopyOutlined sx={{ color: "#fff" }} fontSize="small" />
                          </IconButton>
                        </CopyToClipboard>
                      )}
                    </Box>
                  ) : (
                    <Typography variant="body2" fontWeight="bold">
                      {itemInfo.value}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ height: "150px", width: "auto" }} src={QRCodeOnly} alt="qr-code" />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default PaymentInformation;
