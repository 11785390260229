import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo-school.svg";
import FooterRegister from "./FooterRegister";
import { useStyles } from "./styles";

const RegisterFormDisabled = () => {
    const { classes } = useStyles();
    const { t, i18n } = useTranslation(["register", "home"]);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const notiStyle:any = isMobileScreen ? {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '100px',
        paddingBottom: '100px',
        backgroundColor: 'rgb(163 75 75)',
        flexDirection: 'column',
        color: 'white',
        marginTop: '3rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
    } : {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '100px',
        paddingBottom: '100px',
        backgroundColor: 'rgb(163 75 75)',
        flexDirection: 'column',
        color: 'white',
        paddingLeft: '20rem',
        paddingRight: '20rem',
        marginTop: '3rem'
    }
    return (
        <div className={classes.container}>
            <Helmet>
                <title>{t("online_admission_application")}</title>
            </Helmet>
            <div className={classes.header}>
                <div className={classes.wrapHeader}>
                    <Link to="https://vju.ac.vn" target="blank">
                        <img src={logo} alt="logo" className={classes.logo} />
                    </Link>
                </div>
            </div>
            <div className={classes.content}>
                <Box mb={2} className={classes.banner}>
                    <Box className={classes.textBanner}>
                        <Typography color={"#fff"} variant={isMobileScreen ? "h5" : "h3"}>
                            {t("online_admission_application")}
                        </Typography>
                    </Box>
                </Box>
            </div>

            <Typography color={"black"} style={{...notiStyle}} variant={isMobileScreen ? "h5" : "h3"}>
                <h3>
                    ĐỢT TUYỂN SINH 2024 XIN CHÍNH THỨC ĐƯỢC KHÉP LẠI
                </h3>
                <p style={{margin: 0}}>
                    HẸN GẶP LẠI CÁC BẠN VÀO LẦN TUYỂN SINH NĂM TỚI
                </p>
            </Typography>
            <FooterRegister />
        </div>
    );
};

export default RegisterFormDisabled;
