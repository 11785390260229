import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface SearchBarProp {
  className?: string;
  value?: string;
  handleChange: Function;
  autoFocus?: boolean;
  onBlur?: Function;
  onKeyDown?: Function;
}

export default function SearchBar({ className, value, handleChange, autoFocus, onBlur, onKeyDown }: SearchBarProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <TextField
      className={`${classes.container} ${className}`}
      type="search"
      placeholder={`${t("search")}...`}
      autoComplete="off"
      value={value}
      autoFocus={autoFocus}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onBlur={() => onBlur && onBlur()}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => onKeyDown && onKeyDown(e)}
    />
  );
}
