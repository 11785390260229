import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { RootState } from "redux/store";

interface LandingPageReferralLinkState {
  links: any;
  labels: any;
  studentData: any;
  masterData: any;
}

const initialState: LandingPageReferralLinkState = {
  links: {},
  labels: {},
  studentData: {},
  masterData: {},
};

const landingPageReferralLinkSlice = createSlice({
  name: "landingPageReferralLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLandingPageReferralLink.fulfilled, (state, action) => {
      state.links = action.payload;
    });

    builder.addCase(getDataChartReferralLink.fulfilled, (state, action) => {
      state.labels = action.payload.labels;
      state.studentData = action.payload.student_data;
      state.masterData = action.payload.master_data;
    });
  },
});

export const getLandingPageReferralLink = createAsyncThunk(
  "landingPageReferralLink/getAll",
  async (id: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.landingPageReferralLink(id);
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDataChartReferralLink = createAsyncThunk(
  "landingPageReferralLink/getDataChart",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = API.chartContactReferralLink;
      const response = await axiosClient.get(url);
      if (response.status === 200) {
        return fulfillWithValue(response.data.data);
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export default landingPageReferralLinkSlice;
export const linksSelector = (state: RootState) =>
  state?.landingPageReferralLink?.links;
export const labelsSelector = (state: RootState) =>
  state?.landingPageReferralLink?.labels;
export const studentDataSelector = (state: RootState) =>
  state?.landingPageReferralLink?.studentData;
export const masterDataSelector = (state: RootState) =>
  state?.landingPageReferralLink?.masterData;
