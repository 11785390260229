import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";

export const useStyles = makeStyles()(() => ({
  title: {
    backgroundColor: "#4A4358",
    color: "#FFF",
    height: "40px",
    fontSize: "18px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "20px",
  },
  btnClose: {
    backgroundColor: "#4A4358",
    color: "#FFF",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    height: "30px",
  },
  btnYes: {
    backgroundColor: colors.btnPrimary,
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  btnNo: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "10px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    "@media(max-width: 425px)": {
      flexDirection: "column",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "150px",
    "& span": {
      color: "#F2756F",
    },
    "@media(max-width: 425px)": {
      minWidth: "unset",
    },
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "150px",
  },
  containerContent: {
    display: "flex",
    minHeight: "100px",
    flexDirection: "column",
    justifyContent: "center",
  },
  inputSelect: {
    width: "100%",
  },
}));
