import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width: 576px)": {
      flexDirection: "column",
      padding: "0 8px",
    },
  },
  wrapPage: {
    display: "flex",
    alignItems: "center",
    padding: "16px 0",
    "@media(max-width: 576px)": {
      padding: "10px 0",
    },
  },
  btnCancel: {
    backgroundColor: "#FFFFFF",
    color: colors.defaultColorText,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  btnChangePage: {
    backgroundColor: "#63727C",
    "&:hover": {
      backgroundColor: "#63727C",
    },
  },
  btnSave: {
    marginLeft: "30px",
    backgroundColor: "#63727C",
    "&:hover": {
      backgroundColor: "#63727C",
    },
  },
  content: {
    margin: "0 10px",
    color: "#868686",
    fontWeight: 500,
    fontSize: "14px",
  },
  wrapStart: {
    display: "flex",
    alignItems: "center",
    "@media(max-width: 576px)": {
      paddingTop: "10px",
    },
  },
  logo: {
    marginLeft: "7px",
  },
}));
