import { Box, Typography } from "@mui/material";
import BaseChart from "components/Charts";
import ReferralLinkForm from "components/Forms/ReferralLinkForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserInfo } from "redux/auth/auth.service";
import { useAppDispatch } from "redux/store";
import {
  getDataChartReferralLink,
  getLandingPageReferralLink,
  labelsSelector,
  linksSelector,
  masterDataSelector,
  studentDataSelector,
} from "store/landingPageReferralLinkSlice";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import colors from "utils/colors";

const LandingPageReferralLink = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { classes } = useStyles();
  const userInfo = getUserInfo();
  const [loading, setLoading] = useState<boolean>(false);

  const landingPageReferralLink = useSelector(linksSelector);
  const labelsChart = useSelector(labelsSelector);
  const studentDataChart = Object.values(useSelector(studentDataSelector));
  const masterDataChart = Object.values(useSelector(masterDataSelector));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: t("undergraduate_and_post_graduate"),
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        border: {
          color: colors.backgroundLight,
        },
        ticks: {
          stepSize: 1,
          beginAtZero: true,
        },
      },
    },
  };

  const dataChart = [
    {
      label: t("undergraduate"),
      data: studentDataChart,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      pointStyle: "dotted",
      pointRadius: 5,
      tension: 0.1,
    },
    {
      label: t("post_graduate"),
      data: masterDataChart,
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      pointStyle: "dotted",
      pointRadius: 5,
      tension: 0.1,
    },
  ];

  const getDataLandingPageReferralLink = async () => {
    setLoading(true);
    await dispatch(getLandingPageReferralLink(userInfo.user.id));
    await dispatch(getDataChartReferralLink());
    setLoading(false);
  };

  useEffect(() => {
    getDataLandingPageReferralLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const current = new Date();
  const month = current.getMonth();

  const totalMasterContact = (masterDataChart as number[]).reduce((sum, i) => sum + i, 0);
  const totalStudentContact = (studentDataChart as number[]).reduce((sum, i) => sum + i, 0);

  return (
    <Box>
      <Helmet>
        <title>{t("landing_page_referral_link")}</title>
      </Helmet>
      <Box padding={"20px"}>
        <Typography variant="h5">{t("landing_page_referral_link")}</Typography>
      </Box>
      <Box className={classes.contentPage}>
        <Box>
          <ReferralLinkForm
            title={t("undergraduate_link")}
            link={landingPageReferralLink.student_ref_link}
            contactMonthly={(studentDataChart[month] as number) || 0}
            totalContact={totalStudentContact}
          />
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <ReferralLinkForm
            title={t("post_graduate_link")}
            link={landingPageReferralLink.master_ref_link}
            contactMonthly={masterDataChart[month] as number}
            totalContact={totalMasterContact}
          />
        </Box>

        {!loading && (
          <Box className={classes.wrapChart}>
            <BaseChart type={"line"} options={options} datasets={dataChart} labels={Object.values(labelsChart)} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LandingPageReferralLink;
