import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "models/common";
import { AppDispatch } from "redux/store";
import { API } from "utils/api";
import { axiosClient } from "../services/axiosClient";

export interface RoleState {
  roles: LooseObject[];
  permissions: LooseObject[];
  page: number;
  total: number;
  limit: number;
  error?: string | null;
}

const initialState: RoleState = {
  roles: [],
  permissions: [],
  page: 1,
  total: 0,
  limit: 50,
  error: null,
};

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    fetchRolesSuccess: (state, action: PayloadAction<LooseObject>) => {
      state.roles = action.payload.data.data;
      state.page = action.payload.data.current_page;
      state.total = action.payload.data.total;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    getListPermissionSuccess: (state, action: PayloadAction<LooseObject[]>) => {
      state.permissions = action.payload;
    },
  },
});

export const { fetchRolesSuccess, setLimit, getListPermissionSuccess } = roleSlice.actions;

export const fetchRoles =
  (page: number = 1) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      const limit = getState().roles.limit;
      const paramsData = { page: page, limit: limit };
      const url: string = API.restfulRoles;

      const response = await axiosClient.get(url, {
        params: { ...paramsData },
      });

      if (response.status === 200) {
        dispatch(fetchRolesSuccess(response.data));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const getRoleById = (id: any) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url: string = API.getRoleById(id);
    const response = await axiosClient.get(url);

    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const setPageSize = (data: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLimit(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const getListPermissions = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url: string = API.listPermissions;
    const response = await axiosClient.get(url);

    if (response.status === 200) {
      dispatch(getListPermissionSuccess(response.data.data));
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const createRole = (role: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = API.createRole;
    const response = await axiosClient.post(url, role);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const editRole = (id: number, role: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = API.editRole(id);
    const response = await axiosClient.put(url, role);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default roleSlice;
