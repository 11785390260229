import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";

export const useStyles = makeStyles()(() => ({
  containerBtn: {
    display: "flex",
    justifyContent: "center"
  },
  btnCancel: {
    height: "31px",
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "51px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnSearch: {
    height: "31px",
    backgroundColor: colors.btnDark,
    "&:hover": {
      backgroundColor: colors.btnDark,
    },
  },
  containerForm: {
    paddingLeft: "12.5px"
  }
}));
