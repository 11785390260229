import { cloneDeep } from "lodash";
import { LooseObject } from "models/common";

export const showColumnAvailable = (columns: any, constantColumn: LooseObject[], selectedTab: any) => {
  const data: LooseObject[] = [];
  Array.isArray(columns.displayed) &&
    columns.displayed.forEach((item: string) => {
      const index: number = constantColumn.findIndex((element: LooseObject) => element.nameDisplay === item);
      if (index !== -1) {
        if (constantColumn[index]?.title === "full_name" && selectedTab === 3) {
          data.push({ ...constantColumn[index], type: "string" });
        } else {
          data.push(constantColumn[index]);
        }
      }
    });
  let action: LooseObject = cloneDeep(constantColumn[constantColumn.length - 1]);
  if (selectedTab === 3) {
    action = { ...action, show: ["restore", "bin"] };
  }
  data.push(action);
  return data;
};

export const getLink = (link: string, id: any) => link.replace("{id}", id);

export const getNameFromArray = (data: LooseObject[]) => {
  if (data.length === 1) {
    return data[0]?.name ?? data[0]?.contact_name;
  }
  let result = "";
  data.forEach((item: LooseObject) => {
    result = result.concat(", ", item?.name ?? item?.contact_name);
  });
  result = result.slice(1);
  return result;
};
