import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    marginBottom: "16px",
  },
  title: {
    borderRadius: "4px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#7C8286",
    "& p": {
      fontSize: "18px",
      fontWeight: 400,
      color: "#FFFFFF",
      marginLeft: "15px",
    },
    marginTop: "0px"
  },
  content: {
    borderRadius: "0 0 4px 4px",
    padding: "16px 16px",
    backgroundColor: "#FAFAFA",
  },
  titleText: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#413C51",
    marginBottom: "25px",
  }
}));
