import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Grid, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import BaseButton from "components/Buttons/BaseButton";
import CardCollapse from "components/CardCollapse";
import Loading from "components/Loading/Loading";
import ModalAddContactPoint from "components/Modal/ModalAddContactPoint";
import ModalAddContactToList from "components/Modal/ModalAddContactToList";
import ModalConfirm from "components/Modal/ModalConfirm";
import ModalEditContactList from "components/Modal/ModalEditContactList";
import ModalEditContactPoint from "components/Modal/ModalEditContactPoint";
import Pagination from "components/Pagination";
import PagingDetail from "components/PagingDetail";
import RoleBaseComponent from "components/RoleBaseComponent";
import { LooseObject, SelectDataProp, optionsStudentContact } from "models/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import { removeContactPoint, removeContactToList } from "store/actionContactSlice";
import contactCategorySlice, {
  IContactCategory,
  UnitTag,
  contactCategoryListSelector,
  fetchContactCategoriesByContactId,
  filterContactCategoryByContactIdSelector,
} from "store/contactCategorySlice";
import contactPointSlice, { IContactPoint, contactPointsSelector, fetchContactPoint } from "store/contactPointSlice";
import { setSelectedTab } from "store/detailStudentContactSlice";
import { getOptionsCountryForStudent, initialOptions } from "store/studentContactSlice";
import { STUDENT_CONTACT_TYPE, CALL_LOG_TYPE } from "utils/constant";
import { useContextIsFilterStudent } from "..";
import { useStyles } from "./styles";
import ModalSendSms from "components/Modal/ModalSendSms";
import ModalSendEmail from "components/Modal/ModalSendEmail";
import { setDataCallLog } from "store/callLogSlice";
import { actionButtonSelector, stateActionSupport } from "store/actionSupportSlice";

export default function ListContactPoint() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation();
  const stateActionSupport: stateActionSupport = useSelector(actionButtonSelector);
  const contactCategories = useSelector(contactCategoryListSelector);
  const filterContactList = useSelector(filterContactCategoryByContactIdSelector);
  const contactPoints = useSelector(contactPointsSelector);
  const navigate = useNavigate();
  const [deleteListId, setDeleteListId] = useState<number | null>(null);
  const [deleteContactPointId, setDeleteContactPointId] = useState<number | null>(null);
  const [limitContactList, setLimitContactList] = useState<number>(Number(filterContactList?.limit));
  const filterContactPoint = useSelector((state: RootState) => state.contactPoint.filterData);
  const [limitContactPoint, setLimitContactPoint] = useState<number>(Number(filterContactPoint?.limit));
  const [isOpenModalAddContactToList, setIsOpenModalAddContactToList] = useState<boolean>(false);
  const [isOpenModalAddContactPoint, setIsOpenModalAddContactPoint] = useState<boolean>(false);
  const [isOpenModalEditContactList, setIsOpenModalEditContactList] = useState<boolean>(false);
  const [isOpenModalEditContactPoint, setIsOpenModalEditContactPoint] = useState<boolean>(false);
  const [contact, setContact] = useState<LooseObject>({});
  const currentContact = useSelector((state: RootState) => state.detailStudentContact.contact);
  const [contactCategoryId, setContactCategoryId] = useState<number>(0);
  const [contactPointId, setContactPointId] = useState<number>(0);
  const [isFilter, setIsFilter] = useContextIsFilterStudent();
  const allOptions = useSelector((state: RootState) => state.studentcontacts.options);
  const [options, setOptions] = useState<optionsStudentContact>(initialOptions);

  const [openDialogSendSms, setOpenDialogSendSms] = useState(false);
  const [dataSendSms, setDataSendSms] = useState<SelectDataProp[]>([]);
  const [openDialogSendEmail, setOpenDialogSendEmail] = useState(false);
  const [dataSendEmail, setDataSendEmail] = useState<SelectDataProp[]>([]);

  useEffect(() => {
    if (state?.isFilter) {
      setIsFilter(state?.isFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (allOptions) {
      setOptions({ ...allOptions });
    }
  }, [allOptions]);

  useEffect(() => {
    if (currentContact) {
      setContact(currentContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContact]);

  useEffect(() => {
    dispatch(setSelectedTab("list_contact_point"));
    dispatch(getOptionsCountryForStudent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const contactId = Number(id);
    if (Number.isInteger(contactId) && contactId > 0) {
      setLoading(true);
      dispatch<any>(contactCategorySlice.actions.resetFilter());
      dispatch<any>(contactCategorySlice.actions.setTypeContactCategory(STUDENT_CONTACT_TYPE));
      dispatch<any>(contactCategorySlice.actions.setContactId(contactId));
      dispatch<any>(fetchContactCategoriesByContactId());
      dispatch<any>(contactPointSlice.actions.resetFilter());
      dispatch<any>(contactPointSlice.actions.setContactId(contactId));
      dispatch<any>(fetchContactPoint());
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlePageNumberContactListClick = async (page: number = 1) => {
    setLoading(true);
    await dispatch<any>(contactCategorySlice.actions.setPage(page));
    await dispatch<any>(fetchContactCategoriesByContactId());
    setLoading(false);
  };

  const handlePageNumberContactPointClick = async (page: number = 1) => {
    setLoading(true);
    await dispatch<any>(contactPointSlice.actions.setPage(page));
    await dispatch<any>(fetchContactPoint());
    setLoading(false);
  };

  const handleShowModalDeleteList = (id: number) => {
    setDeleteListId(id);
  };

  const handleShowModalDeleteContactPoint = (id: number) => {
    setDeleteContactPointId(id);
  };

  const handleDeleteList = async () => {
    setLoading(true);
    const result = await dispatch<any>(
      removeContactToList({
        id: id as unknown as number,
        contact_category_ids: [deleteListId as unknown as number],
      })
    );

    if (result?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("delete_student_contact_successful"));
      await dispatch<any>(contactCategorySlice.actions.resetFilter());
      await dispatch<any>(fetchContactCategoriesByContactId());
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
    setDeleteListId(null);
  };

  const handleDeleteContactPoint = async () => {
    setLoading(true);
    const dataRemoveContactPoint = {
      contact_id: parseInt(id ?? ""),
      contact_point_ids: [parseInt(deleteContactPointId?.toString() ?? "")],
    };
    const result = await dispatch<any>(removeContactPoint(dataRemoveContactPoint));

    if (result?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("delete_student_contact_successful"));
      await dispatch<any>(contactPointSlice.actions.resetFilter());
      await dispatch<any>(fetchContactPoint());
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
    setDeleteContactPointId(null);
  };

  const setPageSizeContactList = async (limit: number) => {
    setLoading(true);
    dispatch(contactCategorySlice.actions.setLimit(limit));
    await dispatch<any>(fetchContactCategoriesByContactId());
    setLoading(false);
  };

  const setPageSizeContactPoint = async (limit: number) => {
    setLoading(true);
    dispatch(contactPointSlice.actions.setLimit(limit));
    await dispatch<any>(fetchContactPoint());
    setLoading(false);
  };

  const getUnitTag = (item: UnitTag[]) => {
    return item?.map((item: UnitTag) => item?.name || ", ").join(", ");
  };

  const handleOpenModalCallLog = async (row: LooseObject) => {
    const dataCallLog = {
      isOpenModal: true,
      contactId: row?.id,
      user: {
        full_name: row?.full_name,
        phone_number: row?.phone_number,
      },
      type: CALL_LOG_TYPE[2].value,
    };
    dispatch(setDataCallLog(dataCallLog));
  };

  const handleOpenDialogSendSms = (row: LooseObject) => {
    setOpenDialogSendSms(true);
    setDataSendSms([{ id: row?.id, name: row?.phone_number }]);
  };

  const handleCloseDialogSendSms = () => {
    setOpenDialogSendSms(false);
  };

  const handleOpenDialogSendEmail = (row: LooseObject) => {
    setOpenDialogSendEmail(true);
    setDataSendEmail([{ id: row?.id, name: row?.email }]);
  };

  const handleCloseDialogSendEmail = () => {
    setOpenDialogSendEmail(false);
  };

  return (
    <>
      <div className={classes.containerContent}>
        {loading && <Loading />}
        <CardCollapse
          title={t("contact_point")}
          className={classes.headerCard}
          childrens={
            <div className={classes.containerData + " " + classes.mbCard}>
              <RoleBaseComponent permissions={["student_contact_edit"]}>
                <div>
                  <BaseButton
                    className={classes.addBtn}
                    title={t("add_contact_point")}
                    startIcon={<AddIcon />}
                    onClick={() => setIsOpenModalAddContactPoint(true)}
                  />
                </div>
              </RoleBaseComponent>
              <Grid
                container
                style={{ gap: 20 }}
                className={classes.containerCardContent}
                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
              >
                {contactPoints?.length > 0
                  ? contactPoints?.map((item: IContactPoint) => (
                      <Grid item xs={4} sm={3} md={3} lg={2} key={"contact_points" + item.id}>
                        <div className={classes.itemContentContainer}>
                          <div className={classes.itemContent}>
                            <div className={classes.itemHeader}>{item.full_name}</div>
                            <div className={classes.itemSource}>{`${t("relationship")} : ${
                              item.relationship ?? ""
                            }`}</div>
                            <div className={classes.itemAttribute}>{`${t("email")} : ${item.email ?? ""}`}</div>
                            <div className={classes.itemAttribute}>{`${t("phone_number")} : ${
                              item.phone_number ?? ""
                            }`}</div>
                            <div className={classes.itemAttribute}>{`${t("address")} : ${item.address ?? ""}`}</div>
                          </div>
                          <RoleBaseComponent permissions={["student_contact_edit"]}>
                            <div className={classes.wrapBtn}>
                              <div className={classes.horizontalIcons}>
                                <div className={classes.iconContainer}>
                                  <IconButton
                                    className={classes.editBtn}
                                    aria-label="edit"
                                    onClick={() => {
                                      setIsOpenModalEditContactPoint(true);
                                      setContactPointId(item?.id as unknown as number);
                                    }}
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                  <IconButton
                                    className={classes.deleteBtn}
                                    aria-label="delete"
                                    onClick={() => handleShowModalDeleteContactPoint(item?.id as unknown as number)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </div>
                                <div className={classes.verticalIcons}>
                                  <div
                                    className={`${classes.wrapIconEmail} ${
                                      !stateActionSupport.enableSendSms && !stateActionSupport.enableCallLog
                                        ? classes.pbEmail
                                        : ""
                                    }`}
                                  >
                                    {stateActionSupport.enableSendEmail && (
                                      <IconButton
                                        aria-label="email"
                                        onClick={() => handleOpenDialogSendEmail(item ?? {})}
                                      >
                                        <span className={`${classes.iconMenu} ${classes.iconEmail}`} />
                                      </IconButton>
                                    )}
                                  </div>

                                  <div className={classes.horizontalIconSmsPhone}>
                                    {stateActionSupport.enableSendSms && (
                                      <IconButton aria-label="sms" onClick={() => handleOpenDialogSendSms(item ?? {})}>
                                        <span className={`${classes.iconMenu} ${classes.iconSms}`} />
                                      </IconButton>
                                    )}
                                    {stateActionSupport.enableCallLog && (
                                      <IconButton aria-label="phone" onClick={() => handleOpenModalCallLog(item ?? {})}>
                                        <span className={`${classes.iconMenu} ${classes.iconCall}`} />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </RoleBaseComponent>
                        </div>
                      </Grid>
                    ))
                  : t("no_data")}
              </Grid>
              <Pagination
                totalRecords={filterContactPoint?.total || 0}
                pageSize={Number(filterContactPoint?.limit)}
                limit={limitContactPoint}
                currentPage={Number(filterContactPoint?.page)}
                onChangePage={handlePageNumberContactPointClick}
                onChangeLimit={(limitList: number) => {
                  setLimitContactPoint(limitList);
                }}
                onSetPageSize={(limit: number) => {
                  setPageSizeContactPoint(limit);
                }}
              />
            </div>
          }
        />
        <CardCollapse
          title={t("lists")}
          childrens={
            <div className={classes.containerData + " " + classes.mbCard}>
              <RoleBaseComponent permissions={["student_contact_edit"]}>
                <div>
                  <BaseButton
                    className={classes.addBtn}
                    title={t("add_contact_to_list")}
                    startIcon={<AddIcon />}
                    onClick={() => setIsOpenModalAddContactToList(true)}
                  />
                </div>
              </RoleBaseComponent>
              <Grid
                container
                style={{ gap: 20 }}
                className={classes.containerCardContent}
                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
              >
                {contactCategories?.data?.length > 0
                  ? contactCategories?.data?.map((item: IContactCategory) => (
                      <Grid item xs={4} sm={3} md={3} lg={2} key={"contact_category" + item.id}>
                        <div className={classes.itemContentContainer}>
                          <div className={classes.itemContent}>
                            <Tooltip title={`${item?.name}`}>
                              <div className={classes.itemHeader}>{item?.name}</div>
                            </Tooltip>
                            <Tooltip title={`${t("source")} : ${item?.source}`}>
                              <div className={classes.itemSource}>
                                {t("source")}: {item?.source}
                              </div>
                            </Tooltip>
                            <Tooltip title={`${t("unit")}: ${getUnitTag(item?.unit_tags || [])}`}>
                              <div className={classes.itemAttribute}>
                                {t("unit")}: {getUnitTag(item?.unit_tags || [])}
                              </div>
                            </Tooltip>
                            <div className={classes.itemAttribute}>
                              {t("created_date")}: {item?.created_date}
                            </div>
                          </div>
                          <div className={classes.iconContainer}>
                            <RoleBaseComponent permissions={["student_list_edit"]}>
                              <IconButton
                                className={classes.editBtn}
                                aria-label="edit"
                                onClick={() => {
                                  setIsOpenModalEditContactList(true);
                                  setContactCategoryId(item?.id as unknown as number);
                                }}
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </RoleBaseComponent>
                            <RoleBaseComponent permissions={["student_contact_edit"]}>
                              <IconButton
                                className={classes.deleteBtn}
                                aria-label="delete"
                                onClick={() => handleShowModalDeleteList(item?.id as unknown as number)}
                              >
                                <CancelIcon />
                              </IconButton>
                            </RoleBaseComponent>
                          </div>
                        </div>
                      </Grid>
                    ))
                  : t("no_data")}
              </Grid>
              <Pagination
                totalRecords={filterContactList?.total || 0}
                pageSize={Number(filterContactList?.limit)}
                limit={limitContactList}
                currentPage={filterContactList?.page || 0}
                onChangePage={handlePageNumberContactListClick}
                onChangeLimit={(limitList: number) => {
                  setLimitContactList(limitList);
                }}
                onSetPageSize={(limit: number) => {
                  setPageSizeContactList(limit);
                }}
              />
            </div>
          }
        />

        <ModalAddContactToList
          isOpen={isOpenModalAddContactToList}
          onClose={() => setIsOpenModalAddContactToList(false)}
          contactId={parseInt(id || "")}
        />
        <ModalAddContactPoint
          isOpen={isOpenModalAddContactPoint}
          onClose={() => setIsOpenModalAddContactPoint(false)}
          contactId={Number(id)}
          options={options}
        />
        <ModalEditContactList
          isOpen={isOpenModalEditContactList}
          onClose={() => setIsOpenModalEditContactList(false)}
          contactCategoryId={contactCategoryId}
        />

        <ModalEditContactPoint
          isOpen={isOpenModalEditContactPoint}
          onClose={() => setIsOpenModalEditContactPoint(false)}
          contactPointId={contactPointId}
          options={options}
        />
        {deleteListId && (
          <ModalConfirm
            isOpen={deleteListId ? true : false}
            title={t("confirmation")}
            htmlContent={<p>{t("are_you_sure_to_remove")}</p>}
            onClose={() => {
              setDeleteListId(null);
            }}
            onConfirm={handleDeleteList}
          />
        )}

        {deleteContactPointId && (
          <ModalConfirm
            isOpen={deleteContactPointId ? true : false}
            title={t("confirmation")}
            htmlContent={<p>{t("are_you_sure_to_remove")}</p>}
            onClose={() => {
              setDeleteContactPointId(null);
            }}
            onConfirm={handleDeleteContactPoint}
          />
        )}
      </div>
      {contact.id && (
        <PagingDetail
          current={contact?.index + 1}
          total={contact?.total}
          onClickBack={() => navigate("/studentContact")}
          onClickPrevious={() =>
            navigate(`/studentContact/${contact?.prev_id}/listContactPoint`, {
              state: { isFilter: isFilter },
            })
          }
          onClickNext={() =>
            navigate(`/studentContact/${contact?.next_id}/listContactPoint`, {
              state: { isFilter: isFilter },
            })
          }
        />
      )}
      {openDialogSendSms && (
        <ModalSendSms isOpen={openDialogSendSms} onClose={handleCloseDialogSendSms} dataSendSms={dataSendSms} />
      )}

      {openDialogSendEmail && (
        <ModalSendEmail
          isOpen={openDialogSendEmail}
          onClose={handleCloseDialogSendEmail}
          dataSendEmail={dataSendEmail}
        />
      )}
    </>
  );
}
