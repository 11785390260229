import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Chip from "components/Buttons/Chip";
import Select from "components/Select";
import InputDateTime from "components/inputDateTime";
import { LooseObject } from "models/common";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { getUsers } from "store/taskSlice";
import * as Yup from "yup";
import { StringSchema } from "yup";
import BaseButton from "../../Buttons/BaseButton";
import { useStyles } from "./styles";
import { getUserInfo, hasRoles } from "redux/auth/auth.service";
import { TASK_TYPE } from "utils";

interface ModalTaskProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  handleSearchContact: Function;
  isSearchContact: boolean;
  title: any;
  contact: LooseObject[];
  task?: LooseObject;
  setListContactUpdate?: Function;
  listId?: number[];
}

export default function ModalTask({
  isOpen,
  onClose,
  onConfirm,
  title,
  handleSearchContact,
  isSearchContact,
  contact,
  task,
  setListContactUpdate,
  listId,
}: ModalTaskProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const taskType = useSelector((state: RootState) => state.tasks.taskType);
  const taskResult = useSelector((state: RootState) => state.tasks.taskResult);
  const users = useSelector((state: RootState) => state.tasks.users);
  const [listUsers, setListUsers] = useState<LooseObject[]>(users);
  const contactsStore = useSelector((state: RootState) => state.tasks.contacts);
  const [isSearchUser, setIsSearchUser] = useState<boolean>(false);
  const [contacts, setContacts] = useState<LooseObject[]>([]);
  const [result, setResult] = useState<LooseObject[]>([]);
  const [type, setType] = useState<number>(1);
  const refBtnSubmit = useRef<HTMLButtonElement>(null);
  const userInfo = getUserInfo();
  const isAdmin = hasRoles(["Super Admin", "Administration"]);

  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t("this_field_is_required"))
      .max(255, t("this_field_must_not_exceed_255_characters")),
    type: Yup.number().required(t("this_field_is_required")).typeError(t("this_field_must_be_a_number")),
    result: Yup.number().required(t("this_field_is_required")).typeError(t("this_field_must_be_a_number")),
    assigned_to: Yup.number().required(t("this_field_is_required")).typeError(t("this_field_must_be_a_number")),
    description: Yup.string()
      .when(["type"], {
        is: (type: number) => type === TASK_TYPE,
        then: (schema: StringSchema) => schema.required(t("this_field_is_required")),
        otherwise: (schema: StringSchema) => schema.notRequired(),
      })
      .max(255, t("this_field_must_not_exceed_255_characters")),
    deadline_at: Yup.date().required(t("this_field_is_required")),
    contact_ids: Yup.array().min(1, t("this_field_is_required")).required(t("this_field_is_required")),
  });
  type FilterSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FilterSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getDataOption = async () => {
    await dispatch(getUsers(""));
    if (!task) {
      handleSearchContact("");
    }
  };

  useEffect(() => {
    getDataOption();
    if (!task) {
      setValue("type", 1);
      setValue("contact_ids", listId && listId.length > 0 ? listId : contact);
      setValue("result", 1);
      let listResult: LooseObject[] = [...taskResult];
      let type = getValues("type");
      listResult = listResult.filter((item: LooseObject) => item?.task_type_id === type);
      setResult(listResult);
    } else {
      setValue("type", task?.type?.id);
      setValue(
        "contact_ids",
        task?.tasks_in_group.map((item: LooseObject) => ({ id: item?.contact_id, label: item?.contact_name }))
      );
      setValue("result", task?.result?.id);
      setType(task?.result?.id);
      setValue("name", task?.name);
      let date = String(task?.deadline_at).replace("-", "");
      setValue("deadline_at", moment(date, "YYYY-MM-DD HH:mm").toDate());
      setValue("assigned_to", task?.assigned_to?.id);
      task?.description && setValue("description", task?.description);
      let listResult: LooseObject[] = [...taskResult];
      listResult = listResult.filter((item: LooseObject) => item?.task_type_id === task?.type?.id);
      setResult(listResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let list: LooseObject[] = [];
    if (task) {
      list.push(task?.assigned_to);
    }
    if (Array.isArray(users) && users.length !== 0) {
      users.forEach((item: LooseObject) => {
        if (task && task?.assigned_to?.id === item?.id) {
          return;
        }
        list.push({ id: item?.id, name: item?.name });
      });
    }
    setListUsers(process.env.REACT_APP_VJU_WORKFLOW === "true" ? list : (isAdmin ? list : [{ id: userInfo.user.id, name: userInfo.user.name }]));
  }, [users, task, isAdmin, userInfo.user.id, userInfo.user.name]);

  useEffect(() => {
    let option: LooseObject[] = [];
    const selectedContact: LooseObject[] = getValues("contact_ids");
    selectedContact.forEach((item: LooseObject) => {
      if (contactsStore.findIndex((element: LooseObject) => element?.id === item?.id) === -1) {
        option.push(item);
      }
    });

    setContacts([...contactsStore, ...option]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsStore]);

  const handleSearchUser = async (text: string) => {
    setIsSearchUser(true);
    await dispatch(getUsers(text));
    setIsSearchUser(false);
  };

  const handleOnchangeSelect = (name: string, data: any, reason: string) => {
    if (name === "contact_ids" && setListContactUpdate && reason === "selectOption") {
      setListContactUpdate(data);
    } else if (name === "contact_ids" && setListContactUpdate && reason === "removeOption ") {
      const list = contact.filter((item: LooseObject) => item?.id !== data?.option?.id);
      setListContactUpdate(list);
    }
    if (name === "contact_ids" && reason === "clear") {
      setValue("contact_ids", []);
    } else if (name === "type") {
      let listResult: LooseObject[] = [...taskResult];
      listResult = listResult.filter((item: LooseObject) => item?.task_type_id === data?.id);
      setResult(listResult);
      setType(listResult[0]?.id);
      setValue("result", listResult[0]?.id);
    }
  };

  const handleChangeResult = (idResult: number) => {
    setType(idResult);
    setValue("result", idResult);
  };

  const handleSubmitModal = async (data: LooseObject) => {
    const formData: LooseObject = { ...data };
    formData.deadline_at = moment(formData?.deadline_at).format("YYYY-MM-DD HH:mm");
    formData.contact_ids = data?.contact_ids.map((item: LooseObject) => item?.id);
    if (task) {
      delete formData["contact_ids"];
      formData.contact_id = task?.contact_id;
      onConfirm(task?.id, formData);
    } else {
      onConfirm(formData);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        className={classes.container}
        scroll={"paper"}
      >
        <div className={classes.title}>
          <div className={classes.wrapLabelTitle}>
            <span className={classes.iconcheckList} /> {t(title).toUpperCase()}
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers>
          <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
            <div className={classes.formControl}>
              <div className={`${classes.label}`}>
                {`${t("task_name")} `}
                <span>*</span>
              </div>
              <input className={classes.input} type="text" {...register("name")} />
            </div>
            {errors["name"]?.message && <div className={classes.error}>{errors["name"]?.message}</div>}
            <div className={classes.formControl}>
              <div className={`${classes.label}`}>
                {`${t("type")} `}
                <span>*</span>
              </div>
              <Select
                options={taskType ?? []}
                className={classes.inputSelect}
                nameRegister={"type"}
                control={control}
                isForm
                disableClearable
                onchange={handleOnchangeSelect}
                isFilterSelected={false}
              />
            </div>
            {errors["type"]?.message && <div className={classes.error}>{errors["type"]?.message}</div>}
            <div className={classes.formControl}>
              <div className={`${classes.label}`}>
                {`${t("deadline")} `}
                <span>*</span>
              </div>
              <InputDateTime control={control} nameRegister={"deadline_at"} />
            </div>
            {errors["deadline_at"]?.message && <div className={classes.error}>{errors["deadline_at"]?.message}</div>}
            <div className={classes.formControl}>
              <div className={`${classes.label}`}>
                {`${t("assigned_to")} `}
                <span>*</span>
              </div>
              <Select
                options={listUsers ?? []}
                className={classes.inputSelect}
                nameRegister={"assigned_to"}
                control={control}
                isForm
                onChangeSearch={handleSearchUser}
                isSearching={isSearchUser}
              />
            </div>
            {errors["assigned_to"]?.message && <div className={classes.error}>{errors["assigned_to"]?.message}</div>}
            <div className={classes.formControl}>
              <div className={`${classes.label}`}>
                {`${t("contact")} `}
                <span>*</span>
              </div>
              <Select
                options={contacts ?? []}
                className={classes.inputSelect}
                nameRegister={"contact_ids"}
                control={control}
                isMultiple
                isForm
                isObjectSubmit
                onChangeSearch={handleSearchContact}
                isSearching={isSearchContact}
                onchange={handleOnchangeSelect}
                isDisabled={(listId && listId.length > 0) || task ? true : false}
                isHideTag={listId && listId.length > 0}
              />
            </div>
            {errors["contact_ids"]?.message && <div className={classes.error}>{errors["contact_ids"]?.message}</div>}
            <div className={classes.formControl}>
              <div className={`${classes.labelResult}`}>
                {`${t("result")} `}
                <span>*</span>
              </div>
              <div className={classes.wrapResult}>
                {result.map((item: LooseObject) => {
                  return (
                    <Chip
                      label={item?.name}
                      handleClick={() => handleChangeResult(item?.id)}
                      key={`${item.id} - a`}
                      isActive={item.id === type}
                    />
                  );
                })}
              </div>
            </div>
            {errors["result"]?.message && <div className={classes.error}>{errors["result"]?.message}</div>}
            <div className={classes.formControl}>
              <div className={`${classes.label}`}>
                {t("description")}
                {errors["description"]?.type === "required" && <span>*</span>}
              </div>
              <textarea rows={7} className={classes.inputArea} {...register("description")}></textarea>
            </div>
            {errors["description"]?.message && <div className={classes.error}>{errors["description"]?.message}</div>}
            <button type="submit" className={classes.hidden} ref={refBtnSubmit}></button>
          </form>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton
            title={t("cancel")}
            className={classes.btnNo}
            onClick={() => {
              onClose();
            }}
          />
          <BaseButton
            title={t("save")}
            className={classes.btnYes}
            onClick={() => refBtnSubmit.current && refBtnSubmit.current.click()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
