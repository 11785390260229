import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useTranslation } from "react-i18next";
import BaseButton from "./../Buttons/BaseButton/index";
import { useStyles } from "./styles";
interface PagingDetailProp {
  current: number;
  total: number;
  onClickBack: Function;
  onClickPrevious: Function;
  onClickNext: Function;
  isShowBtnSave?: boolean;
}

export default function PagingDetail({
  current,
  total,
  onClickBack,
  onClickPrevious,
  onClickNext,
  isShowBtnSave,
}: PagingDetailProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.wrapStart}>
        <BaseButton title={t("back")} className={classes.btnCancel} onClick={() => onClickBack()} />
        {isShowBtnSave && <BaseButton title={t("save")} className={classes.btnSave} isSubmit />}
      </div>
      <div className={classes.wrapPage}>
        <BaseButton
          title={t("previous")}
          className={classes.btnChangePage}
          startIcon={<ArrowBackIosNewRoundedIcon />}
          isDisabled={current === 1}
          onClick={onClickPrevious}
        />
        <p className={classes.content}>{`${current} ${t("of")} ${total}`} </p>
        <BaseButton
          title={t("next")}
          className={classes.btnChangePage}
          endIcon={<ArrowForwardIosRoundedIcon />}
          isDisabled={current === total}
          onClick={onClickNext}
        />
      </div>
    </div>
  );
}
