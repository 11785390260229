import { yupResolver } from "@hookform/resolvers/yup";
import InputTextForm from "components/Forms/InputTextForm";
import Loading from "components/Loading/Loading";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import * as Yup from "yup";
import { useStyles } from "./styles";
import { Dialog, DialogContent } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import contactPointSlice, {
  fetchContactPoint,
  getContactPointById,
  updateContactPointById,
} from "store/contactPointSlice";
import { toast } from "react-toastify";
import ButtonSaveForm from "components/Forms/ButtonSaveForm";
import ButtonCancelForm from "components/Forms/ButtonCancelForm";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { optionsStudentContact } from "models/common";
import { removeCharsFromStart } from "utils/helper";
import { INIT_COUNTRY_ISO_VN_DEFAULT } from "utils";

interface ModalFilterProp {
  isOpen: boolean;
  onClose: Function;
  contactPointId: number;
  onSubmitModal?: () => void;
  options?: optionsStudentContact;
}

export default function ModalEditContactPoint({
  isOpen,
  onClose,
  contactPointId,
  onSubmitModal,
  options,
}: ModalFilterProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);
  const contactPoint = useSelector((state: RootState) => state.contactPoint?.contactPoint);

  useEffect(() => {
    if (isOpen) {
      clearErrors();
      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
      dispatch(contactPointSlice.actions.resetErrorState());
      dispatch(getContactPointById(contactPointId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const validationSchema = Yup.object().shape(
    {
      id: Yup.number().required(),
      full_name: Yup.string().required(t("name_is_required")).max(255, t("name_must_not_exceed_255_characters")),
      email: Yup.string()
        .ensure()
        .when("phone_number", {
          is: "",
          then: (schema: Yup.StringSchema) =>
            schema
              .required(t("please_enter_either_an_email_or_a_phone_number"))
              .email(t("email_is_invalid"))
              .max(255, t("email_must_not_exceed_255_characters")),
          otherwise: (schema: Yup.StringSchema) => schema,
        }),
      phone_number: Yup.string()
        .ensure()
        .when("email", {
          is: "",
          then: (schema: Yup.StringSchema) =>
            schema
              .test("is-Phone", t("this_field_is_required"), function (value) {
                const { phone_number, country_iso } = this.parent;
                return !!phone_number && !!country_iso;
              })
              .required(t("please_enter_either_an_email_or_a_phone_number"))
              .max(15, t("phone_is_invalid")),
          otherwise: (schema: Yup.StringSchema) => schema,
        }),
      country_iso: Yup.string()
        .ensure()
        .when("phone_number", {
          is: "",
          then: (schema: Yup.StringSchema) => schema,
          otherwise: (schema: Yup.StringSchema) => schema.required(t("phone_is_invalid")),
        }),
      relationship: Yup.string().nullable().max(255, t("this_field_must_not_exceed_255_characters")),
      address: Yup.string().nullable().max(255, t("this_field_must_not_exceed_255_characters")),
    },
    [
      ["email", "phone_number"],
      ["country_iso", "phone_number"],
    ]
  );

  const errorFromApis = useSelector((state: RootState) => state.contactPoint.errors);

  type EditContactPointSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    reset,
    clearErrors,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm<EditContactPointSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: contactPointId,
      full_name: "",
      phone_number: "",
      email: "",
      relationship: "",
      address: "",
      country_iso: "",
    },
  });

  useEffect(() => {
    if (contactPoint?.country_iso) {
      let phone = removeCharsFromStart(
        String(Number(contactPoint?.phone_number)),
        String(contactPoint?.country_iso?.phone_code)
      );
      setValue("country_iso", contactPoint?.country_iso?.iso);
      setValue("phone_number", phone);
    } else {
      setValue("phone_number", contactPoint?.phone_number ?? "");
      setValue("country_iso", "");
    }
    if (!contactPoint?.country_iso) {
      setValue("country_iso", INIT_COUNTRY_ISO_VN_DEFAULT);
    }
    setValue("full_name", contactPoint?.full_name);
    setValue("email", contactPoint?.email);
    setValue("relationship", contactPoint?.relationship);
    setValue("address", contactPoint?.address);
    setValue("id", contactPoint?.id);
  }, [contactPoint, setValue]);

  const getErrorsValidate = (name: any) => {
    return Object.keys(errors).length !== 0 ? errors[name as keyof typeof errors]?.message : errorFromApis?.[name]?.[0];
  };

  const handleSubmitModal = async (data: EditContactPointSubmitForm) => {
    setIsLoading(true);
    const dataSubmit = {
      id: data?.id,
      full_name: data?.full_name,
      phone_number: data?.phone_number,
      country_iso: data?.country_iso,
      email: data?.email ?? "",
      relationship: data?.relationship ?? "",
      address: data?.address ?? "",
    };
    const response = await dispatch(updateContactPointById(dataSubmit));
    if (response?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("edit_contact_point_successful"));
      dispatch(contactPointSlice.actions.resetFilter());
      await dispatch(fetchContactPoint());
      onClose();
    }

    setIsLoading(false);
  };
  const witdhLabelForm = 105;
  const marginRightLabelForm = 44;

  const handleBackClick = () => {
    isDirty ? setIsOpenModalConfirmLeaveForm(true) : onClose();
  };

  const handleClose = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: "hidden", height: "auto" } }}
      >
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <FormatListBulletedIcon />
            <p>{t("edit_contact_point")}</p>
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent className={classes.containerContent}>
          <>
            {isLoading && <Loading />}
            <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
              <div className={classes.containerForm}>
                <InputTextForm
                  title={t("full_name")}
                  widthLabel={witdhLabelForm}
                  marginRightLabel={marginRightLabelForm}
                  isRequired={true}
                  name="full_name"
                  register={register}
                  errors={getErrorsValidate("full_name")}
                />
                <InputTextForm
                  title={t("phone_number")}
                  widthLabel={witdhLabelForm}
                  marginRightLabel={marginRightLabelForm}
                  name="phone_number"
                  type="number"
                  register={register}
                  errors={getErrorsValidate("phone_number") ?? getErrorsValidate("country_iso")}
                  control={control}
                  options={options}
                  initIsoVnDefault={contactPoint?.country_iso?.iso ?? INIT_COUNTRY_ISO_VN_DEFAULT}
                />
                <InputTextForm
                  title={t("email")}
                  widthLabel={witdhLabelForm}
                  marginRightLabel={marginRightLabelForm}
                  name="email"
                  register={register}
                  errors={getErrorsValidate("email")}
                />
                <InputTextForm
                  title={t("relationship")}
                  widthLabel={witdhLabelForm}
                  marginRightLabel={marginRightLabelForm}
                  name="relationship"
                  register={register}
                  errors={getErrorsValidate("relationship")}
                />

                <InputTextForm
                  type="textarea"
                  title={t("address")}
                  widthLabel={witdhLabelForm}
                  marginRightLabel={marginRightLabelForm}
                  name="address"
                  isRequired={false}
                  register={register}
                  errors={getErrorsValidate("address")}
                />
              </div>
              <div className={classes.containerBtn}>
                <ButtonCancelForm onClick={handleBackClick} />
                <ButtonSaveForm />
              </div>
            </form>
            <ModalConfirmLeaveForm
              isOpen={isOpenModalConfirmLeaveForm}
              onClose={() => {
                setIsOpenModalConfirmLeaveForm(false);
                onClose();
              }}
              onConfirm={() => {
                setIsOpenModalConfirmLeaveForm(false);
              }}
            />
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}
