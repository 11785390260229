import { makeStyles } from "tss-react/mui";
import colors from "../../../utils/colors";
import checkList from "../../../assets/images/checklist.svg";

export const useStyles = makeStyles()(() => ({
  container: {
    "& .MuiPaper-root": {
      position: "fixed",
      top: "unset",
      bottom: "0",
      left: "auto",
      right: "30px",
      margin: 0,
      "@media (max-width: 576px)": {
        "& .MuiDialogContent-root": {
          padding: "10px",
        },
      },
      minWidth: "280px",
    },
  },
  iconcheckList: {
    maskImage: `url(${checkList})`,
    display: "block",
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    marginRight: "10px",
  },
  wrapLabelTitle: {
    display: "flex",
  },
  title: {
    backgroundColor: "#4A4358",
    color: "#FFF",
    height: "40px",
    fontSize: "18px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "20px",
    "@media (max-width: 576px)": {
      fontSize: "16px",
    },
  },
  btnClose: {
    backgroundColor: "#4A4358",
    color: "#FFF",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
  },
  btnYes: {
    backgroundColor: colors.btnPrimary,
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: colors.btnPrimary,
    },
  },
  btnNo: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "10px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginBottom: "15px",
    "@media (max-width: 576px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "10px",
    },
  },
  label: {
    fontSize: "14px",
    color: colors.defaultColorText,
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "200px",
    "& span": {
      color: "#F00",
    },
  },
  labelResult: {
    color: colors.defaultColorText,
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "100px",
    "& span": {
      color: "#F00",
    },
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  inputSelect: {
    width: "100%",
    "& .MuiChip-label": {
      maxWidth: "200px",
    },
    "@media (max-width: 768px)": {
      "& .MuiChip-label": {
        maxWidth: "150px",
      },
    },
  },
  inputArea: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
    marginTop: "12px",
    resize: "none",
    "@media (max-width: 576px)": {
      marginTop: "0",
    },
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "200px",
    marginTop: "-10px",
    marginBottom: "10px",
    "@media (max-width: 576px)": {
      marginLeft: "0",
    },
  },
  wrapResult: {
    whiteSpace: "nowrap",
    overflowY: "auto",
    paddingBottom: "5px",
    "@media (max-width: 576px)": {
      maxWidth: "100%",
    },
  },
  hidden: {
    display: "none",
  },
}));
