import { LooseObject } from "models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";

export const getColumns = (screen: string) => async () => {
  try {
    const url: string = API.column;
    const response = await axiosClient.get(url, { params: { screen: screen } });
    if (response.status === 200) {
      const column: LooseObject = {
        displayed: [],
        hidden: [],
        id: response.data.data.id,
      };
      Object.keys(response.data.data.display).map((key: string) => {
        if (response.data.data.display[key]) {
          column.displayed.push(key);
        } else {
          column.hidden.push(key);
        }
        return null;
      });
      return column;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const saveColumns = (id: number, data: LooseObject) => async () => {
  try {
    const url: string = `${API.column}/${id}`;
    const response = await axiosClient.put(url, data);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
