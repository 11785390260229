import {Close, ContentPaste, SaveAltOutlined} from "@mui/icons-material";
import {CircularProgress, DialogActions, Grid, IconButton} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BaseButton from "components/Buttons/BaseButton";
import { LooseObject } from "models/common";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosClient } from "services/axiosClient";
import ModalChangeApplicationStatus from "../ModalChangeApplicationStatus";
import { useStyles } from "./styles";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../../redux/store";
import {getCandidateApplicationPdf} from "../../../store/applicationSlice";
import {padStart} from "lodash";

interface IProps {
  isOpen: boolean;
  onClose: Function;
  dataDetail: LooseObject;
}

export default function ModalDetailCandidateContact({ isOpen, onClose, dataDetail }: IProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [dataRender, setDataRender] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalChangeApplicationStatus, setOpenModalChangeApplicationStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    onClose();
  };

  const handleOpenModalChangeApplicationStatus = () => {
    setOpenModalChangeApplicationStatus(true);
  };

  const handleDownloadCandidateApplicationPdf = async () => {
    setLoading(true);
    const response = await dispatch(getCandidateApplicationPdf(dataDetail.view_html));
    try {
      const href = window.URL.createObjectURL(response);
      const fileName = dataDetail.full_name
          + "_VJU" + dataDetail.aspiration.id + dataDetail.admission_method.id + padStart(dataDetail.numerical_order, 5, '0')
          + "_đợt_" + dataDetail.admission_period.number;
      const anchorElement = document.createElement("a");
      anchorElement.href = href;
      anchorElement.download = fileName;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    } catch (error) {
      console.log(error);
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  }

  const handleCloseModalChangeApplicationStatus = () => {
    setOpenModalChangeApplicationStatus(false);
  };

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(dataDetail.view_html)
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        setDataRender(res.data);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, [dataDetail]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: "hidden", height: "auto" } }}
      >
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <ContentPaste />
            <p>{t("detail")}</p>
          </div>
          <IconButton aria-label="close" onClick={handleClose} className={classes.btnClose}>
            <Close />
          </IconButton>
        </div>

        <DialogContent className={classes.containerContent}>
          {loading ? <CircularProgress /> : <div className={classes.pdfContent} dangerouslySetInnerHTML={{ __html: dataRender }} />}
        </DialogContent>
        <DialogActions className={classes.footer} style={{marginBottom: 0, paddingBottom: 0}}>
          <BaseButton
              title={t("change_application_status")}
              className={classes.btnSearch}
              onClick={() => handleOpenModalChangeApplicationStatus()}
          />
        </DialogActions>
        <DialogActions className={classes.footer}>
          <BaseButton
              startIcon={<SaveAltOutlined style={{ fontSize: "20px" }} />}
              title={t("download_application_pdf")}
              className={classes.btnSearch}
              onClick={() => handleDownloadCandidateApplicationPdf()}
          />
        </DialogActions>
      </Dialog>
      {openModalChangeApplicationStatus && (
        <ModalChangeApplicationStatus
          isOpen={openModalChangeApplicationStatus}
          onClose={handleCloseModalChangeApplicationStatus}
          dataDetail={dataDetail}
        />
      )}
    </div>
  );
}
