import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    backgroundColor: colors.backgroundLight,
    padding: "16px",
    margin: "0 auto 16px",
    borderRadius: "8px",
    maxWidth: "1200px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      margin: "8px",
    },
  },
  containerStep2: {
    backgroundColor: colors.backgroundDark,
    paddingBottom: "16px",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    gap: "32px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "16px",
    },
  },
  inputSelect: {
    width: "100%",
  },
  inputSelectPoper: {
    zIndex: 1000,
  },
  formControlStep2: {
    flex: 1,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  formControl: {
    display: "flex",
    padding: "16px",
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0,
      marginBottom: "16px",
    },
  },
  title: {
    minWidth: "250px",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px",
    },
  },
  titleStep3: {
    width: "500px",
    minWidth: "500px",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("lg")]: {
      width: "300px",
      minWidth: "300px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
      minWidth: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },
  },
  headerCard: {
    backgroundColor: "#AB2523cc !important",
    borderRadius: "4px 4px 0 0 !important",
  },
  iconStep2: {
    marginRight: "5px",
  },
  containerStep3: {
    backgroundColor: colors.backgroundDark,
    padding: "16px",
    boxSizing: "border-box",
    width: "100%",
    fontSize: "14px",
  },
  inputArea: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
    resize: "none",
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    minHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "10px",
    },
  },
  noteBank: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 600,
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "10px",
    },
  },
  wrapDetail: {
    width: "100%",
  },
}));
