import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

interface IProps {
  title?: string;
  data?: number | string;
}

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("BJS", 159, 12, 24, 45),
  createData("BCSE", 237, 90, 37, 43),
  createData("ECE", 237, 90, 37, 42),
  createData("ESAS", 262, 160, 24, 60),
  createData("MJM", 305, 37, 67, 43),
  createData("FTH", 356, 160, 49, 39),
];

const TableReport: React.FC<IProps> = (props) => {
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Total candidates (leads)</TableCell>
              <TableCell align="center">1st choice (show interest)</TableCell>
              <TableCell align="center">2nd choice</TableCell>
              <TableCell align="center">3rd choice</TableCell>
              <TableCell align="center">Next year</TableCell>
              <TableCell align="center">Interview 1</TableCell>
              <TableCell align="center">Pass 1</TableCell>
              <TableCell align="center">Interview 2</TableCell>
              <TableCell align="center">Pass 2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.calories}</TableCell>
                <TableCell align="center">{row.fat}</TableCell>
                <TableCell align="center">{row.carbs}</TableCell>
                <TableCell align="center">{row.protein}</TableCell>
                <TableCell align="center">_</TableCell>
                <TableCell align="center">_</TableCell>
                <TableCell align="center">_</TableCell>
                <TableCell align="center">_</TableCell>
                <TableCell align="center">_</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableReport;
