import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LooseObject } from "../models/common";
import { AppDispatch } from "../redux/store";
import { API } from "../utils/api";
import { axiosClient } from "../services/axiosClient";

export interface User {
  type: number,
  status: number,
  full_name?: string,
  email: string,
  password?: string,
  roles: LooseObject[],
  permissions: LooseObject[]
}

export interface UserState {
  users: LooseObject[];
  page: number;
  total: number;
  limit: number;
  error?: any;
  isCreateUser?: string | null;
  isUpdateUser?: string | null;
  isGetUser?: string | null;
  user: User | LooseObject
}

const initialState: UserState = {
  users: [],
  page: 1,
  total: 0,
  limit: 50,
  error: null,
  isCreateUser: null,
  isUpdateUser: null,
  user: {}
};

export const createUser = createAsyncThunk(
  "users/createUser", async(user: User, {rejectWithValue, dispatch}) => {
    dispatch(resetUserError());
    const url: string = API.restfulUsers;
    return await axiosClient.post(url, user)
      .then(response => response?.data)
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
)

export const updateUserById = createAsyncThunk(
  "users/updateUser", async({user, id}: {user: User, id: number}, {rejectWithValue, fulfillWithValue, dispatch}) => {
    dispatch(resetUserError());
    const url: string = API.restfulUsers + "/" + id;
    return await axiosClient.put(url, user)
      .then(response => fulfillWithValue(response?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
)

export const getUserById = createAsyncThunk(
  "users/getUserById", async(id: string, {rejectWithValue, fulfillWithValue, dispatch}) => {
    dispatch(resetUserError());
    const url: string = API.restfulUsers + "/" + parseInt(id);
    return await axiosClient.get(url)
      .then(response => fulfillWithValue(response?.data?.data))
      .catch(response => rejectWithValue(response?.response?.data?.errors))
  }
)

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetchUsersSuccess: (state, action: PayloadAction<LooseObject>) => {
      state.users = action.payload.data;
      state.page = action.payload.meta.current_page;
      state.total = action.payload.meta.total;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    resetUserError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state) => {
      state.error = null;
      state.isCreateUser = 'creating';
    })
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.error = null;
      state.isCreateUser = 'created';
    })
    builder.addCase(createUser.rejected, (state, action) => {
      state.isCreateUser = null;
      state.error = action.payload;
    })
    builder.addCase(getUserById.pending, (state) => {
      state.isGetUser = 'getting';
      state.error = null;
    })
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isGetUser = 'got';
    })
    builder.addCase(getUserById.rejected, (state, action) => {
      state.isGetUser = null;
      state.error = action.payload
    })
    builder.addCase(updateUserById.pending, (state) => {
      state.error = null;
      state.isUpdateUser = 'updating';
    })
    builder.addCase(updateUserById.fulfilled, (state, action) => {
      state.error = null;
      state.isUpdateUser = 'updated';
    })
    builder.addCase(updateUserById.rejected, (state, action) => {
      state.isUpdateUser = null;
      state.error = action.payload;
    })
  }
});

export const { fetchUsersSuccess, setLimit, resetUserError } = userSlice.actions;

export const fetchUsers =
  (page: number = 1) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      const limit = getState().users.limit;
      const paramsData = { page: page, limit: limit };
      const url: string = API.restfulUsers;
      const response = await axiosClient.get(url, {
        params: { ...paramsData },
      });
      if (response.status === 200) {
        dispatch(fetchUsersSuccess(response.data));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const setPageSize = (data: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLimit(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const changeStatusUser = (id: number, status: number) => async (dispatch: AppDispatch) => {
  try {
    const url: string = API.changeStatusUser(id);
    const response = await axiosClient.patch(url, {status: status});

    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getAllUser = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axiosClient.get(API.restfulUsers, { params: { limit: "*" } });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};


export default userSlice;
export const isCreateUserSelector = (state: any) => state.users?.isCreateUser;
export const isUpdateUserSelector = (state: any) => state.users?.isUpdateUser;
export const userErrorsSelector = (state: any) => state.users?.error;
export const userSelector = (state: any) => state.users?.user;