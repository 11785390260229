import { capitalize, isEmpty } from "lodash";
import { LooseObject } from "../models/common";
import axios from "axios";
import { getUserInfo, hasPermissions } from "redux/auth/auth.service";
import { pages } from "components/NavBar";

const checkSortOrder = (data: LooseObject[], field: string, type: string) => {
  let check: boolean = false;
  let value = data[0][field];

  if (!value && type !== "number") {
    return false;
  }
  try {
    if (type === "string") {
      value = value.toLowerCase();
      data.forEach((item) => {
        if (!item[field] || item[field]?.toLowerCase() > value) {
          check = true;
          return;
        }
      });
    } else if (type === "number") {
      data.forEach((item) => {
        if (item[field] > value) {
          check = true;
          return;
        }
      });
    } else if (type === "array") {
      value = value.length;
      data.forEach((item) => {
        if (!item[field] || item[field]?.length > value) {
          check = true;
          return;
        }
      });
    } else if (type === "date") {
      value = new Date(String(value));
      data.forEach((item) => {
        if (!item[field] || new Date(String(item[field])) > value) {
          check = true;
          return;
        }
      });
    } else if (type === "object") {
      value = value?.name?.toLowerCase() ?? value?.source?.toLowerCase() ?? value?.type?.toLowerCase() ?? value?.number;
      if (!value) {
        return false;
      }
      data.forEach((item) => {
        if (
          !item[field] ||
          (item[field]?.name?.toLowerCase() ??
            item[field]?.source?.toLowerCase() ??
            item[field]?.type?.toLowerCase() ??
            item[field]?.number) > value
        ) {
          check = true;
          return;
        }
      });
    }
  } catch (error) {
    return false;
  }
  return check;
};

export const sortTable = (data: LooseObject[], field: string, type: string) => {
  let dataTable = [...data];
  if (!dataTable || Object.keys(dataTable).length < 1) {
    return dataTable;
  }
  let valueA = 1;
  let valueB = -1;
  if (checkSortOrder(dataTable, field, type)) {
    valueA = -1;
    valueB = 1;
  }

  if (type === "string") {
    dataTable.sort(function (a, b) {
      let nameA = a[field];
      let nameB = b[field];

      if (!nameA) {
        return valueA;
      }
      if (!nameB) {
        return valueB;
      }

      nameA = String(nameA).toLowerCase();
      nameB = String(nameB).toLowerCase();
      if (nameA === nameB) {
        return 0;
      }
      return nameA > nameB ? valueA : valueB;
    });
  } else if (type === "number") {
    dataTable.sort((a, b) =>
      Number(a[field]) > Number(b[field]) ? valueA : Number(a[field]) < Number(b[field]) ? valueB : 0
    );
  } else if (type === "array") {
    dataTable.sort((a, b) =>
      a[field]?.length > b[field]?.length ? valueA : a[field]?.length < b[field]?.length ? valueB : 0
    );
  } else if (type === "date") {
    dataTable.sort((a, b) => {
      let x: Date = new Date(String(a[field]));
      let y: Date = new Date(String(b[field]));

      if (x > y) {
        return valueA;
      } else if (x < y) {
        return valueB;
      }
      return 0;
    });
  } else if (type === "object") {
    dataTable.sort(function (a, b) {
      let nameA = a[field];
      let nameB = b[field];
      if (nameA === null) {
        return valueA;
      }
      if (nameB === null) {
        return valueB;
      }
      nameA = nameA?.name?.toLowerCase() ?? nameA?.source?.toLowerCase() ?? nameA?.type?.toLowerCase() ?? nameA?.number;
      nameB = nameB?.name?.toLowerCase() ?? nameB?.source?.toLowerCase() ?? nameB?.type?.toLowerCase() ?? nameB?.number;

      if (nameA === nameB) {
        return 0;
      }
      return nameA > nameB ? valueA : valueB;
    });
  }

  return dataTable;
};

export const formatSearchContent = (content: LooseObject[] | string[] | string, text: string) => {
  if (typeof content === "string") {
    return content;
  }
  const nameTag = content.map((item) => (typeof item === "object" ? item.name : item));
  return nameTag.join(` ${text.toLowerCase()} `);
};

export const regexPhone = /^(0)[1-9]{1}[0-9]{8}$/;

export const regexNumber = /^[0-9]+$/;

export const regexDecimalNumber = /^[0-9]+(?:\.[0-9]{1,2})?$/;

export const regexGSM7 = new RegExp(
  "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
);

export const removeCharsFromStart = (inputString: string, charsToRemove: string) => {
  let result = inputString;
  for (const char of charsToRemove) {
    if (result.startsWith(char)) {
      result = result.slice(char.length);
    } else {
      break;
    }
  }

  return result;
};

export const convertToArrayId = (arr: any[]) => {
  return arr.map((item) => item.id);
};

export const uniqueTwoArray = (arr1: any[], arr2: any[]) => {
  return arr1.reduce(
    (acc: any, obj: any) => {
      if (!acc.some((item: any) => item.id === obj.id)) {
        acc.push(obj);
      }
      return acc;
    },
    [...arr2]
  );
};

export const renderListQueriesAndCategoriesParams = (arrQuery?: any[], arrCategories?: any[]) => {
  const queryCate = arrCategories?.map((items) => `contact_category_ids[]=${items}`).join("&");
  const query = arrQuery?.map((items) => `contact_query_ids[]=${items}`).join("&");

  if (!isEmpty(arrQuery) && isEmpty(arrCategories)) {
    return query;
  }
  if (isEmpty(arrQuery) && !isEmpty(arrCategories)) {
    return queryCate;
  }
  if (isEmpty(arrQuery) && isEmpty(arrCategories)) {
    return "";
  }

  return `${queryCate}&${query}`;
};

export const flattenObject = (obj: any, parentKey = "", result: any = {}) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${key}` : key;
      if (typeof obj[key] === "object" && obj[key] !== null) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key] ?? "";
      }
    }
  }
  return result;
};

export const generateRandomString = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const transformUrlsToFiles = async (urlArray: LooseObject[]) => {
  let fileArray: any = [];
  for (const item of urlArray) {
    try {
      const response = await axios.get(item?.url, { responseType: "blob" });
      const randomFilename = generateRandomString(10);
      const file = new File([response.data], randomFilename, { type: "image/png" });
      fileArray.push(file);
    } catch (error) {
      console.error("Error downloading file from URL:", error);
    }
  }
  return fileArray;
};

export const convertScoreFacilityOfTen = (value: number | string) => {
  let formatNumber = value.toString().replace(/[^\d\s]/g, "");
  if (formatNumber === "10") {
    return "10";
  }
  return formatNumber.replace(/^(\d)(\d)$/, "$1.$2");
};

export const startCaseTitle = (str: string) => {
  return str.split(" ").map(capitalize).join(" ");
};

export const checkPermission = (permissions: string[]) => {
  const userInfo = getUserInfo();
  if (!userInfo || !hasPermissions(permissions ?? [])) {
    return null;
  }
  return true;
};

export const getViewPermission = (user: LooseObject) => {
  const listPermission: string[] = [];
  if (user?.permissions && user?.permissions?.length > 0) {
    user?.permissions?.forEach((item: LooseObject) => {
      if (item?.name.includes("view")) {
        listPermission.push(item.name);
      }
    });
  }
  user?.roles.forEach((role: LooseObject) => {
    if (role?.permissions && role?.permissions?.length > 0) {
      role?.permissions?.forEach((item: LooseObject) => {
        if (item?.name.includes("view") && !listPermission.includes(item.name)) {
          listPermission.push(item.name);
        }
      });
    }
  });
  return listPermission;
};

export const getPathToRedirect = (listPermission: string[]) => {
  let path = "/studentContact";
  let shouldBreak = false;
  pages.forEach((item: LooseObject) => {
    if (shouldBreak) {
      return;
    }
    if (item.options.length > 0) {
      item.options.forEach((subItem: LooseObject) => {
        if (shouldBreak) {
          return;
        }
        subItem.permissions.forEach((permission: string) => {
          if (listPermission.includes(permission)) {
            path = subItem.path;
            shouldBreak = true;
            return;
          }
        });
      });
    } else {
      item.permissions.forEach((permission: string) => {
        if (listPermission.includes(permission)) {
          path = item.path;
          shouldBreak = true;
          return;
        }
      });
    }
  });
  return path;
};
