import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    marginBottom: "19px",
    position: "relative",
  },
  time: {
    fontWeight: 700,
    fontSize: "14px",
    width: "fit-content",
    borderBottom: "1px dashed #C3C3C3",
    marginBottom: "10px",
  },
  note: {
    fontWeight: 400,
    fontSize: "12px",
    boxSizing: "border-box",
    flexWrap: "wrap",
    wordWrap: "break-word",
    "& img": {
      maxHeight: "200px",
      maxWidth: "fit-content",
    },
    "& ul, ol": {
      margin: 0,
      padding: 0,
    },
    "@media (max-width: 425px)": {
      "& img": {
        maxHeight: "100px",
      },
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      height: "100%",
      border: "1px double hsl(0, 0%, 70%)",
    },
    "& th, td": {
      border: "1px double hsl(0, 0%, 70%)",
    },
  },
  line: {
    marginLeft: "-8px",
    borderLeft: "1px solid #84C529",
    marginTop: "5px",
    "&::before": {
      content: '""',
      position: "absolute",
      transform: "translate(-56%, -50%)",
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#FFF",
      border: "1px solid #84C529",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      transform: "translate(-56%, 0)",
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#FFF",
      border: "1px solid #84C529",
    },
    "& div": {
      marginLeft: "8px",
      marginRight: "5px",
    },
  },
  audio: {
    height: "30px",
    margin: "5px auto auto 5px",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - 16px)`,
      margin: "5px ",
    },
  },
}));
