import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    paddingBottom: "30px",
  },
  wrap: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 400,
    "& p": {
      marginRight: "60px",
      "& span": {
        color: "#F00",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& p": {
        marginRight: 0,
      },
    },
  },
  error: {
    color: "red",
    fontSize: "14px",
    fontWeight: 400,
    height: "20px",
  },
  label: {
    margin: 0,
  },
}));
