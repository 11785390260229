import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    marginBottom: "16px",
  },
  containerHeader: {
    borderRadius: "4px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#7C8286",
    padding: "0 0 0 16px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: "50px",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: 400,
    color: colors.backgroundLight,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  arrow: {
    color: colors.backgroundLight,
    fontSize: "30px",
  },
}));
