import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AddContactToNewListForm from "components/Forms/AddContactToNewListForm";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { useState } from "react";
import AddContactToExistingListForm from "components/Forms/AddContactToExistingListForm";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ModalFilterProp {
  isOpen: boolean;
  onClose: Function;
  contactId: number;
  onSubmitModal?: () => void;
}

export default function ModalAddContactToList({ isOpen, onClose, contactId, onSubmitModal }: ModalFilterProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isActiveAddToExistingListTab, setIsActiveAddToExistingListTab] = useState(true);
  const handleClose = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: "hidden", height: "auto" } }}
      >
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <FormatListBulletedIcon />
            <p>{t("add_contact_to_list")}</p>
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent className={classes.containerContent}>
          <div className={classes.containerHeaderForm}>
            <div
              className={`${classes.headerTextForm} ${
                isActiveAddToExistingListTab ? classes.activeHeaderTextForm : ""
              }`}
              onClick={() => setIsActiveAddToExistingListTab(true)}
            >
              {t("add_to_existing_list")}
            </div>
            <div className={classes.headerDiviverForm}></div>
            <div
              className={`${classes.headerTextForm} ${
                !isActiveAddToExistingListTab ? classes.activeHeaderTextForm : ""
              }`}
              onClick={() => setIsActiveAddToExistingListTab(false)}
            >
              {t("create_new_list")}
            </div>
          </div>
          {isActiveAddToExistingListTab && (
            <AddContactToExistingListForm isOpen={isOpen} onClose={onClose} contactId={contactId} />
          )}
          {!isActiveAddToExistingListTab && (
            <AddContactToNewListForm isOpen={isOpen} onClose={onClose} contactId={contactId} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
