import { LooseObject } from "models/common";
import { configRequest } from "../../services/axiosClient";

export const saveUserInfo = (userInfo: any) => {
  configRequest(userInfo.token);
  return localStorage.setItem("user_info", JSON.stringify(userInfo));
};
export const getUserInfo = () => {
  return JSON.parse(String(localStorage.getItem("user_info")));
};

export const removeUserInfo = () => {
  return localStorage.removeItem("user_info");
};

export const hasRoles = (roles: string[]) => {
  const user = getUserInfo();
  if (!user) {
    return false;
  }
  if (roles.length === 0) {
    return true;
  }
  const userRoles = user.user?.roles || [];
  return userRoles.some((role: LooseObject) => roles.includes(role?.name));
};

export const hasPermissions = (permissions: string[]) => {
  const user = getUserInfo();
  const userRoles = user?.user?.roles || [];
  if (!user) {
    return false;
  }
  if (permissions.length === 0 || userRoles?.some((role: LooseObject) => role?.name === "Super Admin")) {
    return true;
  }

  let userPermissions: LooseObject[] = [];
  userPermissions.push(...(user.user?.permissions || []));
  userRoles.length > 0 &&
    userRoles.forEach((role: LooseObject) => {
      role?.permissions?.length > 0 && userPermissions.push(...role?.permissions);
    });

  return userPermissions?.some((permission: LooseObject) => permissions.includes(permission?.name));
};
