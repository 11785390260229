import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../redux/auth/auth.service";
import { useStyles } from "./styles";
import RoleBaseComponent from "components/RoleBaseComponent";
import { AssignmentOutlined } from "@mui/icons-material";

export default function DropdownMenu(props: any) {
  const { t } = useTranslation();
  const { page, onClose } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userInfor = getUserInfo();
  const { classes } = useStyles();

  useEffect(() => {
    let isActive = false;
    const currentPath = location.pathname.toLowerCase().replace("/", "");
    isActive = page.name.toLowerCase() === currentPath;

    if (!isActive) {
      isActive = page.options.some((option: any) => {
        const optionPath = option.path.toLowerCase();
        return optionPath.includes(currentPath) || (currentPath !== "" && currentPath.includes(optionPath));
      });
    }

    setIsActive(isActive);
  }, [location.pathname, page.name, page.options, page.path]);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (option: any) => {
    setAnchorEl(null);
    navigate(option.path);
    onClose();
  };

  const getRolePermissionData = (item: any) => {
    return item && item?.length > 0 ? item : [];
  };

  return (
    <>
      <RoleBaseComponent
        roles={getRolePermissionData(page?.roles)}
        permissions={getRolePermissionData(page?.permissions)}
      >
        <div>
          {userInfor && (
            <Button
              key={page.label}
              className={`${classes.btnMenu} ${isActive && classes.isActive}`}
              onClick={(e) => {
                if (page.options.length > 0) {
                  handleMenuOpen(e);
                } else {
                  navigate(page.path);
                  onClose();
                }
              }}
            >
              <div>
                {page.name === "onlineAdmission" ? (
                  <AssignmentOutlined sx={{ marginRight: 1 }} />
                ) : (
                  <span
                    className={`${classes.iconMenu} ${page.name === "contacts" && classes.iconContact} ${
                      page.name === "tasksManagement" && classes.iconSales
                    } ${page.name === "reports" && classes.iconReports} ${
                      page.name === "settings" && classes.iconSeetings
                    } ${isActive && classes.isActiveIcon}`}
                  />
                )}

                <p>{t(page.label)}</p>
              </div>
            </Button>
          )}
          {page.options.length > 0 && userInfor && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 50,
                horizontal: 15,
              }}
            >
              {page.options.map((option: any) => (
                <RoleBaseComponent
                  key={`rolebase-${option.label}`}
                  roles={getRolePermissionData(page?.options?.roles)}
                  permissions={getRolePermissionData(option?.permissions)}
                >
                  <MenuItem
                    key={`${option.label}`}
                    onClick={() => handleMenuClose(option)}
                    className={classes.menuItem}
                  >
                    {t(option.label)}
                  </MenuItem>
                </RoleBaseComponent>
              ))}
            </Menu>
          )}
        </div>
      </RoleBaseComponent>
    </>
  );
}
