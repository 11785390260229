import { LooseObject, optionsStudentTask } from "models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { STUDENT_CONTACT_TYPE } from "utils/constant";

export interface StudentTaskListState {
  tasks: LooseObject[];
  units: LooseObject[];
  taskResult: LooseObject[];
  taskType: LooseObject[];
  users: LooseObject[];
  contacts: LooseObject[];
  options: optionsStudentTask;
  page: number;
  total: number;
  limit: number;
  searchOption: LooseObject;
  error?: LooseObject[];
}

export const initialSearchOptionListTask = {
  task_type_id: "",
  task_result_ids: [],
  text: "",
};

export const initialOptions = {
  type: [],
  result: [],
};

const initialState: StudentTaskListState = {
  tasks: [],
  units: [],
  taskResult: [],
  taskType: [],
  users: [],
  contacts: [],
  page: 1,
  total: 0,
  limit: 50,
  searchOption: initialSearchOptionListTask,
  options: initialOptions,
  error: [],
};

const studentTaskListSlice = createSlice({
  name: "studentTaskList",
  initialState,
  reducers: {
    getUnitSuccess: (state, action: PayloadAction<LooseObject[]>) => {
      state.units = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    updateSearch: (state, action: PayloadAction<LooseObject>) => {
      state.searchOption = action.payload;
    },
    updateOptions: (state, action) => {
      state.options = { ...state.options, ...action.payload };
    },
    resetStudentListsError: (state) => {
      state.error = [];
    },
    setContactsForTask: (state, action) => {
      state.contacts = action.payload;
    },
    resetTasks: (state) => {
      state.tasks = [];
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchStudentTaskList.fulfilled, (state, action) => {
      state.tasks = action.payload.data;
      state.page = action.payload.current_page;
      state.total = action.payload.total;
    })
    .addCase(getTaskResults.fulfilled, (state, action) => {
      state.taskResult = action.payload.data;
    })
    .addCase(getTaskTypes.fulfilled, (state, action) => {
      state.taskType = action.payload.data;
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload.data;
    })
    .addCase(getOptionsTypeForStudent.fulfilled, (state, action) => {
      state.options = { ...state.options, ...action.payload };
    })
    ;
  },
});

export const { getUnitSuccess, setContactsForTask, resetTasks, setLimit, updateSearch, updateOptions, resetStudentListsError } = studentTaskListSlice.actions;

export const setPageSize = (data: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLimit(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchStudentTaskList = createAsyncThunk(
  "studentTaskList/fetch",
  async (page: number = 1, { getState, rejectWithValue, fulfillWithValue }) => {
    const { studentTaskList } = getState() as { studentTaskList: StudentTaskListState };
    const { searchOption, limit } = studentTaskList;

    try {
      const params: LooseObject = {
        page: page,
        limit: limit,
        contact_type: STUDENT_CONTACT_TYPE,
      };

      Object.entries(searchOption).map(([key, value]) => {
        if (value && key === "text") {
          params["name"] = value;
        } else if (value && typeof value === "string") {
          params[key] = value;
        } else if (value && Array.isArray(value)) {
          params[key] = value.map((item: LooseObject) => item.id);
        } else if (value.name) {
          params[key] = value.id;
        }
        return null;
      });

      const response = await axiosClient.get(API.task, {
        params: { ...params },
      });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTaskTypes = createAsyncThunk("tasks/getTaskType", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await axiosClient.get(API.taskType, { params: { limit: "*" } });
    return fulfillWithValue(response.data);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getTaskResults = createAsyncThunk(
  "studentTaskList/getTaskResults",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(API.taskResult, { params: { limit: "*" } });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsers = createAsyncThunk(
  "studentTaskList/getUsers",
  async (text: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(API.restfulUsers, { params: { limit: "*", name: text } });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOptionsTypeForStudent = createAsyncThunk(
  "studentTaskList/getOptionsType",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resTypes = await axiosClient.get(API.taskType, { params: { limit: "*" } });
      let type = [...resTypes.data.data];
      const data: LooseObject = {
        type: Array.isArray(type) ? type : [],
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editTask = (id: number, data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.task}/${id}`;
    const response = await axiosClient.put(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const changeResult = (id: number, data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.task}/${id}/change-result`;
    const response = await axiosClient.post(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateSearchOption =
  (option: LooseObject | string, field: string) => async (dispatch: AppDispatch, getState: any) => {
    try {
      let searchOption = getState().studentTaskList.searchOption;
      let searchData: LooseObject = JSON.parse(JSON.stringify(searchOption));
      let checkChangeType: boolean = false;
      let options = getState().studentTaskList.options;
      let valueOption: LooseObject = JSON.parse(JSON.stringify(options));

      if (field !== "text" && typeof option !== "string" && Array.isArray(searchData[field])) {
        searchData[field] = searchData[field].map((item: LooseObject) => item.id).includes(option.id)
          ? searchData[field].filter((item: LooseObject) => item.id !== option.id)
          : [...searchData[field], option];
      } else {
        searchData[field] = option;
      }
      
      dispatch(updateSearch(searchData));
      if (field === "task_type_id" && typeof option !== "string") {
        checkChangeType = true;
  
        const response = await axiosClient.get(API.taskResult, {
          params: { limit: "*", type: option.id },
        });
  
        if (response.status === 200) {
          const resultData: LooseObject[] = response.data.data.map((item: LooseObject) => ({
            ...item,
            task_type_id: option.id,
          }));
          valueOption.result = Array.isArray(resultData) ? resultData : [];
        }
      }
     
      dispatch(updateSearch(searchData));
      if (checkChangeType) {
        dispatch(updateOptions(valueOption));
      }
      return true;
    } catch (error) {
      return false;
    }
  };



export const deleteFieldSearch = (field: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const searchOption = getState().studentTaskList.searchOption;
    let data: LooseObject = JSON.parse(JSON.stringify(searchOption));
    const options = getState().studentTaskList.options;
    let dataOptions: optionsStudentTask = JSON.parse(JSON.stringify(options));
    let checkChangeType: boolean = false;
    if (Array.isArray(data[field])) {
      data[field] = [];
    } else {
      data[field] = "";
    }

    if (field === "task_type_id") {
      data["task_result_ids"] = [];
      dataOptions.result = [];
      checkChangeType = true;
    }
   
    dispatch(updateSearch(data));
    if (checkChangeType) {
      dispatch(updateOptions(dataOptions));
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getStudentContactForOption = (text: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await axiosClient.get(API.studentContact, {
      params: { limit: "*", text: text },
    });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default studentTaskListSlice;
