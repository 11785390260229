import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10px",
    },
  },
  input: {
    height: "30px",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 10px",
    width: "387px",
    boxSizing: "border-box",
    resize: "none",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputPhone: {
    height: "30px",
    width: "387px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  wrap: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 400,
    "& p": {
      marginRight: "60px",
      "& span": {
        color: "#F00",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& p": {
        marginRight: 0,
        marginBottom: "5px",
      },
    },
  },
  label: {
    margin: 0,
  },
  containerPassword: {
    width: "387px",
    position: "relative",
    "& input": {
      paddingRight: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputPassword: {
    width: "100%",
    height: "30px",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 10px",
    boxSizing: "border-box",
    resize: "none",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
      outline: "none",
    },
  },
  btnHide: {
    position: "absolute",
    right: "6px",
    bottom: "-2px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  classNamePoper: {
    zIndex: 1300,
  },
  flexGrow: {
    flexGrow: 1,
  },
  wrapTextArea: {
    display: "flex",
    flexDirection: "column",
    width: "387px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputArea: {
    width: "100%",
    height: "120px",
    fontFamily: "Roboto",
    backgroundColor: colors.whiteF5,
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 10px",
    boxSizing: "border-box",
    resize: "none",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
      outline: "none",
    },
  },
  textSpecial: { color: "#F2756F", fontWeight: "bold" },
}));
