import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    zIndex: 1000,
    "& .daterangepicker .fromDateTimeContainer .fromDateHourContainer .timeContainer":
      {
        display: "none !important",
      },
    "& input": {
      width: "100%",
      height: "30px",
      backgroundColor: "#F5F5F5",
      border: "1px solid #DEDEDE",
      borderRadius: "4px",
      padding: "0 8px",
      boxSizing: "border-box",
      "&:focus": {
        border: "1px solid #1070bd",
        outline: "none",
      },
      "&:hover": {
        border: "1px solid #202020",
      },
    },
    "& select": {
      border: "none",
      cursor: "pointer",
    },
    "& .applyButton": {
      backgroundColor: "#4A4358 !important",
      border: "1px solid #4A4358",
      "&:hover": {
        opacity: 0.8,
      },
    },
    "& .daterangepicker:before, .daterangepicker:after": {
      content: "none",
    },
  },
}));
