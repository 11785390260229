import { FileUploadOutlined, SaveAltOutlined } from "@mui/icons-material";
import { Box, Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import DropdownRadio from "components/DropdownRadio";
import FilterTag from "components/FilterTag";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading/Loading";
import ModalConfirm from "components/Modal/ModalConfirm";
import Table from "components/Table";
import BasicDateRange from "components/dropdownDateRangePicker";
import SearchBar from "components/inputSearch";
import { LooseObject } from "models/common";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import { getUnits } from "store/listStudentContactSlice";
import {
  admissionFeesSelector,
  candidatesSelector,
  deleteFieldSearch,
  exportOnlineAdmission,
  getAllCandidates,
  getOptionsforOnlineAdmission,
  importMoneyTransfer,
  initialOptionsRegisterForm,
  initialSearchOptionCandidateContact,
  limitCandidateContactSelector,
  optionsRegisterForm,
  pageCandidateContactSelector,
  searchOptionCandidateSelector,
  setLimitCandidate,
  setPageCandidate,
  totalCandidateContactSelector,
  updateSearch,
  updateSearchOption,
} from "store/registerFormSlice";
import { ACTION_CANDIDATES_LIST, CANDIDATES_CONTACT_COLUMNS } from "utils/constant";
import { formatSearchContent, sortTable } from "utils/helper";
import BaseButton from "./../../../components/Buttons/BaseButton/index";
import { useStyles } from "./styles";
import Dropdown from "components/Dropdown";

export default function OnlineAdmission() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const candidates = useSelector(candidatesSelector);
  const page = useSelector(pageCandidateContactSelector);
  const totalCandidates = useSelector(totalCandidateContactSelector);
  const candidatePerPage = useSelector(limitCandidateContactSelector);
  const searchOption = useSelector(searchOptionCandidateSelector);
  const admissionFees = useSelector(admissionFeesSelector);

  const [limit, setLimit] = useState<number>(candidatePerPage);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [checkedList, setCheckedList] = useState<number[] | []>([]);
  const [isShowAction, setIsShowAction] = useState<boolean>(false);
  const [isShowClearAll, setIsShowClearAll] = useState<boolean>(false);
  const [startCreate, setStartCreate] = useState(moment().startOf("isoWeek"));
  const [endCreate, setEndCreate] = useState(moment().endOf("isoWeek"));
  const [startPayment, setStartPayment] = useState(moment().startOf("isoWeek"));
  const [endPayment, setEndPayment] = useState(moment().endOf("isoWeek"));
  const [optionsIsPaid, setOptionsIsPaid] = useState<LooseObject>({});
  const total = useSelector((state: RootState) => state.registerForm.total);
  const [isOpenModalConfirmExport, setIsOpenModalConfirmExport] = useState<boolean>(false);
  const allOptions = useSelector((state: RootState) => state.registerForm.options);
  const [options, setOptions] = useState<optionsRegisterForm>(initialOptionsRegisterForm);

  const [data, setData] = useState<LooseObject[]>([]);

  const columnsTable = useMemo(() => {
    if (isMobileScreen)
      return CANDIDATES_CONTACT_COLUMNS.filter((column) => ["full_name", "action"].includes(column.title));
    return CANDIDATES_CONTACT_COLUMNS;
  }, [isMobileScreen]);

  const initialized = useRef(false);
  const getDataOption = async () => {
    setLoading(true);
    await dispatch(getUnits());
    await dispatch(getOptionsforOnlineAdmission());
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
      const firstFormGroup = document.querySelectorAll("#root")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setLimit(candidatePerPage);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    await dispatch(getAllCandidates());
    setIsShowAction(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, page, candidatePerPage, searchOption]);

  useEffect(() => {
    setData(candidates);
    setCheckedList([]);
  }, [candidates]);

  useEffect(() => {
    if (allOptions) {
      setOptions(allOptions);
    }
  }, [allOptions]);

  useEffect(() => {
    let check: boolean = false;
    if (searchOption) {
      Object.keys(searchOption).map((item) => {
        if (
          (Array.isArray(searchOption[item]) && searchOption[item].length !== 0) ||
          Object.keys(searchOption[item]).length !== 0
        ) {
          check = true;
          return null;
        }
        return null;
      });
    }
    setIsShowClearAll(check);
  }, [searchOption]);

  const handlePageClick = (page: number = 1) => {
    dispatch(setPageCandidate(page));
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    setCheckedList([]);
    setIsShowAction(false);
    dispatch(getAllCandidates())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleCheckBox = useCallback((checkbox: number[]) => {
    setCheckedList(checkbox);
    if (checkbox.length > 0) {
      setIsShowAction(true);
    } else {
      setIsShowAction(false);
    }
  }, []);

  const handleClickAction = useCallback((action: string) => {
    if (action === "export") {
      setIsOpenModalConfirmExport(true);
    }
  }, []);

  const handleSortTable = useCallback(
    async (field: string, type: string) => {
      const newData: LooseObject[] = sortTable(data, field, type);
      setData(newData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data]
  );

  const handleChangeLimit = useCallback(async (limit: number) => {
    setLimit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(async (page: number) => {
    handlePageClick(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetPageSize = useCallback(async (data: number) => {
    dispatch(setLimitCandidate(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteFieldSearch = (field: string) => {
    setOptionsIsPaid({});
    if (field === "text") {
      setSearchText("");
    }
    dispatch(deleteFieldSearch(field));
  };

  const handleClearAllTagSearch = () => {
    dispatch(updateSearch(initialSearchOptionCandidateContact));
    setSearchText("");
    setOptionsIsPaid({});
  };

  const handleSearchList = () => {
    if (searchText === searchOption?.text) {
      return;
    }
    dispatch(updateSearchOption(searchText, "text"));
  };

  const handleClickMenuItem = (row: LooseObject, field: string) => {
    setOptionsIsPaid(row as LooseObject);
    dispatch(updateSearchOption(row, field));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const applyCallbackCreateDate = (startDate: any, endDate: any) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartCreate(startDate);
    setEndCreate(endDate);
    setCurrentPage(1);
    setIsShowAction(false);
    dispatch(updateSearchOption(option, "created_date"));
    fetchData();
  };

  const applyCallbackPaymentDate = (startDate: any, endDate: any) => {
    const option = {
      startDate: startDate,
      endDate: endDate,
    };
    setStartPayment(startDate);
    setEndPayment(endDate);
    setCurrentPage(1);
    setIsShowAction(false);
    dispatch(updateSearchOption(option, "payment_date"));
    fetchData();
  };

  const optionsFilterPaid = useMemo(() => {
    return [
      { id: 1, name: t("paid") },
      { id: 0, name: t("unpaid") },
    ];
  }, [t]);

  const validateFile = (file: File) => {
    let error = "";
    if (file.size / (1024 * 1024) > 10) {
      error = `the_file_must_not_be_greater_than_10MB`;
    } else if (
      file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "application/vnd.ms-excel"
    ) {
      error = `the_file_must_be_a_file_of_type_xlsx_xls`;
    }
    return error;
  };
  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    if (e.target.files) {
      const fileInput = e.target.files[0];
      e.target.value = "";
      const err = validateFile(fileInput);
      if (err) {
        toast.error<void>(t(err));
      } else {
        try {
          const result = await dispatch(importMoneyTransfer(fileInput));
          if (result) {
            toast.success<void>(t("import_successful"));
          } else {
            toast.error<void>(t("system_error,_please_try_again_later"));
          }
        } catch (error) {
          toast.error<void>(t("system_error,_please_try_again_later"));
          setLoading(false);
        }
      }
    }
    setLoading(false);
  };

  const handleExportContact = async () => {
    setLoading(true);
    let response: any;
    if (checkedList.length !== 0) {
      response = await dispatch(exportOnlineAdmission(0, checkedList));
    } else {
      response = await dispatch(exportOnlineAdmission(1));
    }
    if (response) {
      try {
        const href = window.URL.createObjectURL(response);
        const fileName = "online-admission-" + moment().format("DD-MM-YYYY");
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = fileName;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
        toast.success<void>(t("export_successful"));
      } catch (error) {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }

    setLoading(false);
    setIsOpenModalConfirmExport(false);
  };

  return (
    <>
      <Helmet>
        <title>{t("online_admissions")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("online_admissions")} />
        <div>
          <div className={classes.contentPage}>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <div className={classes.wrapSearchFilter}>
                  <div className={classes.containerSearch}>
                    <SearchBar
                      value={searchText}
                      className={classes.inputSearch}
                      handleChange={setSearchText}
                      onBlur={handleSearchList}
                      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleSearchList();
                          const target = e.target as HTMLButtonElement;
                          target.blur();
                        }
                      }}
                    />
                    <div className={classes.wrapDropdown}>
                      <BasicDateRange
                        title={t("created_date")}
                        start={startCreate}
                        end={endCreate}
                        applyCallback={applyCallbackCreateDate}
                      />
                      <BasicDateRange
                        title={t("payment_date")}
                        start={startPayment}
                        end={endPayment}
                        applyCallback={applyCallbackPaymentDate}
                      />
                      <DropdownRadio
                        title={t("is_paid")}
                        className={classes.dropdown}
                        options={optionsFilterPaid}
                        onClickItem={handleClickMenuItem}
                        fieldSearch="is_paid"
                        selectedItem={optionsIsPaid}
                      />
                      <Dropdown
                        title={t("aspirations")}
                        className={classes.dropdown}
                        options={options.aspirations}
                        withCheckbox
                        onClickItem={handleClickMenuItem}
                        fieldSearch="aspiration_ids"
                        searchOption={searchOption}
                      />
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className={classes.containerBtn}>
                  <Tooltip enterTouchDelay={0} title={isMobileScreen ? t("import_invoices") : ""}>
                    <label className={`${classes.btnInputFile}`}>
                      <SaveAltOutlined style={{ fontSize: "20px" }} />
                      {isMobileScreen ? "" : <span className={classes.btnText}>{t("import_invoices")}</span>}
                      <input type="file" accept=".xlsx, .xls, application/vnd.ms-excel" onChange={handleFileSelected} />
                    </label>
                  </Tooltip>

                  <BaseButton
                    title={isMobileScreen ? "" : t("export")}
                    className={`${classes.btn}`}
                    onClick={() => {
                      if (checkedList.length === 0 && total === 0) {
                        toast.warning<void>(t("no_data_found"));
                      } else {
                        setIsOpenModalConfirmExport(true);
                      }
                    }}
                    startIcon={isMobileScreen ? null : <FileUploadOutlined />}
                    icon={isMobileScreen ? <FileUploadOutlined /> : null}
                    showToolTip={isMobileScreen}
                    textToolTip={t("export")}
                  />
                </div>
              </Grid>
            </Grid>

            <div className={classes.containerFilter}>
              {Object.entries(searchOption).map(([category, options]) => {
                let text: any = category as any;
                if (
                  options &&
                  typeof options === "object" &&
                  ["created_date", "payment_date"].includes(category) &&
                  "startDate" in options &&
                  "endDate" in options &&
                  options?.startDate &&
                  options?.endDate
                ) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${moment(options?.startDate).format("YYYY/MM/DD")} -> ${moment(
                        options?.endDate
                      ).format("YYYY/MM/DD")}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (Array.isArray(options) && options.length !== 0) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${formatSearchContent(options, t("or"))}`}
                      key={category}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "string") {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                } else if (options && typeof options === "object" && "name" in options && options?.name) {
                  return (
                    <FilterTag
                      title={text}
                      field={`${t(text)}: ${options?.name}`}
                      key={`${category} - c`}
                      onDelete={handleDeleteFieldSearch}
                    />
                  );
                }
                return null;
              })}

              {isShowClearAll && (
                <div className={classes.clearAll} onClick={() => handleClearAllTagSearch()}>
                  <p>{t("clear_all")}</p>
                </div>
              )}
            </div>
            <div className={classes.results}>{isShowClearAll && `${totalCandidates} ${t("results")}`}</div>
          </div>
          <div className={classes.contentPage}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className={classes.wrapStatusFilter}>
                  {admissionFees &&
                    Object.entries(admissionFees).map((admissionFee, index) => {
                      return (
                        <Box key={`${admissionFee[0]} + ${index}`} className={classes.btnStatus}>
                          {`${t(admissionFee[0])} `}
                          <span style={{ fontWeight: "bold" }}>{`(${admissionFee[1]})`}</span>
                        </Box>
                      );
                    })}
                </Box>
              </Grid>
            </Grid>
            <Table
              data={data}
              columns={columnsTable}
              total={totalCandidates}
              limit={limit}
              pageSize={candidatePerPage}
              currentPage={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
              handleSortTable={handleSortTable}
              setPageSize={handleSetPageSize}
              handleCheckBox={handleCheckBox}
              isShowAction={isShowAction}
              actionMenu={ACTION_CANDIDATES_LIST as any[]}
              handleClickAction={handleClickAction}
              checkedList={checkedList}
            />
          </div>
        </div>
      </div>
      {isOpenModalConfirmExport && (
        <ModalConfirm
          isOpen={isOpenModalConfirmExport}
          title={t("export")}
          htmlContent={
            checkedList.length === 0 ? (
              <p className={classes.contentModalExport}>
                <span className={classes.nameExport}>{t("export_according_to_search")}</span>
                {t("numbers")}
                {` (${total})`}
              </p>
            ) : (
              <p className={classes.contentModalExport}>
                <span className={classes.nameExport}>{`${t("numbers")}: `}</span>
                {checkedList.length}
              </p>
            )
          }
          onClose={() => {
            setIsOpenModalConfirmExport(false);
          }}
          onConfirm={() => handleExportContact()}
          contentNo="close"
          contentYes="export"
        />
      )}
    </>
  );
}
