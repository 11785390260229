import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../redux/auth/auth.service";

function Home() {
  const userInfor = getUserInfo();

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfor) {
      navigate("/studentContact");
    } else {
      navigate("/login");
    }
  }, [navigate, userInfor]);

  return null;
}

export default Home;
