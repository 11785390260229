import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import imageCompression from "browser-image-compression";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useStyles } from "./styles";

export interface InputFileProps {
  register: any;
  nameRegister: string;
  setValue: Function;
  setError: Function;
  watch: any;
  max: number;
}

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const InputFile = ({ register, nameRegister, setValue, watch, setError, max }: InputFileProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation(["register", "home"]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const images = watch(nameRegister);
  const [loading, setLoading] = useState<boolean>(false);
  const isLoadingImage = useSelector((state: RootState) => state.registerForm.isLoadingImage);
  useEffect(() => {
    if (Array.isArray(images) && images.length > 0) {
      const previews = images.map((file: any) => URL.createObjectURL(file));
      setImagePreviews(previews);
    }

    return () => {
      imagePreviews.forEach((element: string) => {
        URL.revokeObjectURL(element);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    if (event.target.files && Array.from(event.target.files).length) {
      event.preventDefault();
      let existFile = Array.from(event.target.files).length;
      if (Array.isArray(images) && images.length > 0) {
        existFile += images.length;
      }
      if (existFile > max) {
        setError(nameRegister, {
          type: "manual",
          message: `Chọn tối đa ${max} ảnh`,
        });
        setLoading(false);
        return;
      }
    }
    if (event.target.files) {
      setError(nameRegister, undefined);
      let files: any[] = [];
      const fileUpload = Array.from(event.target.files);
      if (Array.isArray(images) && images.length > 0) {
        files = [...images];
      }
      for (const file of fileUpload) {
        try {
          const compressedImage = await imageCompression(file, options);
          files.push(compressedImage);
        } catch (error) {
          console.error("Error compressing image:", error);
          setLoading(false);
        }
      }

      const previews = files.map((file) => URL.createObjectURL(file));
      setImagePreviews(previews);
      setValue(nameRegister, files);
      event.target.value = "";
    }
    setLoading(false);
  };

  const handleRemoveImage = (indexToRemove: number) => {
    setError(nameRegister, undefined);
    const newPreviews = [...imagePreviews];
    URL.revokeObjectURL(newPreviews[indexToRemove]);
    newPreviews.splice(indexToRemove, 1);
    setImagePreviews(newPreviews);
    const updatedImages = images.filter((_: any, index: number) => index !== indexToRemove);
    setValue(nameRegister, updatedImages);
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapBtnInputFile}>
        {loading || isLoadingImage ? (
          <LoadingButton
            loading={true}
            className={`${classes.btnInputFile} ${imagePreviews.length >= max ? classes.disabled : ""}`}
          />
        ) : (
          <label className={`${classes.btnInputFile} ${imagePreviews.length >= max ? classes.disabled : ""}`}>
            <CloudUploadIcon style={{ marginRight: "10px" }} />
            {t("home:select_file")}
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              disabled={imagePreviews.length >= max}
            />
          </label>
        )}

        <input type="file" accept="image/*" {...register(nameRegister)} multiple className={classes.inputFile} />
        {imagePreviews.length > 0 && (
          <div className={classes.numberOfImage}>{`(${imagePreviews.length} ${t("photo")})`}</div>
        )}
      </div>
      <div className={classes.previewContainer}>
        {imagePreviews.map((preview, index) => (
          <div key={index} className={classes.wrapImagePreview}>
            <img src={preview} alt="Preview" className={classes.imagePreview} />
            <IconButton onClick={() => handleRemoveImage(index)} className={classes.btnRemove}>
              <ClearIcon className={classes.iconRemove} />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(InputFile);
