import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { LooseObject, optionsStudentContact } from "../models/common";
import { AppDispatch, RootState } from "../redux/store";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { getColumns, saveColumns } from "./columnSlice";
import { deleteQuery, getListQueries, getQuery } from "./querySlice";
import { getListStudentForOption } from "./listStudentContactSlice";

export interface StudentContactState {
  contacts: LooseObject[];
  state: LooseObject[];
  page: number;
  total: number;
  limit: number;
  searchOption: LooseObject;
  error?: LooseObject[];
  selectedTab: number;
  listQueries: LooseObject[];
  columns: LooseObject;
  options: optionsStudentContact;
  isHasOption: boolean;
  listFilter: number[];
}

export const initialSearchFilterStudent = {
  states: [],
  statuses: [],
  countries: [],
  cities: [],
  districts: [],
  aspirations: [],
  text: "",
  filter_contact_list_id: "",
  filter_full_name: "",
  filter_email: "",
  filter_phone_number: "",
  filter_citizen_id: "",
  filter_address: "",
  filter_note: "",
  filter_competency_evaluation_score: "",
  filter_total_graduation_score: "",
  filter_high_school: "",
  filter_english_language_certificate: "",
  filter_japan_language_certificate: "",
  filter_subject_combination_id: "",
  filter_contact_source_id: "",
  filter_call_status_id: "",
  filter_reject_reason_id: "",
  filter_pic: "",
  filter_contact_has_task_with_user_id: "",
  filter_date_of_acceptance: "",
  filter_aspiration_1: "",
  filter_aspiration_2: "",
  filter_aspiration_3: "",
};

export const initialOptions = {
  list: [],
  country: [],
  city: [],
  district: [],
  ward: [],
  subjectCombination: [],
  aspiration: [],
  source: [],
  pic: [],
  status: [],
  rejectReason: [],
  callStatus: [],
  state: [],
};

const initialState: StudentContactState = {
  contacts: [],
  state: [],
  page: 1,
  total: 0,
  limit: 50,
  searchOption: initialSearchFilterStudent,
  error: [],
  selectedTab: 0,
  listQueries: [],
  columns: {},
  options: initialOptions,
  isHasOption: false,
  listFilter: [],
};

const studentContactSlice = createSlice({
  name: "studentcontacts",
  initialState,
  reducers: {
    getStateSuccess: (state, action: PayloadAction<LooseObject[]>) => {
      state.state = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
      state.page = 1;
    },
    updateSearch: (state, action: PayloadAction<LooseObject>) => {
      state.searchOption = action.payload;
      state.page = 1;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
      state.page = 1;
    },
    setListQuery: (state, action) => {
      state.listQueries = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    updateOptions: (state, action) => {
      state.options = { ...state.options, ...action.payload };
    },
    resetStudentContactError: (state) => {
      state.error = [];
    },
    resetfieldState: (state) => {
      state.page = 1;
      state.total = 0;
      state.limit = 50;
      state.searchOption = initialSearchFilterStudent;
      state.error = [];
      state.selectedTab = 0;
      state.isHasOption = false;
    },
    updateListFilter: (state, action) => {
      state.listFilter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStudentContacts.fulfilled, (state, action) => {
        state.contacts = action.payload.data;
        state.page = action.payload.current_page;
        state.total = action.payload.total;
        const listState = [...state.state];
        listState.forEach((item: LooseObject) => {
          item.contacts_count = 0;
        });
        action.payload?.states_count.forEach((item: LooseObject) => {
          const index = listState.findIndex((element: LooseObject) => element?.id === item?.contact_state_id);
          if (index !== -1) {
            listState[index].contacts_count = item.count;
          }
        });
        state.state = listState;
      })
      .addCase(createStudentContact.fulfilled, (state, action) => {
        state.contacts.push(action.payload);
      })
      .addCase(createStudentContact.rejected, (state, action) => {
        let typedPayload = action.payload as AxiosError<any>;
        const err = typedPayload?.response?.data?.errors;
        if (Object.keys(err).length !== 0) {
          const array: LooseObject[] = [];
          Object.entries(err).forEach(([key, value]) => {
            array.push({ key: key, message: value });
            state.error = array;
          });
        }
      })
      .addCase(getOptionsStudentContact.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
        state.isHasOption = true;
      })
      .addCase(getOptionsListForStudent.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
      })
      .addCase(getOptionsCountryForStudent.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
      })
      .addCase(getOptionsPicForStudent.fulfilled, (state, action) => {
        state.options = { ...state.options, ...action.payload };
      })
      .addCase(fetchAllFilterStudentContacts.fulfilled, (state, action) => {
        const arr: number[] = action.payload.data.map((item: LooseObject) => item?.id);
        state.listFilter = arr;
      });
  },
});

export const {
  getStateSuccess,
  setLimit,
  updateSearch,
  setSelectedTab,
  setListQuery,
  setColumns,
  updateOptions,
  resetStudentContactError,
  resetfieldState,
  updateListFilter,
} = studentContactSlice.actions;

export const setPageSize = (data: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLimit(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const getStates = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await axiosClient.get(API.contactState, { params: { limit: "*" } });
    if (response.status === 200) {
      const listState = [...response.data.data];
      listState.forEach((item: LooseObject) => {
        item.contacts_count = 0;
      });
      dispatch(getStateSuccess(listState));
      return listState;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateFilterOptions = (optionFilter: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    let data: LooseObject = JSON.parse(JSON.stringify(initialSearchFilterStudent));

    Object.keys(optionFilter).forEach((key) => {
      data[key] = optionFilter[key];
    });

    dispatch(updateSearch(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const updateSearchOption =
  (option: LooseObject | string, field: string) => async (dispatch: AppDispatch, getState: any) => {
    try {
      let searchOption = getState().studentcontacts.searchOption;
      let searchData: LooseObject = JSON.parse(JSON.stringify(searchOption));
      let checkChangeLocale: boolean = false;
      let options = getState().studentcontacts.options;
      let valueOption: LooseObject = JSON.parse(JSON.stringify(options));

      if (field !== "text" && typeof option !== "string" && Array.isArray(searchData[field])) {
        searchData[field] = searchData[field].map((item: LooseObject) => item.id).includes(option.id)
          ? searchData[field].filter((item: LooseObject) => item.id !== option.id)
          : [...searchData[field], option];
      } else {
        searchData[field] = option;
      }

      dispatch(updateSearch(searchData));
      if (field === "countries" && typeof option !== "string") {
        checkChangeLocale = true;
        if (searchOption.countries.map((item: LooseObject) => item.id).includes(option.id)) {
          valueOption.city = valueOption.city.filter((item: LooseObject) => item.id !== option.id);
          searchData.cities = searchData.cities.filter((item: LooseObject) => item.country_id !== option.id);
          valueOption.district = valueOption.district.filter((item: LooseObject) => item.country_id !== option.id);
          searchData.districts = searchData.districts.filter((item: LooseObject) => item.country_id !== option.id);
        } else {
          const response = await axiosClient.get(API.getCity, {
            params: { countries: [option.id] },
          });
          if (response.status === 200) {
            let cityData: LooseObject[] = [...response.data.data];
            cityData.forEach((item: LooseObject) => (item.nameGroup = option.name));
            valueOption.city = [...valueOption.city, { ...option, options: response.data.data }];
          }
        }
      }
      if (field === "cities" && typeof option !== "string") {
        checkChangeLocale = true;
        if (searchOption.cities.map((item: LooseObject) => item.id).includes(option.id)) {
          valueOption.district = valueOption.district.filter((item: LooseObject) => item.id !== option.id);
          searchData.districts = searchData.districts.filter((item: LooseObject) => item.city_id !== option.id);
        } else {
          const response = await axiosClient.get(API.getDistricts, {
            params: { cities: [option.id] },
          });
          if (response.status === 200) {
            let districts: LooseObject[] = [...response.data.data];
            districts.forEach((item: LooseObject) => {
              item.country_id = option.country_id;
              item.nameGroup = option.name;
            });
            valueOption.district = [...valueOption.district, { ...option, options: districts }];
          }
        }
      }

      dispatch(updateSearch(searchData));
      if (checkChangeLocale) {
        dispatch(updateOptions(valueOption));
      }
      return true;
    } catch (error) {
      return false;
    }
  };

export const deleteFieldSearch = (field: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const searchOption = getState().studentcontacts.searchOption;
    const options = getState().studentcontacts.options;
    let dataOptions: optionsStudentContact = JSON.parse(JSON.stringify(options));
    let data: LooseObject = JSON.parse(JSON.stringify(searchOption));
    let checkChangeLocale: boolean = false;
    if (Array.isArray(data[field])) {
      data[field] = [];
    } else {
      data[field] = "";
    }

    if (field === "countries") {
      data["cities"] = [];
      data["districts"] = [];
      dataOptions.city = [];
      dataOptions.district = [];
      checkChangeLocale = true;
    }
    if (field === "cities") {
      data["districts"] = [];
      dataOptions.district = [];
      checkChangeLocale = true;
    }
    dispatch(updateSearch(data));
    if (checkChangeLocale) {
      dispatch(updateOptions(dataOptions));
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const getOptionsStudentContact = createAsyncThunk(
  "studentcontact/getOptions",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resCountries = await axiosClient.get(API.getCountry);
      const resSubjectCombination = await axiosClient.get(API.getSubjectCombination);
      const resAspiration = await axiosClient.get(API.getAspirations, {
        params: { types: [0, 1] },
      });
      const resSource = await axiosClient.get(API.getSource, {
        params: { types: [0, 1] },
      });
      const resPIC = await axiosClient.get(API.restfulUsers, {
        params: { limit: "*", 'mockup_null': 1 },
      });
      const resTaskAssigned = await axiosClient.get(API.restfulUsers, {
        params: { limit: "*", 'mockup_null': 1 },
      });
      const resStatus = await axiosClient.get(API.getStatus, {
        params: { types: [0, 1] },
      });
      const resRejectReason = await axiosClient.get(API.getRejectReason);
      const resCallStatus = await axiosClient.get(API.getCallStatus);
      const resList = await axiosClient.get(API.restfulContactCategory, {
        params: { page: 1, limit: 15, type: 1 },
      });
      const resState = await axiosClient.get(API.contactState, { params: { limit: "*" } });

      let country = [...resCountries.data.data];
      const index = country.findIndex((item: LooseObject) => item.name === "Vietnam");
      if (index) {
        const vietnam = country[index];
        country.splice(index, 1);
        country.unshift(vietnam);
      }

      const data: LooseObject = {
        country: Array.isArray(country) ? country : [],
        subjectCombination: Array.isArray(resSubjectCombination.data.data) ? resSubjectCombination.data.data : [],
        aspiration: Array.isArray(resAspiration.data.data) ? resAspiration.data.data : [],
        source: Array.isArray(resSource.data.data) ? resSource.data.data : [],
        pic: Array.isArray(resPIC.data.data) ? resPIC.data.data : [],
        taskAssigned: Array.isArray(resTaskAssigned.data.data) ? resTaskAssigned.data.data : [],
        status: Array.isArray(resStatus.data.data) ? resStatus.data.data : [],
        rejectReason: Array.isArray(resRejectReason.data.data) ? resRejectReason.data.data : [],
        callStatus: Array.isArray(resCallStatus.data.data) ? resCallStatus.data.data : [],
        list: Array.isArray(resList.data.data.data) ? resList.data.data.data : [],
        state: Array.isArray(resState.data.data) ? resState.data.data : [],
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOptionsPicForStudent = createAsyncThunk(
  "studentcontact/getOptionsPic",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resPIC = await axiosClient.get(API.restfulUsers, {
        params: { limit: "*", mockup_null: 1 },
      });
      const data: LooseObject = {
        pic: Array.isArray(resPIC.data.data) ? resPIC.data.data : [],
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOptionsListForStudent = createAsyncThunk(
  "studentcontact/getOptionsList",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resList = await axiosClient.get(API.restfulContactCategory, {
        params: { page: 1, limit: 15, type: 1 },
      });
      const data: LooseObject = {
        list: Array.isArray(resList.data.data.data) ? resList.data.data.data : [],
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOptionsCountryForStudent = createAsyncThunk(
  "studentcontact/getOptionsCountry",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const resCountries = await axiosClient.get(API.getCountry);
      let country = [...resCountries.data.data];
      const index = country.findIndex((item: LooseObject) => item.name === "Vietnam");
      if (index) {
        const vietnam = country[index];
        country.splice(index, 1);
        country.unshift(vietnam);
      }
      const data: LooseObject = {
        country: Array.isArray(country) ? country : [],
      };
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createStudentContact = createAsyncThunk(
  "studentcontact/addContact",
  async (contact: LooseObject, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.post(API.studentContact, contact);
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAllFilterStudentContacts = createAsyncThunk(
  "studentcontact/fetchAllFilter",
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const { studentcontacts } = getState() as {
      studentcontacts: StudentContactState;
    };
    const { searchOption, selectedTab } = studentcontacts;

    try {
      const params: LooseObject = {
        limit: "*",
        filter_option: selectedTab,
        select_field: ["id"],
      };

      Object.entries(searchOption).map(([key, value]) => {
        if (value && typeof value === "string") {
          params[key] = value;
        } else if (value && Array.isArray(value)) {
          params[key] = value.map((item: LooseObject) => item.id);
        } else if (value.name) {
          params[key] = value.id;
        }
        return null;
      });
      const response = await axiosClient.get(API.studentContact, {
        params: { ...params },
      });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const fetchStudentContacts = createAsyncThunk(
  "studentcontact/fetch",
  async (page: number = 1, { getState, rejectWithValue, fulfillWithValue }) => {
    const { studentcontacts } = getState() as {
      studentcontacts: StudentContactState;
    };
    const { searchOption, limit, selectedTab } = studentcontacts;

    try {
      const params: LooseObject = {
        page: page,
        limit: limit,
        filter_option: selectedTab,
      };

      Object.entries(searchOption).map(([key, value]) => {
        if (value && typeof value === "string") {
          params[key] = value;
        } else if (value && Array.isArray(value)) {
          params[key] = value.map((item: LooseObject) => item.id);
        } else if (value.name) {
          params[key] = value.id;
        }
        return null;
      });
      const response = await axiosClient.get(API.studentContact, {
        params: { ...params },
      });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getListQueryStudent = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await dispatch(getListQueries(1));

    if (response) {
      dispatch(setListQuery(response));
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getQueryStudent = (id: number) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await dispatch(getQuery(id));
    if (response) {
      let data: LooseObject = JSON.parse(JSON.stringify(initialSearchFilterStudent));
      Object.keys(response.params).forEach((key) => {
        data[key] = response.params[key];
      });
      dispatch(updateSearch(data));

      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteQueryStudent = (id: number) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await dispatch(deleteQuery(id));
    if (response) {
      let listQueries = getState().studentcontacts.listQueries;
      const data = listQueries.filter((item: LooseObject) => item.id !== id);
      dispatch(setListQuery(data));
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getStudentColumns = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await dispatch(getColumns("student"));
    if (response) {
      dispatch(setColumns(response));
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const saveStudentColumns = (data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const id: number = data.id;
    const displayValue: LooseObject = {};
    data.displayed.forEach((item: string) => {
      displayValue[item] = true;
    });
    data.hidden.forEach((item: string) => {
      displayValue[item] = false;
    });
    const column: LooseObject = {
      screen: "student",
      display: displayValue,
    };

    const response = await dispatch(saveColumns(id, column));
    if (response) {
      dispatch(setColumns(data));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const deleteStudentContact = createAsyncThunk(
  "studentcontact/deleteContact",
  async (contact: any[], { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.delete(API.deleteStudentContact, {
        params: { id: [...contact] },
      });
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportStudentContact =
  (type: number, listId?: number[]) => async (dispatch: AppDispatch, getState: any) => {
    try {
      const searchOption = getState().studentcontacts.searchOption;
      const url = `${API.studentContact}/list/export`;
      let params: LooseObject = {};
      if (type === 1) {
        Object.entries(searchOption).map(([key, value]) => {
          if (value && typeof value === "string") {
            params[key] = value;
          } else if (value && Array.isArray(value) && value.length !== 0) {
            params[key] = value.map((item: LooseObject) => item.id);
          } else if (value && typeof value === "object" && Object.keys(value).length !== 0 && "id" in value) {
            params[key] = value.id;
          }
          return null;
        });
      } else if (type === 0 && listId) {
        params.contact_ids = [...listId];
      }
      params.export_type = type;
      params.limit = "*";

      const response = await axiosClient.get(url, {
        params: { ...params },
        responseType: "blob",
      });
      if (response) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const getStudentContactForOption = (text: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const params: LooseObject = {};
    if (text === "") {
      params["limit"] = 10;
    } else {
      params["limit"] = "*";
      params["name"] = text;
    }
    const response = await axiosClient.get(API.studentContact, { params: { ...params } });
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const restoreStudent = (data: number[]) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.studentContact}/restore`;
    const response = await axiosClient.post(url, { contact_ids: [...data] });
    if (response) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const importStudentContact = (data: LooseObject[]) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = `${API.studentContact}/import`;
    let bodyFormData = new FormData();
    data.forEach((item: LooseObject) => {
      if (item?.name === "mapping") {
        item?.value.forEach((element: LooseObject) => {
          bodyFormData.append(`mapping[${element?.name}]`, element?.id);
        });
      } else if (item?.name === "tags" || item?.name === "contact_categories") {
        item?.value.forEach((element: string) => {
          bodyFormData.append(`${item?.name}[]`, element);
        });
      } else {
        bodyFormData.append(item?.name, item?.value);
      }
    });

    const response = await axiosClient.post(url, bodyFormData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateOptionListContact = (text: string) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const list: LooseObject | string = getState().studentcontacts.searchOption.filter_contact_list_id;
    const result = await dispatch(getListStudentForOption({ limit: "*", name: text, type: 1 }));

    if (result.data && typeof list !== "string" && list?.id) {
      let listOption: LooseObject[] = [...result.data];
      const index = listOption.findIndex((item: LooseObject) => item?.id === list?.id);
      if (index !== -1) {
        dispatch(updateOptions({ list: result.data }));
      } else {
        list.push(list);
        dispatch(updateOptions({ list: list }));
      }
    } else if (result.data) {
      dispatch(updateOptions({ list: result.data }));
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteStudentContactCompletely = createAsyncThunk(
  "studentcontact/deleteContactCompletely",
  async (contact: any[], { rejectWithValue, fulfillWithValue }) => {
    try {
      const url = `${API.studentContact}/empty`;
      const response = await axiosClient.post(url, JSON.stringify({ contact_ids: [...contact] }));
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const massUpdateContact = (data: LooseObject) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const response = await axiosClient.put(API.massUpdateStudent, data);
    if (response) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default studentContactSlice;

export const selectedTabStudentContactSelector = (state: RootState) => state.studentcontacts.selectedTab;
export const queriesStudentSelector = (state: RootState) => state?.studentcontacts?.listQueries;
export const allStudentOptionsSelector = (state: RootState) => state?.studentcontacts?.options;
export const allStudentContactsSelector = (state: RootState) => state?.studentcontacts?.contacts;
export const errorSelector = (state: RootState) => state.studentcontacts.error;
