import DetailStudentContact from "pages/StaticPages/Contacts/StudentContact/Detail";
import UpdateUser from "pages/StaticPages/Setting/User/Update";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import LoginGoogle from "../pages/Auth/LoginGoogle";
import CreateStudentContact from "../pages/StaticPages/Contacts/StudentContact/Create";
import StudentContacts from "../pages/StaticPages/Contacts/StudentContact/List";
import CreateRole from "../pages/StaticPages/Setting/Role/Create";
import EditRole from "../pages/StaticPages/Setting/Role/Edit";
import Role from "../pages/StaticPages/Setting/Role/List/Role";
import CreateUser from "../pages/StaticPages/Setting/User/Create";
import User from "../pages/StaticPages/Setting/User/List/User";
import ProtectedComponent from "./../components/ProtectedComponent/index";
import Home from "./Home";
import ProfileStudentContact from "pages/StaticPages/Contacts/StudentContact/Detail/Profile";
import ListContactPoint from "pages/StaticPages/Contacts/StudentContact/Detail/ListContactPoint";
import Application from "pages/StaticPages/Contacts/StudentContact/Detail/Application";
import MasterContacts from "pages/StaticPages/Contacts/MasterContact/List";
import CreateMasterContact from "pages/StaticPages/Contacts/MasterContact/Create";
import TaskStudent from "pages/StaticPages/Contacts/StudentContact/Detail/Task";
import NotFound from "pages/NotFound";
import Forbidden from "pages/Forbidden";
import ImportStudent from "pages/StaticPages/Contacts/StudentContact/Import";
import StudentList from "pages/StaticPages/Contacts/StudentList/List";
import MasterList from "pages/StaticPages/Contacts/MasterList/List";
import DetailMasterContact from "pages/StaticPages/Contacts/MasterContact/Detail";
import ProfileMasterContact from "pages/StaticPages/Contacts/MasterContact/Detail/Profile";
import ApplicationMaster from "pages/StaticPages/Contacts/MasterContact/Detail/Application";
import LandingPageReferralLink from "pages/StaticPages/LandingPageReferralLink";
import TaskMaster from "pages/StaticPages/Contacts/MasterContact/Detail/Task";
import Reports from "pages/StaticPages/Reports";
import ListMasterContactPoint from "pages/StaticPages/Contacts/MasterContact/Detail/ListMasterContactPoint";
import ImportMaster from "pages/StaticPages/Contacts/MasterContact/Import";
import StudentTasks from "pages/StaticPages/TasksManagement/StudentTasks/List";
import MasterTasks from "pages/StaticPages/TasksManagement/MasterTasks/List";
import ActivityLogStudent from "pages/StaticPages/Contacts/StudentContact/Detail/Log";
import NotifyDetail from "pages/StaticPages/Notify";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RegisterForm from "pages/StaticPages/LandingPageAdmissionRegister";
import OnlineAdmission from "pages/StaticPages/OnlineAdmission";
import ActivityLogMaster from "pages/StaticPages/Contacts/MasterContact/Detail/Log";
import StudentAdmissionPeriod from "../pages/StaticPages/Setting/AdmissionPeriod/StudentAdmissionPeriod";
import RegisterFormDisabled from "../pages/StaticPages/LandingPageAdmissionRegister/RegisterFormDisabled";

function AppRoutes() {
  const studentContactActions = ["student_contact_view_own", "student_contact_view_global", "student_contact_edit"];
  const masterContactActions = ["master_contact_view_own", "master_contact_view_global", "master_contact_edit"];
  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/login") {
      const urlParams = new URLSearchParams(location.search);
      const lang = urlParams.get("lang");
      if (lang && ["vi", "en", "jp"].includes(lang)) {
        i18n.changeLanguage(lang);
        localStorage.setItem("react-app-lang", lang);
      }
    }
  }, [location.search, location.pathname, navigate, i18n]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/*" element={<NotFound />} />
      <Route path="/access-denied" element={<Forbidden />} />
      <Route
        path="/studentContact"
        element={
          <ProtectedComponent permissions={["student_contact_view_own", "student_contact_view_global"]}>
            <StudentContacts />
          </ProtectedComponent>
        }
      />
      <Route
        path="/studentContact/import"
        element={
          <ProtectedComponent permissions={["student_contact_import"]}>
            <ImportStudent />
          </ProtectedComponent>
        }
      />
      <Route
        path="/studentContact/import/:id"
        element={
          <ProtectedComponent permissions={["student_contact_import"]}>
            <ImportStudent />
          </ProtectedComponent>
        }
      />
      <Route
        path="/studentContact/create"
        element={
          <ProtectedComponent permissions={["student_contact_create"]}>
            <CreateStudentContact />
          </ProtectedComponent>
        }
      />
      <Route
        path="/masterContact"
        element={
          <ProtectedComponent permissions={["master_contact_view_own", "master_contact_view_global"]}>
            <MasterContacts />
          </ProtectedComponent>
        }
      />
      <Route
        path="/masterContact/import"
        element={
          <ProtectedComponent permissions={["master_contact_import"]}>
            <ImportMaster />
          </ProtectedComponent>
        }
      />
      <Route
        path="/masterContact/import/:id"
        element={
          <ProtectedComponent permissions={["master_contact_import"]}>
            <ImportMaster />
          </ProtectedComponent>
        }
      />
      <Route
        path="/masterContact/create"
        element={
          <ProtectedComponent permissions={["master_contact_create"]}>
            <CreateMasterContact />
          </ProtectedComponent>
        }
      />
      <Route path="/student-tasks" element={<StudentTasks />} />
      <Route path="/master-tasks" element={<MasterTasks />} />
      <Route
        path="/studentContact/:id"
        element={
          <ProtectedComponent permissions={["student_contact_view_own", "student_contact_view_global"]}>
            <DetailStudentContact />
          </ProtectedComponent>
        }
      >
        {/* Profile Student Route */}
        <Route
          path=""
          element={
            <ProtectedComponent permissions={studentContactActions}>
              <ProfileStudentContact />
            </ProtectedComponent>
          }
        />
        {/* List Contact Point Student Route */}
        <Route
          path="listContactPoint"
          element={
            <ProtectedComponent permissions={studentContactActions}>
              <ListContactPoint />
            </ProtectedComponent>
          }
        />
        {/* Application Student Route */}
        <Route
          path="application"
          element={
            <ProtectedComponent permissions={studentContactActions}>
              <Application />
            </ProtectedComponent>
          }
        />
        {/* Task Student Route */}
        <Route
          path="tasks"
          element={
            <ProtectedComponent permissions={studentContactActions}>
              <TaskStudent />
            </ProtectedComponent>
          }
        />
        {/* Activity Log Student Route */}
        <Route
          path="activityLog"
          element={
            <ProtectedComponent permissions={studentContactActions}>
              <ActivityLogStudent />
            </ProtectedComponent>
          }
        />
      </Route>
      <Route
        path="/masterContact/:id"
        element={
          <ProtectedComponent permissions={["master_contact_view_own", "master_contact_view_global"]}>
            <DetailMasterContact />
          </ProtectedComponent>
        }
      >
        <Route
          path=""
          element={
            <ProtectedComponent permissions={masterContactActions}>
              <ProfileMasterContact />
            </ProtectedComponent>
          }
        />
        {/* List Contact Point Master Route */}
        <Route
          path="listContactPoint"
          element={
            <ProtectedComponent permissions={masterContactActions}>
              <ListMasterContactPoint />
            </ProtectedComponent>
          }
        />
        <Route
          path="application"
          element={
            <ProtectedComponent permissions={masterContactActions}>
              <ApplicationMaster />
            </ProtectedComponent>
          }
        />
        <Route
          path="tasks"
          element={
            <ProtectedComponent permissions={masterContactActions}>
              <TaskMaster />
            </ProtectedComponent>
          }
        />
        {/* Activity Log Master Route */}
        <Route
          path="activityLog"
          element={
            <ProtectedComponent permissions={masterContactActions}>
              <ActivityLogMaster />
            </ProtectedComponent>
          }
        />
      </Route>
      <Route
        path="/role"
        element={
          <ProtectedComponent permissions={["role_view"]}>
            <Role />
          </ProtectedComponent>
        }
      />
      <Route
          path="/student-admission-period"
          element={
              <ProtectedComponent permissions={["online_admission_view"]}>
                <StudentAdmissionPeriod />
              </ProtectedComponent>
          }
      />
      <Route
        path="/role/create"
        element={
          <ProtectedComponent permissions={["role_create"]}>
            <CreateRole />
          </ProtectedComponent>
        }
      />
      <Route
        path="/role/edit/:id"
        element={
          <ProtectedComponent permissions={["role_edit"]}>
            <EditRole />
          </ProtectedComponent>
        }
      />
      <Route
        path="/user"
        element={
          <ProtectedComponent permissions={["user_view"]}>
            <User />
          </ProtectedComponent>
        }
      />
      <Route
        path="/user/create"
        element={
          <ProtectedComponent permissions={["user_create"]}>
            <CreateUser />
          </ProtectedComponent>
        }
      />
      <Route
        path="/user/:id/"
        element={
          <ProtectedComponent permissions={["user_edit"]}>
            <UpdateUser />
          </ProtectedComponent>
        }
      />
      <Route
        path="/studentList"
        element={
          <ProtectedComponent permissions={["student_list_view_own", "student_list_view_global"]}>
            <StudentList />
          </ProtectedComponent>
        }
      />
      <Route
        path="/masterList"
        element={
          <ProtectedComponent permissions={["student_list_view_own", "student_list_view_global"]}>
            <MasterList />
          </ProtectedComponent>
        }
      />
      <Route
        path="/notify/:id/"
        element={
          <ProtectedComponent>
            <NotifyDetail />
          </ProtectedComponent>
        }
      />
      <Route
        path="/onlineAdmission"
        element={
          <ProtectedComponent  permissions={["online_admission_view"]}>
            <OnlineAdmission />
          </ProtectedComponent>
        }
      />
      <Route path="/landingPageReferralLink" element={<LandingPageReferralLink />} />
      <Route path="/registerForm-admin" element={
          <ProtectedComponent  permissions={["online_admission_edit"]}>
            <RegisterForm />
          </ProtectedComponent>
      } />
      <Route path="/registerForm" element={<RegisterFormDisabled />} />
        {/*<Route path="/registerForm" element={<RegisterForm />} />*/}
      <Route path="/edit-documents" element={<RegisterForm />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/login" element={<Login />} />
      <Route path="/auth/callback/google" element={<LoginGoogle />} />
    </Routes>
  );
}

export default AppRoutes;
