import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
  },
  wrapContent: {
    backgroundColor: "#FAFAFA",
    borderRadius: "4px 4px 0 0",
    paddingBottom: "16px",
  },
  wrapNodata: {
    backgroundColor: "#FAFAFA",
  },
  noData: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    backgroundColor: "#FDFDFD",
    border: "1px solid #C7D2DE",
    borderRadius: "4px",
    margin: "16px 16px 0 16px",
    padding: "16px",
    minHeight: "300px",
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    marginBottom: "19px",
  },
  label: {
    fontSize: "14px",
    fontWeight: 700,
    whiteSpace: "nowrap",
    minWidth: "100px",
  },
  wrapValueItem: {
    width: "100%",
    fontSize: "14px",
    maxWidth: "100%",
    overflow: "hidden",
    "& img": {
      maxWidth: "100%",
    },
  },
  success: {
    color: "green",
  },
  fail: {
    color: "red",
  },
  btnBack: {
    margin: "16px 16px 0 16px",
    backgroundColor: "#63727C",
    "&:hover": {
      backgroundColor: "#63727C",
    },
  },
  successContactLink: {
    color: "green",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "14px",
    "&: hover": {
      color: "#F08377",
      transition: "ease-in 0.2s",
    },
  },
  failContactLink: {
    color: "red",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "14px",
    "&: hover": {
      color: "#456589",
      transition: "ease-in 0.2s",
    },
  },
}));
