import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "components/Buttons/BaseButton";
import Loading from "components/Loading/Loading";
import HTMLReactParser from "html-react-parser";
import { LooseObject } from "models/common";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import { getDistrict } from "store/locationSlice";
import {
  getCandidateDocument,
  getCandidateInformation,
  getHighSchoolByDistrict,
  getOptionsRegisterForm,
  isLoadingImageSelector,
  registerSchool,
  resetNotify,
  updateCandidate,
  updateLoadingImage,
  updateOptions,
} from "store/registerFormSlice";
import { ADMISSION_FIELD, FIELD_STUDY_RESULT, regexNumber, startCaseTitle } from "utils";
import * as Yup from "yup";
import logo from "../../../assets/images/logo-school.svg";
import { transformUrlsToFiles } from "./../../../utils/helper";
import FooterRegister from "./FooterRegister";
import GeneralInformation from "./GeneralInformation";
import ModalNotify from "./ModalNotify";
import RegistrationDossier from "./RegistrationDossier";
import { useStyles } from "./styles";

const RegisterForm = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation(["register", "home"]);
  const currentLanguage = i18n.language;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const [loading, setLoading] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(true);
  const [isShowApplication, setIsShowApplication] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const errorStore = useSelector((state: RootState) => state.registerForm.error);
  const notification = useSelector((state: RootState) => state.registerForm.notification);
  const isLoadingImage = useSelector(isLoadingImageSelector);
  const [isShowNotify, setIsShowNotify] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const generalInformation = JSON.parse(String(localStorage.getItem("generalInformation")) ?? "");
  const score = JSON.parse(String(localStorage.getItem("score")) ?? "");
  const [id, setId] = useState<number | null>(null);
  const [isOpenModalRemind, setIsOpenModalRemind] = useState<boolean>(false);
  const [dataRegister, setDataRegister] = useState<AdmissionSubmitForm | null>(null);

  useEffect(() => {
    document.cookie = "cookieName=cookieValue; SameSite=None; Secure";
    if (currentLanguage !== "vi") {
      i18n.changeLanguage("vi");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notification) {
      setIsShowNotify(true);
    } else {
      setIsShowNotify(false);
    }
  }, [notification]);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .trim()
      .required(t("home:this_field_is_required"))
      .max(255, t("home:this_field_must_not_exceed_255_characters")),
    email: Yup.string()
      .trim()
      .required(t("home:this_field_is_required"))
      .email(t("home:email_is_invalid"))
      .max(255, t("home:this_field_must_not_exceed_255_characters")),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, t("home:this_field_must_be_a_number"))
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      }),
    address: Yup.string().trim().required(t("home:this_field_is_required")),
    date_of_birth: Yup.date().required(t("home:this_field_is_required")).typeError("Ngày sinh không hợp lệ"),
    highschool_id: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    city_id: Yup.number().typeError(t("home:this_field_must_be_a_number")).required(t("home:this_field_is_required")),
    district_id: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    language_assessment: Yup.number().when("admission_method_id", {
      // eslint-disable-next-line eqeqeq
      is: (val: any) => val == 1,
      then: (schema: any) => schema.required(t("home:this_field_is_required")),
      otherwise: (schema: any) => schema.nullable(),
    }),
    gender: Yup.number().typeError(t("home:this_field_must_be_a_number")).required(t("home:this_field_is_required")),
    citizen_id: Yup.string()
      .matches(regexNumber, t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      }),
    aspiration_id: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    admission_method_id: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    personal_opinion: Yup.string().trim().max(255, t("home:this_field_must_not_exceed_255_characters")).nullable(),
    academy_performance_10_1: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_conduct_10_1: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_performance_10_2: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_conduct_10_2: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_performance_11_1: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_conduct_11_1: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_performance_11_2: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_conduct_11_2: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_performance_12_1: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_conduct_12_1: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .required(t("home:this_field_is_required")),
    academy_performance_12_2: Yup.number().typeError(t("home:this_field_must_be_a_number")).nullable(),
    academy_conduct_12_2: Yup.number().typeError(t("home:this_field_must_be_a_number")).nullable(),
    admission_period_id: Yup.number()
      .typeError(t("home:this_field_must_be_a_number"))
      .when("admission_method_id", {
        // eslint-disable-next-line eqeqeq
        is: (val: any) => val == 1,
        then: (schema: any) => schema.required(t("home:this_field_is_required")),
        otherwise: (schema: any) => schema.nullable(),
      }),
    citizen_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .typeError(t("home:this_field_is_required"))
      .min(1, t("home:this_field_is_required"))
      .max(2, "Chọn tối đa 2 ảnh")
      .test("fileType", t("home:invalid_file_format"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (!["image/jpeg", "image/png"].includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
            return false;
          }
        }
        return true;
      }),
    transcript_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .typeError(t("home:this_field_is_required"))
      .min(1, t("home:this_field_is_required"))
      .max(20, "Chọn tối đa 20 ảnh")
      .test("fileType", t("home:invalid_file_format"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (!["image/jpeg", "image/png"].includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
            return false;
          }
        }
        return true;
      }),
    language_certificate_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .max(10, "Chọn tối đa 10 ảnh")
      .test("fileType", t("home:invalid_file_format"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (!["image/jpeg", "image/png"].includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
            return false;
          }
        }
        return true;
      })
      .nullable(),
    ielts_toefl_certificate_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .when("admission_method_id", {
        // eslint-disable-next-line eqeqeq
        is: (val: any) => val == 3,
        then: (schema: any) =>
          schema
            .typeError(t("home:this_field_is_required"))
            .min(1, t("home:this_field_is_required"))
            .max(10, "Chọn tối đa 10 ảnh")
            .test("fileType", t("home:invalid_file_format"), (value: any) => {
              if (!Array.isArray(value)) return true;
              for (let i = 0; i < value.length; i++) {
                if (!["image/jpeg", "image/png"].includes(value[i].type)) {
                  return false;
                }
              }
              return true;
            })
            .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value: any) => {
              if (!Array.isArray(value)) return true;
              for (let i = 0; i < value.length; i++) {
                if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
                  return false;
                }
              }
              return true;
            }),
        otherwise: (schema: any) => schema.nullable(),
      }),
    national_certificate_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .when("admission_method_id", {
        // eslint-disable-next-line eqeqeq
        is: (val: any) => val == 2,
        then: (schema: any) =>
          schema
            .typeError(t("home:this_field_is_required"))
            .min(1, t("home:this_field_is_required"))
            .max(10, "Chọn tối đa 10 ảnh")
            .test("fileType", t("home:invalid_file_format"), (value: any) => {
              if (!Array.isArray(value)) return true;
              for (let i = 0; i < value.length; i++) {
                if (!["image/jpeg", "image/png"].includes(value[i].type)) {
                  return false;
                }
              }
              return true;
            })
            .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value: any) => {
              if (!Array.isArray(value)) return true;
              for (let i = 0; i < value.length; i++) {
                if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
                  return false;
                }
              }
              return true;
            }),
        otherwise: (schema: any) => schema.nullable(),
      }),
    personal_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .min(1, t("home:this_field_is_required"))
      .max(1, "Chọn tối đa 1 ảnh")
      .test("fileType", t("home:invalid_file_format"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (!["image/jpeg", "image/png"].includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
            return false;
          }
        }
        return true;
      })
      .nullable(),
    award_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .test("fileType", t("home:invalid_file_format"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (!["image/jpeg", "image/png"].includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
            return false;
          }
        }
        return true;
      })
      .when("admission_method_id", {
        // eslint-disable-next-line eqeqeq
        is: (val: any) => val == 5,
        then: (schema: any) =>
          schema
            .typeError(t("home:this_field_is_required"))
            .min(1, t("home:this_field_is_required"))
            .max(10, "Chọn tối đa 10 ảnh"),
        otherwise: (schema: any) => schema.nullable(),
      })
        .when("admission_method_id", {
          // eslint-disable-next-line eqeqeq
          is: (val: any) => val == 5,
          then: (schema: any) =>
              schema
                  .typeError(t("home:this_field_is_required"))
                  .min(1, t("home:this_field_is_required"))
                  .max(10, "Chọn tối đa 10 ảnh"),
          otherwise: (schema: any) => schema.nullable(),
        }),
    highschool_graduation_exam_test_result_image: Yup.array()
        .transform((currentValue, originalValue) => {
          if (!Array.isArray(originalValue)) {
            return [];
          }
          return currentValue;
        })
        .typeError(t("home:this_field_is_required"))
        .min(1, t("home:this_field_is_required"))
        .max(10, "Chọn tối đa 10 ảnh")
        .test("fileType", t("home:invalid_file_format"), (value) => {
          if (!Array.isArray(value)) return true;
          for (let i = 0; i < value.length; i++) {
            if (!["image/jpeg", "image/png"].includes(value[i].type)) {
              return false;
            }
          }
          return true;
        })
        .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
          if (!Array.isArray(value)) return true;
          for (let i = 0; i < value.length; i++) {
            if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
              return false;
            }
          }
          return true;
        }),
    extra_international_certificate_images: Yup.array()
        .transform((currentValue, originalValue) => {
          if (!Array.isArray(originalValue)) {
            return [];
          }
          return currentValue;
        })
        .max(10, "Chọn tối đa 10 ảnh")
        .test("fileType", t("home:invalid_file_format"), (value) => {
          if (!Array.isArray(value)) return true;
          for (let i = 0; i < value.length; i++) {
            if (!["image/jpeg", "image/png"].includes(value[i].type)) {
              return false;
            }
          }
          return true;
        })
        .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
          if (!Array.isArray(value)) return true;
          for (let i = 0; i < value.length; i++) {
            if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
              return false;
            }
          }
          return true;
        }),
    fee_payment_proof_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .typeError(t("home:this_field_is_required"))
      .min(1, t("home:this_field_is_required"))
      .max(2, "Chọn tối đa 2 ảnh")
      .test("fileType", t("home:invalid_file_format"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (!["image/jpeg", "image/png"].includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value) => {
        if (!Array.isArray(value)) return true;
        for (let i = 0; i < value.length; i++) {
          if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
            return false;
          }
        }
        return true;
      }),
    competency_assessment_score_image: Yup.array()
      .transform((currentValue, originalValue) => {
        if (!Array.isArray(originalValue)) {
          return [];
        }
        return currentValue;
      })
      .when("admission_method_id", {
        // eslint-disable-next-line eqeqeq
        is: (val: any) => val == 4,
        then: (schema: any) =>
          schema
            .typeError(t("home:this_field_is_required"))
            .min(1, t("home:this_field_is_required"))
            .max(10, "Chọn tối đa 10 ảnh")
            .test("fileType", t("home:invalid_file_format"), (value: any) => {
              if (!Array.isArray(value)) return true;
              for (let i = 0; i < value.length; i++) {
                if (!["image/jpeg", "image/png"].includes(value[i].type)) {
                  return false;
                }
              }
              return true;
            })
            .test("fileSize", t("home:the_file_must_not_be_greater_than_10MB"), (value: any) => {
              if (!Array.isArray(value)) return true;
              for (let i = 0; i < value.length; i++) {
                if (value[i] && "size" in value[i] && Number(value[i].size) > 1024 * 1024 * 10) {
                  return false;
                }
              }
              return true;
            }),
        otherwise: (schema: any) => schema.nullable(),
      }),
  });

  type AdmissionSubmitForm = Yup.InferType<typeof validationSchema>;
  type KeyError = keyof AdmissionSubmitForm;

  const {
    control,
    register,
    setValue,
    formState: { errors },
    watch,
    handleSubmit,
    setError,
    clearErrors,
    setFocus,
    getValues,
    resetField,
  } = useForm<AdmissionSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const cityId = watch("city_id");
  const districtId = watch("district_id");

  const initialized = useRef(false);
  const getDataOption = async () => {
    setLoading(true);
    dispatch(getOptionsRegisterForm());
    setLoading(false);
  };

  useEffect(() => {
    const updateDistrictOption = async () => {
      const districts = await dispatch(getDistrict([cityId]));
      if (districts) {
        dispatch(updateOptions({ district: districts }));
      }
    };
    if (cityId) {
      updateDistrictOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId]);

  useEffect(() => {
    const updateHighSchoolOption = async () => {
      const highSchools = await dispatch(getHighSchoolByDistrict(districtId));
      if (highSchools) {
        dispatch(updateOptions({ highSchool: highSchools }));
      }
    };
    if (districtId) {
      updateHighSchoolOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtId]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorStore?.length !== 0) {
      let errors = "<div>";
      errorStore?.forEach((item: any) => {
        let check = true;
        if (Object.keys(validationSchema.fields).includes(item.key)) {
          if (check) {
            setFocus(item.key);
            check = false;
          }
          setError(item.key, {
            type: "manual",
            message: item.message.join(","),
          });
        }
        errors += item.message.join(",");
        errors += "</br>";
      });
      errors += "</div>";
      toast.error<void>(HTMLReactParser(errors), { autoClose: 15000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorStore, setError]);

  useEffect(() => {
    const getData = async (token: string) => {
      try {
        const data = await dispatch(getCandidateInformation(token));

        if (data) {
          Object.entries(data).forEach(([key, value]) => {
            if (Object.keys(validationSchema.fields).includes(key) && value) {
              setValue(String(key) as any, (value as any) ?? "");
            }
          });
          setId(data?.id);
        } else {
          toast.error<void>("Lỗi xảy ra khi nhận dữ liệu, đề nghị kiểm tra lại (code: 404)");
          return;
        }
        const dataDocument = await dispatch(getCandidateDocument(token));
        if (dataDocument) {
          Object.entries(dataDocument).forEach(([key, value]) => {
            if (
              key === "admission_period" &&
              dataDocument?.admission_method_id === 1 &&
              value &&
              typeof value === "object" &&
              "id" in value &&
              "number" in value
            ) {
              setValue("admission_period_id", value?.id as any);
              dispatch(updateOptions({ admissionPeriod: [{ ...value, name: `Đợt ${value?.number}` }] }));
            }
            if (Object.keys(validationSchema.fields).includes(key) && value) {
              setValue(String(key) as any, (value as any) ?? "");
            }
          });
          const imagePath = dataDocument?.image_path;
          for (const key in imagePath) {
            if (Array.isArray(imagePath[key]) && imagePath[key].length > 0) {
              const data = await transformUrlsToFiles(imagePath[key]);
              setValue(String(key) as any, (data as any) ?? "");
            }
          }
          dispatch(updateLoadingImage(false));
        } else {
          toast.error<void>("Lỗi xảy ra khi nhận dữ liệu, đề nghị kiểm tra lại (code: 404)");
          return;
        }
      } catch (error) {
        toast.error<void>(`Lỗi xảy ra khi nhận dữ liệu, đề nghị kiểm tra lại (code: ${error})`);
        dispatch(updateLoadingImage(false));
      }
    };
    if (token) {
      getData(token);
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const aspirationId = watch("aspiration_id");
  const admissionMethodId = watch("admission_method_id");

  useEffect(() => {
    if (!isUpdate) {
      if (generalInformation) {
        Object.entries(generalInformation).forEach(([key, value]) => {
          if (Object.keys(validationSchema.fields).includes(key) && value) {
            setValue(String(key) as any, (value as any) ?? "");
          }
        });
      }
      if (score) {
        Object.entries(score).forEach(([key, value]) => {
          if (Object.keys(validationSchema.fields).includes(key) && value) {
            setValue(String(key) as any, (value as any) ?? "");
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  useEffect(() => {
    if (aspirationId && admissionMethodId) {
      setIsShowApplication(true);
    } else {
      setIsShowApplication(false);
    }
  }, [aspirationId, admissionMethodId]);

  const onSubmitForm = async (data: AdmissionSubmitForm) => {
    setLoading(true);
    if (Boolean(process.env.REACT_APP_RECAPTCHA_SITE_KEY) && !captchaValue) {
      return;
    }
    if (isUpdate) {
      try {
        let dataSubmit: LooseObject = {
          ...data,
          full_name: data?.full_name && startCaseTitle(data?.full_name),
          date_of_birth: data?.date_of_birth && moment(data?.date_of_birth).format("YYYY/MM/DD"),
        };
        dataSubmit.token = token;

        const result: any = await dispatch(updateCandidate({ id: id, token: token, data: dataSubmit }));
        if (result.meta.requestStatus === "fulfilled") {
          toast.success<void>(t("update_information_successfully"));
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else if (result?.payload?.response?.status === 422) {
          toast.error<void>(
            `${t("some_fields_contain_invalid_data", { ns: "home" })} (code: ${result.payload.response.status})`,
            { autoClose: 5000 }
          );
        } else {
          toast.error<void>(`${t("an_error_occurred", { ns: "home" })} (code: ${result.payload.response.status})`);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (error) {
        toast.error<void>(`${t("an_error_occurred", { ns: "home" })} (code: ${error})`);
        setLoading(false);
      }
    } else {
      setIsOpenModalRemind(true);
      setDataRegister(data);
    }
    setLoading(false);
  };

  const handleRegisterForm = async () => {
    setIsOpenModalRemind(false);
    setLoading(true);
    try {
      handleSaveToTemporaryMemory();
      let dataSubmit: LooseObject = {
        ...dataRegister,
        full_name: dataRegister?.full_name && startCaseTitle(dataRegister?.full_name),
        date_of_birth: dataRegister?.date_of_birth && moment(dataRegister?.date_of_birth).format("YYYY/MM/DD"),
      };

      const result: any = await dispatch(registerSchool(dataSubmit));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success<void>(t("registration_successful"));
        handleResetForm();
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (result?.payload?.response?.status === 422) {
        toast.error<void>(
          `${t("some_fields_contain_invalid_data", { ns: "home" })} (code: ${result.payload.response.status})`,
          { autoClose: 5000 }
        );
      } else {
        toast.error<void>(`${t("an_error_occurred", { ns: "home" })} (code: ${result.payload.response.status})`);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      toast.error<void>(`${t("an_error_occurred", { ns: "home" })} (code: ${error})`);
      setLoading(false);
    }
    setDataRegister(null);
    setLoading(false);
  };

  const handleResetForm = () => {
    resetField("aspiration_id");
    resetField("admission_method_id");
    setValue("personal_opinion", "");
    setValue("citizen_image", []);
    setValue("transcript_image", []);
    setValue("language_certificate_image", []);
    setValue("ielts_toefl_certificate_image", []);
    setValue("national_certificate_image", []);
    setValue("highschool_graduation_exam_test_result_image", []);
    setValue("personal_image", []);
    setValue("award_image", []);
    setValue("fee_payment_proof_image", []);
    setValue("competency_assessment_score_image", []);
    setValue("extra_international_certificate_images", []);
  };

  const handleChangeAdmissionMethod = useCallback((name: string, data: any, reason: string) => {
    clearErrors([
      "citizen_image",
      "transcript_image",
      "language_certificate_image",
      "ielts_toefl_certificate_image",
      "national_certificate_image",
      "highschool_graduation_exam_test_result_image",
      "personal_image",
      "award_image",
      "extra_international_certificate_images",
      "fee_payment_proof_image",
      "competency_assessment_score_image",
    ]);
    switch (data?.id) {
      case 1:
        setValue("ielts_toefl_certificate_image", []);
        setValue("national_certificate_image", []);
        setValue("competency_assessment_score_image", []);
        break;
      case 2:
        setValue("language_certificate_image", []);
        setValue("ielts_toefl_certificate_image", []);
        setValue("competency_assessment_score_image", []);
        break;
      case 3:
        setValue("language_certificate_image", []);
        setValue("national_certificate_image", []);
        setValue("competency_assessment_score_image", []);
        break;
      case 4:
        setValue("language_certificate_image", []);
        setValue("national_certificate_image", []);
        setValue("ielts_toefl_certificate_image", []);
        break;
      case 5:
        setValue("language_certificate_image", []);
        setValue("national_certificate_image", []);
        setValue("ielts_toefl_certificate_image", []);
        setValue("competency_assessment_score_image", []);
        break;
      case 6:
        setValue("highschool_graduation_exam_test_result_image", []);
        setValue("extra_international_certificate_images", []);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCLoseModalNotify = () => {
    setIsShowNotify(false);
    dispatch(resetNotify());
  };

  const onChangeCaptcha = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleSaveToTemporaryMemory = () => {
    try {
      let data: LooseObject = {};
      ADMISSION_FIELD.forEach((item: LooseObject) => {
        data = {
          ...data,
          [item.name]: getValues(item.name),
        };
      });
      localStorage.setItem("generalInformation", JSON.stringify(data));

      let dataScore: LooseObject = {};
      FIELD_STUDY_RESULT.forEach((grade) => {
        dataScore = {
          ...dataScore,
          [`${grade.performance}`]: getValues(grade.performance as KeyError),
          [`${grade.conduct}`]: getValues(grade.conduct as KeyError),
        };
      });
      localStorage.setItem("score", JSON.stringify(dataScore));
    } catch (error) {
      toast.error<void>(t(error));
    }
  };

  const handleChangeLocal = (name: string, data: any, reason: string) => {
    if (name === "city_id") {
      resetField("district_id");
      resetField("highschool_id");
      dispatch(updateOptions({ district: [], highSchool: [] }));
    } else if (name === "district_id") {
      resetField("highschool_id");
      dispatch(updateOptions({ highSchool: [] }));
    }
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t("online_admission_application")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className={classes.header}>
        <div className={classes.wrapHeader}>
          <Link to="https://vju.ac.vn" target="blank">
            <img src={logo} alt="logo" className={classes.logo} />
          </Link>
        </div>
      </div>
      <div className={classes.content}>
        <Box mb={2} className={classes.banner}>
          <Box className={classes.textBanner}>
            <Typography color={"#fff"} variant={isMobileScreen ? "h5" : "h3"}>
              {t("online_admission_application")}
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <GeneralInformation
            control={control}
            register={register}
            watch={watch}
            errors={errors}
            handleChangeLocal={handleChangeLocal}
          />
          <RegistrationDossier
            isShowApplication={isShowApplication}
            control={control}
            register={register}
            setValue={setValue}
            watch={watch}
            admissionMethodId={admissionMethodId}
            errors={errors}
            handleChangeAdmissionMethod={handleChangeAdmissionMethod}
            isUpdate={isUpdate}
            setError={setError}
          />
          <div className={classes.wrapBtnFooter}>
            {Boolean(process.env.REACT_APP_RECAPTCHA_SITE_KEY) && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? "your-secret-key"}
                onChange={onChangeCaptcha}
                hl="vi"
              />
            )}
            <Button
              title={t("submit_application")}
              className={classes.btnSubmit}
              onClick={handleSubmit(onSubmitForm)}
              isDisabled={(Boolean(process.env.REACT_APP_RECAPTCHA_SITE_KEY) && !captchaValue) || isLoadingImage}
            />
          </div>
        </form>
      </div>
      <FooterRegister />
      {isShowNotify && (
        <ModalNotify
          isOpen={isShowNotify}
          onClose={handleCLoseModalNotify}
          content={notification}
          onConfirm={handleCLoseModalNotify}
        />
      )}
      {isOpenModalRemind && (
        <ModalNotify
          title={"LƯU Ý"}
          isOpen={isOpenModalRemind}
          onClose={() => setIsOpenModalRemind(false)}
          onConfirm={handleRegisterForm}
          content={
            "Thí sinh <span style='color: red; font-weight: bold'>vui lòng chuyển khoản</span> số tiền tương ứng với phương thức đăng ký và <span style='color: red; font-weight: bold'>kiểm tra kỹ điểm hồ sơ</span>, mọi thông tin sai thí sinh sẽ tự chịu trách nhiệm!"
          }
          classNameHeader={classes.headerModalRemind}
          isShowFooterReminder
        />
      )}
    </div>
  );
};

export default RegisterForm;
