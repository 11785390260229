import BackspaceIcon from "@mui/icons-material/Backspace";
import { Button, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LooseObject } from "../../models/common";
import SearchBar from "../inputSearch";
import { useStyles } from "./styles";
import ModalConfirm from "components/Modal/ModalConfirm";

interface DropdownQueryProp {
  title: string;
  options: LooseObject[];
  className?: string;
  withSearch?: boolean;
  onClickItem: Function;
  onDelete: Function;
  startIcon?: JSX.Element | null;
  endIcon?: JSX.Element | null;
  icon?: JSX.Element | null;
}

export default function DropdownQuery({
  title,
  options,
  className,
  withSearch,
  onClickItem,
  endIcon,
  onDelete,
  icon,
}: DropdownQueryProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchText, setSearchText] = useState<string>("");
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchText("");
  };

  const handleSearchInputChange = (event: string) => {
    setSearchText(event);
  };
  const filteredOptions = options.filter((option: LooseObject | string) => {
    if (typeof option === "string") {
      return String(option)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          searchText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        );
    }
    return option.name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(
        searchText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      );
  });

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={endIcon}
        onClick={handleClick}
        className={`${className} ${classes.btn}`}
      >
        {title}
        {icon}
      </Button>
      <Menu
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            maxHeight: "250px",
          },
        }}
        className={withSearch ? classes.menuTop : ""}
      >
        {withSearch && (
          <div className={classes.menuSearch}>
            <SearchBar
              value={searchText}
              className={classes.search}
              handleChange={handleSearchInputChange}
              autoFocus={true}
            />
          </div>
        )}
        {filteredOptions.length === 0 ? (
          <MenuItem className={classes.menuItem}>
            <Typography variant="inherit" noWrap>
              {t("no_data_found")}
            </Typography>
          </MenuItem>
        ) : (
          <div>
            {filteredOptions.map((item: LooseObject, index: number) => (
              <MenuItem className={classes.menuItem} key={`${index}-b`}>
                <Tooltip title={item.name} placement="left">
                  <Typography
                    variant="inherit"
                    noWrap
                    onClick={() => onClickItem(item.id)}
                    className={classes.wrapItem}
                  >
                    {typeof item === "string" ? item : item.name}
                  </Typography>
                </Tooltip>
                <IconButton
                  onClick={() => {
                    setIsOpenModalConfirm(true);
                    setCurrentItem(item);
                  }}
                >
                  <BackspaceIcon className={classes.iconDelete} />
                </IconButton>
              </MenuItem>
            ))}
          </div>
        )}
      </Menu>
      {isOpenModalConfirm && (
        <ModalConfirm
          isOpen={isOpenModalConfirm}
          title={t("confirmation")}
          htmlContent={
            <p>
              {t("are_you_sure_to_remove")} <span className={classes.nameQuery}>{`${currentItem.name}?`}</span>
            </p>
          }
          onClose={() => {
            setIsOpenModalConfirm(false);
            handleClose();
          }}
          onConfirm={() => {
            onDelete(currentItem.id);
            handleClose();
            setIsOpenModalConfirm(false);
          }}
        />
      )}
    </>
  );
}
