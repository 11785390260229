import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { LooseObject } from "models/common";
import { API } from "utils/api";
import { axiosClient } from "../services/axiosClient";
import { MasterContactState } from "./masterContactSlice";

export interface DetailMasterContactState {
  contact: LooseObject;
  selectedTab: string;
  error: LooseObject[];
}

const initialState: DetailMasterContactState = {
  contact: {},
  selectedTab: "",
  error: [],
};

const detailMasterContactSlice = createSlice({
  name: "detailMasterContact",
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
    setContactDetail: (state, action) => {
      state.contact = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMasterContact.fulfilled, (state, action) => {
        state.contact = action.payload;
      })
      .addCase(editMasterContact.rejected, (state, action) => {
        let typedPayload = action.payload as AxiosError<any>;
        const err = typedPayload?.response?.data?.errors;
        if (Object.keys(err).length !== 0) {
          const array: LooseObject[] = [];
          Object.entries(err).forEach(([key, value]) => {
            array.push({ key: key, message: value });
            state.error = array;
          });
        }
      });
  },
});

export const { setSelectedTab, setContactDetail } = detailMasterContactSlice.actions;

export const getMasterContact = createAsyncThunk(
  "masterContact/getDetail",
  async (data: { id: any; isFilter?: boolean }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { mastercontacts } = getState() as { mastercontacts: MasterContactState };
      const { searchOption, selectedTab } = mastercontacts;
      const { id, isFilter } = data;
      const params: LooseObject = {};
      if (isFilter) {
        Object.entries(searchOption).map(([key, value]) => {
          if (value && typeof value === "string") {
            params[key] = value;
          } else if (value && Array.isArray(value)) {
            params[key] = value.map((item: LooseObject) => item.id);
          } else if (value.name) {
            params[key] = value.id;
          }
          return null;
        });
        params.filter_option = selectedTab;
      }

      const url = `${API.masterContact}/${id}`;
      const response = await axiosClient.get(url, { params: { ...params } });
      const contact = { ...response.data.data };
      Object.entries(response.data.data.current).map(([key, value]) => {
        if (value && typeof value == "object" && "id" in value && key.includes("aspiration")) {
          contact[key] = value;
        } else if (value && typeof value == "object" && "id" in value) {
          contact[`${key}_id`] = value;
        } else {
          contact[key] = value;
        }
        return null;
      });
      if (contact["english_language_certificate"].type) {
        contact["english_language_certificate"].type = String(contact["english_language_certificate"].type).toUpperCase();
      }
      if (contact["english_language_certificate"].type && contact["english_language_certificate"].type === "IELTS") {
        if (Number(contact["english_language_certificate"].value) === 0) {
          contact["english_language_certificate"] = null;
        } else {
          contact["english_language_certificate"].value = Number(contact["english_language_certificate"].value);
        }
      }
      delete contact.current;
      return fulfillWithValue(contact);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editMasterContact = createAsyncThunk(
  "masterContact/editContact",
  async (data: { id: any; value: LooseObject }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { id, value } = data;
      const url = `${API.masterContact}/${id}`;
      const response = await axiosClient.put(url, value);
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editFieldMasterContact = createAsyncThunk(
  "masterContact/editFieldContact",
  async (data: { id: any; value: LooseObject }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { id, value } = data;
      const url = `${API.masterContact}/${id}`;
      const response = await axiosClient.patch(url, value);
      return fulfillWithValue(response.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export default detailMasterContactSlice;
