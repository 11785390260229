import { yupResolver } from "@hookform/resolvers/yup";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AutoCompleteForm from "components/Forms/AutoCompleteForm";
import ButtonCancelForm from "components/Forms/ButtonCancelForm";
import ButtonSaveForm from "components/Forms/ButtonSaveForm";
import InputTextForm from "components/Forms/InputTextForm";
import Loading from "components/Loading/Loading";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import {
  errorContactCategorySelector,
  fetchContactCategoriesByContactId,
  getContactCategoryById,
  updateContactCategory,
} from "store/contactCategorySlice";
import unitTagSlice, { createMultipleUnitTag, getUnitTagList, unitTagListSelector } from "store/unitTagSlice";
import * as Yup from "yup";
import { useStyles } from "./styles";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface ModalFilterProp {
  isOpen: boolean;
  onClose: Function;
  contactCategoryId: number;
  onSubmitModal?: () => void;
}

export default function ModalEditContactList({ isOpen, onClose, contactCategoryId, onSubmitModal }: ModalFilterProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearchUnit, setIsLoadingSearchUnit] = useState<boolean>(false);
  const errorFromApis = useSelector(errorContactCategorySelector);
  const contactCategory = useSelector((state: RootState) => state.contactCategory?.contactCategory);
  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState<boolean>(false);

  useEffect(() => {
    if (contactCategoryId > 0 && isOpen) {
      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
      setIsLoading(true);
      clearErrors();
      dispatch<any>(getContactCategoryById(contactCategoryId));
      dispatch<any>(getUnitTagList());
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contactCategoryId, isOpen]);

  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required(t("name_is_required")).max(255, t("name_must_not_exceed_255_characters")),
    unit_tags: Yup.array().typeError(t("unit_tags_is_not_valid")),
    source: Yup.string().nullable().max(255, t("source_must_not_exceed_255_characters")),
  });

  type EditContactListSubmitForm = Yup.InferType<typeof validationSchema>;
  const {
    reset,
    clearErrors,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<EditContactListSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: contactCategoryId,
      name: "",
      unit_tags: [],
      source: "",
    },
  });

  const handleBackClick = () => {
    isDirty ? setIsOpenModalConfirmLeaveForm(true) : onClose();
  };

  useEffect(() => {
    setValue("name", contactCategory.name);
    setValue("unit_tags", contactCategory.unit_tags);
    setValue("source", contactCategory.source);
  }, [contactCategory, setValue]);

  const handleSubmitModal = async (data: EditContactListSubmitForm) => {
    setIsLoading(true);
    let dataCreateUnitTag = [];

    const dataUnitTag = data?.unit_tags?.map((item: any) => item.id).filter((value: any) => value !== undefined);
    if (data?.unit_tags?.length ?? 0) {
      const createUnitTagsData = data?.unit_tags
        ?.map((item: any) => item.inputValue)
        .filter((value: any) => value !== undefined);
      if (createUnitTagsData?.length) {
        const responseCreateMultipleUnitTag = await dispatch<any>(createMultipleUnitTag(createUnitTagsData ?? []));
        if (responseCreateMultipleUnitTag.meta?.requestStatus === "fulfilled") {
          dataCreateUnitTag = responseCreateMultipleUnitTag.payload;
          toast.success<void>(t("create_unit_tag_successful"));
        } else {
          toast.error<void>(t("fail_to_create_unit_tag"));
        }
      }
    }

    const dataSubmit = {
      id: contactCategoryId,
      name: data.name,
      unit_tags: [...dataCreateUnitTag, ...(dataUnitTag as any[])],
      source: data.source ?? "",
    };

    const response = await dispatch<any>(updateContactCategory(dataSubmit));
    if (response?.meta?.requestStatus === "fulfilled") {
      toast.success<void>(t("edit_contact_to_list_successful"));
      onClose();
      await dispatch<any>(fetchContactCategoriesByContactId());
    }
    setIsLoading(false);
  };

  const handleOnChangeSearch = async (value: any) => {
    setIsLoadingSearchUnit(true);
    dispatch(unitTagSlice.actions.setName(value));
    await dispatch<any>(getUnitTagList());
    setIsLoadingSearchUnit(false);
  };

  const unitTagList = useSelector(unitTagListSelector);

  const getErrorsValidate = (name: any) => {
    return Object.keys(errors).length !== 0 ? errors[name as keyof typeof errors]?.message : errorFromApis?.[name]?.[0];
  };

  const handleClose = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: "hidden", height: "auto" } }}
      >
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
          <FormatListBulletedIcon />
          <p>{t("edit_contact_list")}</p>
          </div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent className={classes.containerContent}>
          <>
            {isLoading && <Loading />}
            <form onSubmit={handleSubmit(handleSubmitModal)} noValidate>
              <div className={classes.containerForm}>
                <InputTextForm
                  title={t("list_name")}
                  isRequired={true}
                  name="name"
                  register={register}
                  errors={getErrorsValidate("name")}
                />
                <AutoCompleteForm
                  isLoading={isLoadingSearchUnit}
                  title={t("unit")}
                  data={unitTagList}
                  isMultiple={true}
                  name="unit_tags"
                  register={register}
                  key={"unit_tags"}
                  control={control}
                  errors={getErrorsValidate("unit_tags")}
                  onChangeSearch={handleOnChangeSearch}
                  maxHeight={120}
                  isAddOption={true}
                  isSearchApi={true}
                />
                <InputTextForm
                  title={t("source")}
                  name="source"
                  isRequired={false}
                  register={register}
                  errors={getErrorsValidate("source")}
                />
              </div>
              <div className={classes.containerBtn}>
                <ButtonCancelForm onClick={handleBackClick} />
                <ButtonSaveForm />
              </div>
            </form>
          </>
        </DialogContent>
      </Dialog>
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          setIsOpenModalConfirmLeaveForm(false);
          onClose();
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </div>
  );
}
