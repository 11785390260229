import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Button, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { LooseObject } from "../../models/common";
import SearchBar from "../inputSearch";
import { useStyles } from "./styles";

interface DropdownProp {
  title: string;
  options: any[];
  withCheckbox?: boolean;
  className?: string;
  withSearch?: boolean;
  onClickItem?: Function;
  fieldSearch?: string;
  searchOption?: LooseObject;
  isSubMenu?: boolean;
}

export default function Dropdown({
  title,
  options,
  withCheckbox,
  className,
  withSearch,
  onClickItem,
  fieldSearch,
  searchOption,
  isSubMenu,
}: DropdownProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchText, setSearchText] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item: LooseObject | string) => {
    setAnchorEl(null);
    setSearchText("");
  };

  const handleSearchInputChange = (event: string) => {
    setSearchText(event);
  };

  const filteredOptions = isSubMenu
    ? options
        .map((item: LooseObject) => ({
          ...item,
          options: item.options.filter((option: LooseObject) => {
            return option.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(
                searchText
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              );
          }),
        }))
        .filter((option: LooseObject) => option.options.length > 0)
    : options.filter((option: LooseObject | string) => {
        if (typeof option === "string") {
          return String(option)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(
              searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            );
        }
        return option.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            searchText
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          );
      });

  const renderOption = (item: LooseObject | string, index: number) => {
    let isChecked = false;
    if (
      searchOption &&
      fieldSearch &&
      typeof item !== "string" &&
      searchOption[fieldSearch].map((item: LooseObject) => item.id).includes(item.id)
    ) {
      isChecked = true;
    } else if (searchOption && fieldSearch && searchOption[fieldSearch].includes(item)) {
      isChecked = true;
    }
    return withCheckbox ? (
      <MenuItem
        key={`${index}-a`}
        className={classes.menuItem}
        onClick={() => onClickItem && onClickItem(item, fieldSearch)}
      >
        <input
          type="checkbox"
          className={classes.checkbox}
          checked={isChecked}
          onChange={() => {}}
        />
        <Typography variant="inherit" noWrap>
          {typeof item === "string" ? item : item.name}
        </Typography>
      </MenuItem>
    ) : (
      <MenuItem onClick={() => handleClose(item)} className={classes.menuItem} key={`${index}-b`}>
        <Typography variant="inherit" noWrap>
          {typeof item === "string" ? item : item.name}
        </Typography>
      </MenuItem>
    );
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<ArrowDropDownRoundedIcon />}
        onClick={handleClick}
        className={`${className} ${classes.title}`}
      >
        {title}
      </Button>
      <Menu
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={(action: LooseObject) => handleClose(action)}
        PaperProps={{
          style: {
            maxHeight: "250px",
          },
        }}
        className={withSearch ? classes.menuTop : ""}
      >
        {withSearch && (
          <div className={classes.menuSearch}>
            <SearchBar
              value={searchText}
              className={classes.search}
              handleChange={handleSearchInputChange}
              autoFocus={true}
            />
          </div>
        )}
        {filteredOptions.length === 0 ? (
          <MenuItem className={classes.menuItem}>
            <Typography variant="inherit" noWrap>
              {t("no_data_found")}
            </Typography>
          </MenuItem>
        ) : (
          <div>
            {filteredOptions.map((item: LooseObject | string, index: number) =>
              isSubMenu && typeof item !== "string" ? (
                <Fragment key={`${index} -  e`}>
                  <p className={classes.subMenu}>{item.name}</p>
                  {item.options.map((item: LooseObject, index: number) => renderOption(item, index))}
                </Fragment>
              ) : (
                renderOption(item, index)
              )
            )}
          </div>
        )}
      </Menu>
    </>
  );
}
