import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { IconButton, Step, StepLabel, Stepper } from "@mui/material";
import BaseButton from "components/Buttons/BaseButton";
import LinkButton from "components/Buttons/LinkButton";
import ImportStep1 from "components/Import/Step1";
import ImportStep2 from "components/Import/Step2";
import { IModuleField, LooseObject } from "models/common";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import readXlsxFile from "read-excel-file";
import { RootState, useAppDispatch } from "redux/store";
import { getListMasterForOption } from "store/listMasterContactSlice";
import { importMasterContact } from "store/masterContactSlice";
import { getAllNotification, getNotifyById } from "store/notifcationSlice";
import { getUnitContact, getUnitTagList } from "store/unitTagSlice";
import { MODULE_FIELD_MASTER } from "utils/constant";
import Loading from "../../../../../components/Loading/Loading";
import { useStyles } from "./styles";

const steps = [
  { name: "upload_import_file", id: 0 },
  { name: "confirm_field_mappings", id: 1 },
  { name: "wait_for_import_result", id: 2 },
  { name: "import_result", id: 3 },
];

export default function ImportMaster() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<any>(0);
  const [completeStep, setCompleteStep] = useState<number[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [moduleField, setModuleFile] = useState<IModuleField[] | null>(null);

  const [listUnit, setListUnit] = useState<LooseObject[]>([]);
  const [listContact, setListContact] = useState<LooseObject[]>([]);
  const unitTagList = useSelector((state: RootState) => state?.unitTag?.unitTags);
  const { id } = useParams();
  const [notify, setNotify] = useState<LooseObject>({});
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setListUnit(unitTagList);
  }, [unitTagList]);

  const fetchData = async () => {
    setLoading(true);
    const data = await dispatch(getNotifyById(id));
    dispatch(getAllNotification());
    if (data) {
      setNotify(data);
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
      navigate("/");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      setActiveStep(-1);
      setCompleteStep([0, 1, 2, 3]);
      fetchData();
      setError("");
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const HandleNextStep2 = async () => {
    setLoading(true);
    setActiveStep(1);
    setCompleteStep([0]);
    if (listUnit.length === 0) {
      dispatch<any>(getUnitTagList());
    }
    if (listContact.length === 0) {
      const list = await dispatch(getListMasterForOption({ page: 1, limit: 10, type: 2 }));
      if (list) {
        setListContact(list?.data);
      }
    }
    setLoading(false);
  };

  const handleImportContact = async (data: LooseObject[]) => {
    setLoading(true);
    setCompleteStep([0, 1]);
    setActiveStep(2);
    const result = await dispatch(importMasterContact([...data, { name: "import_file", value: file }]));
    if (result) {
      toast.success<void>(t("import_contacts_successful"));
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleBackToStep1 = () => {
    setActiveStep(0);
    setCompleteStep([]);
  };

  const handleSearchUnit = async (text: string) => {
    const data = await dispatch(getUnitContact(text));
    if (data) {
      setListUnit(data);
    }
  };

  const handleSearchListContact = async (text: string) => {
    const result = await dispatch(getListMasterForOption({ limit: "*", name: text, type: 2 }));
    if (result) {
      setListContact(result.data);
    }
  };

  const validateFile = (file: File) => {
    let error = "";
    if (file.size / (1024 * 1024) > 10) {
      error = `the_file_must_not_be_greater_than_10MB`;
    } else if (
      file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "application/vnd.ms-excel"
    ) {
      error = `the_file_must_be_a_file_of_type_xlsx_xls`;
    }
    return error;
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      const fileInput = e.target.files[0];
      setFile(fileInput);
      e.target.value = "";
      const err = validateFile(fileInput);
      setError(err);
    }
  };

  const handleReImportErr = async () => {
    if (error) {
      return;
    }
    if (!file) {
      setError("this_field_is_required");
    } else {
      setLoading(true);
      let result: LooseObject = [];
      file &&
        (await readXlsxFile(file).then((rows) => {
          result = rows;
        }));
      if (result.length < 2) {
        setError("the_file_has_no_data");
        setLoading(false);
        return;
      } else {
        let module: IModuleField[] = [];
        for (let index = 0; index < result[0].length; index++) {
          module.push({
            id: index,
            label: result[0][index],
            row: result[1][index],
          });
        }
        module.push({
          id: -1,
          label: t("not_selected"),
          row: "",
        });
        setModuleFile(module);
        HandleNextStep2();
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Import Master Contact</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <div className={classes.wrapHeader}>
          <div className={classes.titleHeader}>{t("masters_import")}</div>
          <div className={classes.wrapSteper}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label: LooseObject, index: number) => (
                <Step key={label.name} completed={completeStep.includes(index)}>
                  <StepLabel>{t(label.name as any)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        {activeStep === 0 && (
          <ImportStep1
            HandleNextStep2={HandleNextStep2}
            file={file}
            setFile={setFile}
            setModuleFile={setModuleFile}
            setLoading={setLoading}
            linkTemplete={`${process.env.REACT_APP_URL_SOCKET}/template/master_contact_template.xlsx`}
          />
        )}
        {activeStep === 1 && moduleField !== null && (
          <ImportStep2
            handleNextStep3={handleImportContact}
            moduleField={moduleField}
            handleBackToStep1={handleBackToStep1}
            handleSearchUnit={handleSearchUnit}
            handleSearchListContact={handleSearchListContact}
            listUnit={listUnit}
            listContact={listContact}
            fieldCreate={MODULE_FIELD_MASTER}
          />
        )}
        {activeStep === 2 && (
          <div className={classes.containerStep}>
            <div className={classes.labelStep}>{`${t("step")} 3: ${t("wait_for_import_result")}`}</div>
            <hr className={classes.hr} />
            <div className={classes.explainStep}>
              {t("the_import_is_in_progress_we_will_notify_when_it_has_been_completed_in_notification_panel")}
            </div>
            <BaseButton title={t("close")} className={classes.btnBack} onClick={() => navigate("/masterContact")} />
          </div>
        )}

        {activeStep === -1 && (
          <div className={classes.containerStep}>
            <div className={classes.labelStep}>
              {`${t("import_result_for")} `}
              <span className={classes.nameFile}>{notify?.file_name}</span>
            </div>
            <hr className={classes.hr} />
            <div className={classes.resultImport}>{`${t("completed")}: ${notify?.success} ${t("rows")}`}</div>
            <div className={classes.resultImport}>
              {`${t("uncompleted")}: `}
              <span className={classes.errResult}>{`${notify?.error} ${t("rows")}`}</span>
            </div>
            <LinkButton
              title={t("download_error_records")}
              className={classes.btnDownloadError}
              link={notify?.error === 0 ? "" : notify?.path}
              startIcon={<DownloadIcon />}
            />
            <div className={classes.explainStep4}>
              {t("download_and_correct_an_error_record_and_then_you_can_reupload_uncompleted_record_only")}
            </div>
            <div className={classes.wrapbtnInput}>
              <BaseButton
                title={t("upload_error_records_only")}
                className={classes.btnUploadError}
                onClick={() => {
                  if (error) {
                    setError("");
                  }
                  inputRef.current && inputRef.current.click();
                }}
                startIcon={<UploadIcon />}
              />
              <input
                className={classes.inputFile}
                type="file"
                accept=".xlsx, .xls"
                ref={inputRef}
                onChange={handleFileSelected}
              />
              {file && (
                <div>
                  {file?.name}{" "}
                  <IconButton
                    className={classes.iconDelete}
                    onClick={() => {
                      if (error) {
                        setError("");
                      }
                      setFile(null);
                    }}
                  >
                    {" "}
                    <CancelIcon />
                  </IconButton>
                </div>
              )}
            </div>
            {error && <div className={classes.error}>{t(error as any)}</div>}
            <div className={classes.wrapBtnFooter}>
              <BaseButton title={t("close")} className={classes.btnBack} onClick={() => navigate("/masterContact")} />
              <BaseButton title={t("import")} className={classes.btnImport} onClick={handleReImportErr} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
