import { Box } from "@mui/material";
import BaseChart from "components/Charts";
import React from "react";
import { useTranslation } from "react-i18next";
import colors, { colorsPaletteChart } from "utils/colors";
import { optionsPieChart } from "utils/optionsChart";

const StudentStatisticChartAccordingToAspirations = () => {
  const { t } = useTranslation();

  const labels = [
    t("number_of_aspirations", { amount: 1 }),
    t("number_of_aspirations", { amount: 2 }),
    t("number_of_aspirations", { amount: 3 }),
  ];

  const data = [
    {
      data: [5, 35, 60],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];

  return (
    <Box>
      <BaseChart
        type={"pie"}
        datasets={data}
        labels={labels}
        options={optionsPieChart}
      />
    </Box>
  );
};

export default StudentStatisticChartAccordingToAspirations;
