import { yupResolver } from "@hookform/resolvers/yup";
import BaseButton from "components/Buttons/BaseButton";
import CardCollapse from "components/CardCollapse";
import ModalChangeStatusFail from "components/Modal/ModalChangeStatusFail";
import ModalConfirm from "components/Modal/ModalConfirm";
import PagingDetail from "components/PagingDetail";
import TabBar from "components/TabBar";
import InputPhoneNumber from "components/inputPhoneNumber";
import _, { isEmpty } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserInfo, hasPermissions } from "redux/auth/auth.service";
import {
  editFieldStudentContact,
  editStudentContact,
  getStudentContact,
  setSelectedTab,
} from "store/detailStudentContactSlice";
import {
  getOptionsPicForStudent,
  getOptionsStudentContact,
  initialOptions,
  resetfieldState,
  selectedTabStudentContactSelector,
} from "store/studentContactSlice";
import * as Yup from "yup";
import { useContextIsFilterStudent } from "..";
import Loading from "../../../../../../components/Loading/Loading";
import Select from "../../../../../../components/Select";
import InputDate from "../../../../../../components/inputDate";
import { LooseObject, optionsStudentContact } from "../../../../../../models/common";
import { RootState, useAppDispatch } from "../../../../../../redux/store";
import { getCities, getDistrict, getWard } from "../../../../../../store/locationSlice";
import {
  ID_SOURCE_NHTS,
  IELTS_MARKS,
  INIT_COUNTRY_ISO_VN_DEFAULT,
  JAPANESE_LANGUAGE_CERTIFICATE,
  STUDENT_CONTACT_DETAIL_FIELD,
  STUDENT_CONTACT_FIELD_MAP_FILTER,
} from "../../../../../../utils/constant";
import { regexDecimalNumber, regexNumber, removeCharsFromStart } from "../../../../../../utils/helper";
import { useStyles } from "./styles";
import InputEmail from "components/InputEmail";
import InputDisable from "components/InputDisable";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { actionButtonSelector, stateActionSupport } from "store/actionSupportSlice";

export default function ProfileStudentContact() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const currentContact = useSelector((state: RootState) => state.detailStudentContact.contact);
  const stateActionSupport: stateActionSupport = useSelector(actionButtonSelector);
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const allOptions = useSelector((state: RootState) => state.studentcontacts.options);
  const [options, setOptions] = useState<optionsStudentContact>(initialOptions);
  const isHasOption = useSelector((state: RootState) => state.studentcontacts.isHasOption);

  const [contact, setContact] = useState<LooseObject>({});
  const errorStore = useSelector((state: RootState) => state.detailStudentContact.error);
  const initialized = useRef(false);
  const [isOpenModalConfirmBack, setIsOpenModalConfirmBack] = useState<boolean>(false);
  const [isOpenModalConfirmPrevious, setIsOpenModalConfirmPrevious] = useState<boolean>(false);
  const [isOpenModalConfirmNext, setIsOpenModalConfirmNext] = useState<boolean>(false);
  const [isOpenModalChangeContactToFail, setIsOpenModalChangeContactToFail] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<LooseObject>({});
  const selectedTab = useSelector(selectedTabStudentContactSelector);
  const [fieldUpdate, setFieldUpdate] = useState<LooseObject[]>(STUDENT_CONTACT_DETAIL_FIELD);
  const [hideOptionAspiration, setHideOptionAspiration] = useState<number[]>([]);
  const [isFilter, setIsFilter] = useContextIsFilterStudent();
  const searchOption = useSelector((state: RootState) => state.studentcontacts.searchOption);
  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .trim()
      .required(t("this_field_is_required"))
      .max(255, t("this_field_must_not_exceed_255_characters")),
    email: Yup.string()
      .trim()
      .email(t("email_is_invalid"))
      .max(255, t("this_field_must_not_exceed_255_characters"))
      .nullable(),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, t("this_field_must_be_a_number"))
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    country_iso: Yup.string().trim().nullable(),
    country_id: Yup.number()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    city_id: Yup.number()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    district_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    ward_id: Yup.number()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    citizen_id: Yup.string()
      .matches(regexNumber, t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    address: Yup.string().max(255, t("this_field_must_not_exceed_255_characters")),
    note: Yup.string().trim(),
    competency_evaluation_score: Yup.number()
      .positive(t("value_must_be_positive"))
      .nullable()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .typeError(t("this_field_must_be_a_number"))
      .test("is-decimal", t("this_field_has_only_two_decimal_values"), (value) => {
        if (!value) return true;
        return regexDecimalNumber.test(value.toString());
      }),
    total_graduation_score: Yup.number()
      .positive(t("value_must_be_positive"))
      .nullable()
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .typeError(t("this_field_must_be_a_number"))
      .test("is-decimal", t("this_field_has_only_two_decimal_values"), (value) => {
        if (!value) return true;
        return regexDecimalNumber.test(value.toString());
      }),
    high_school: Yup.string().trim().max(255, t("this_field_must_not_exceed_255_characters")),
    english_language_certificate: Yup.object().shape({
      type: Yup.string().trim().nullable(),
      value: Yup.string()
        .ensure()
        .when("type", {
          is: (val: any) => val,
          then: (schema: Yup.StringSchema) => schema.trim().required(t("this_field_is_required")),
          otherwise: (schema: Yup.StringSchema) => schema.nullable(),
        }),
    }),
    japan_language_certificate: Yup.string().trim().max(255, t("this_field_must_not_exceed_255_characters")).nullable(),
    subject_combination_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    contact_source_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    source_note: Yup.string().trim().nullable(),
    call_status_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    reject_reason_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    user_id: Yup.number()
      .typeError(t("this_field_must_be_a_number"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    date_of_acceptance: Yup.date()
      .typeError(t("date_is_invalid"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    date_of_birth: Yup.date()
      .typeError(t("date_is_invalid"))
      .transform((cv, ov) => {
        return ov === "" ? undefined : cv;
      })
      .nullable(),
    contact_state_id: Yup.number().typeError(t("this_field_must_be_a_number")).nullable(),
    aspiration_registered: Yup.array()
      .of(
        Yup.object({
          aspiration_id: Yup.number().typeError("This field must be a number").nullable(),
          contact_status_id: Yup.number().typeError("This field must be a number").nullable(),
          note: Yup.string().trim().nullable(),
        }).test("eitherFieldRequired", `${t("please_select_the_aspiration")}`, function (value) {
          return (
            (!value?.aspiration_id && !value?.contact_status_id && !value?.note) ||
            Number.isInteger(value?.aspiration_id)
          );
        })
      )
      .nullable(),
    contact_source_note: Yup.string().trim().nullable(),
    other_certificate: Yup.string().trim().nullable(),
  });
  type StudentContactSubmitForm = Yup.InferType<typeof validationSchema>;
  type KeyError = keyof StudentContactSubmitForm;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setError,
    setValue,
    reset,
    getValues,
    watch,
  } = useForm<StudentContactSubmitForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const contactSource = watch("contact_source_id");
  const typeEnglishLanguageCertificate = watch("english_language_certificate.type");
  const phoneNumber = watch("phone_number");
  const emailContact = watch("email");

  const updateHideAspiration = (aspirations?: LooseObject[]) => {
    const aspirationValue = getValues("aspiration_registered");
    const hideValue: number[] = [];
    if (Array.isArray(aspirations)) {
      aspirations.forEach((item: LooseObject) => {
        if (Number.isInteger(item?.aspiration_id)) {
          hideValue.push(item?.aspiration_id);
        }
      });
    } else if (Array.isArray(aspirationValue)) {
      aspirationValue.forEach((item: LooseObject) => {
        if (Number.isInteger(item?.aspiration_id)) {
          hideValue.push(item?.aspiration_id);
        }
      });
    }
    setHideOptionAspiration(hideValue);
  };

  const initializedField = useRef(false);
  useEffect(() => {
    if (!initializedField.current && Array.isArray(options?.aspiration) && options?.aspiration.length !== 0) {
      initializedField.current = true;
      const newField = [...JSON.parse(JSON.stringify(fieldUpdate))];
      const count = options?.aspiration.length;
      for (let index = count; index > 0; index--) {
        newField[3].leftOption.unshift({
          title: "aspiration",
          name: "aspiration",
          type: "select",
          option: "aspiration",
          required: false,
          index: index,
        });
      }
      setFieldUpdate(newField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const getDataOption = async () => {
    if (isHasOption) {
      setOptions({ ...allOptions, city: [], district: [] });
      dispatch(getOptionsPicForStudent());
    } else {
      setLoading(true);
      const response = await dispatch(getOptionsStudentContact());
      if (response.meta.requestStatus === "rejected") {
        toast.error<void>(t("system_error,_please_try_again_later"));
        navigate("/studentContact");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.isFilter) {
      setIsFilter(state?.isFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
      dispatch(setSelectedTab(""));
      const firstFormGroup = document.querySelectorAll("#root")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(errors).includes("phone_number") || Object.keys(errors).includes("country_iso")) {
      const firstFormGroup = document.querySelectorAll(".phone_number")[0];
      firstFormGroup.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errors]);

  const getDataLocale = async () => {
    const data: LooseObject = {};
    if (contact?.country_id) {
      const cities = await dispatch(getCities([contact.country_id]));
      if (cities && Array.isArray(cities)) {
        data.city = cities;
      }
    } else {
      data.city = [];
      data.district = [];
      data.ward = [];
    }
    if (contact?.city_id) {
      const district = await dispatch(getDistrict([contact.city_id]));
      if (district && Array.isArray(district)) {
        data.district = district;
      }
    } else {
      data.district = [];
      data.ward = [];
    }
    if (contact?.district_id) {
      const ward = await dispatch(getWard([contact.district_id]));
      if (ward && Array.isArray(ward)) {
        data.ward = ward;
      }
    } else {
      data.ward = [];
    }
    if (Object.keys(data).length !== 0) {
      setOptions({ ...allOptions, ...data });
    }
  };

  useEffect(() => {
    if (Object.keys(contact).length !== 0) {
      getDataLocale();
    }

    if (contact?.id) {
      let data: LooseObject = {};
      Object.entries(contact).forEach(([key, value]) => {
        if (key === "aspiration_registered") {
          setValue("aspiration_registered", (value as StudentContactSubmitForm["aspiration_registered"]) ?? null);
          updateHideAspiration(value);
          data[key] = value;
        } else if (Object.keys(validationSchema.fields).includes(key) && value) {
          setValue(String(key) as KeyError, (value as string | number | Date | undefined) ?? "");
          data[key] = value;
        }
      });
      setInitialValue(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  useEffect(() => {
    if (allOptions) {
      setOptions((preOption) => ({
        ...preOption,
        ...allOptions,
        city: options.city,
        district: options.district,
        ward: options.ward,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions]);

  useEffect(() => {
    if (currentContact?.id) {
      reset();
      const newContact = { ...currentContact };
      Object.entries(currentContact).map(([key, value]) => {
        if (value && typeof value == "object" && "id" in value) {
          newContact[key] = value?.id;
        } else {
          newContact[key] = value;
        }
        return null;
      });
      if (newContact?.country_iso) {
        let phone = removeCharsFromStart(
          String(Number(newContact?.phone_number)),
          String(newContact?.country_iso?.phone_code)
        );
        newContact.country_iso = newContact?.country_iso?.iso;
        newContact.phone_number = phone;
      }
      if (!newContact?.country_iso) {
        setValue("country_iso", INIT_COUNTRY_ISO_VN_DEFAULT);
      }
      setContact(newContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContact]);

  useEffect(() => {
    if (errorStore?.length !== 0) {
      errorStore?.forEach((item: any) => {
        setError(item.key, {
          type: "manual",
          message: item.message.join(","),
        });
      });
    }
  }, [errorStore, setError]);

  const checkIfChangeFieldFilter = (data: LooseObject) => {
    let isChange: Boolean = false;
    for (const item of STUDENT_CONTACT_FIELD_MAP_FILTER) {
      switch (item?.type) {
        case "select":
          if (searchOption[item?.filter] && data[item.name] !== searchOption[item?.filter]?.id) {
            isChange = true;
            break;
          }
          break;
        case "text":
          if (
            searchOption[item?.filter] &&
            data[item.name] &&
            !String(data[item.name]).toLowerCase().includes(String(searchOption[item?.filter]).toLowerCase())
          ) {
            isChange = true;
            break;
          }
          break;
        case "array":
          if (
            searchOption[item?.filter].length > 0 &&
            !searchOption[item?.filter].some((element: LooseObject) => element?.id === data[item.name])
          ) {
            isChange = true;
            break;
          }
          break;
        default:
          if (searchOption[item?.filter] !== "") {
            let isExistText: Boolean = true;
            for (const element of STUDENT_CONTACT_FIELD_MAP_FILTER) {
              if (
                element?.type === "text" &&
                data[element.name] &&
                String(data[element.name]).toLowerCase().includes(String(searchOption[item?.filter]).toLowerCase())
              ) {
                isExistText = false;
                break;
              }
            }
            isChange = isExistText;
          }
          break;
      }
      if (isChange) break;
    }
    return isChange;
  };

  const handleEditStudent = async (data: StudentContactSubmitForm) => {
    setLoading(true);
    const values: LooseObject = getValues();
    let form: LooseObject = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        form[key] = value;
      }
    });

    const isEqual = _.isEqual(initialValue, form);

    if (isEqual) {
      toast.warning<void>(t("nothing_change"));
    } else {
      try {
        const aspiration: LooseObject[] = [];
        data.aspiration_registered?.forEach((item: LooseObject) => {
          if (Number.isInteger(item?.aspiration_id)) {
            aspiration.push(item);
          }
        });
        let contact: LooseObject = _.cloneDeep(data);
        contact = {
          ...contact,
          date_of_acceptance: data.date_of_acceptance && moment(data.date_of_acceptance).format("YYYY/MM/DD"),
          date_of_birth: data.date_of_birth && moment(data.date_of_birth).format("YYYY/MM/DD"),
          aspiration_registered: [...aspiration],
        };
        if (contact?.english_language_certificate?.type === "IELTS") {
          contact = {
            ...contact,
            english_language_certificate: {
              type: "IELTS",
              value: contact?.english_language_certificate?.value ? +contact?.english_language_certificate?.value : "",
            },
          };
        }
        const result = await dispatch(editStudentContact({ id: id, value: contact }));
        const isChangeFilter = checkIfChangeFieldFilter(values);
        if (result.meta.requestStatus === "fulfilled") {
          toast.success<void>(t("edit_student_contact_successful"));
          if (isFilter) {
            const userInfor = await dispatch(getUserInfo());
            if (
              (userInfor?.user?.id !== contact?.user_id && selectedTab === 1) ||
              currentContact?.reject_reason_id?.id !== contact?.reject_reason_id ||
              isChangeFilter
            ) {
              dispatch(resetfieldState());
              dispatch(getStudentContact({ id: id }));
              setIsFilter(null);
            } else {
              dispatch(getStudentContact({ id: id, isFilter: Boolean(isFilter) }));
            }
          } else {
            dispatch(getStudentContact({ id: id, isFilter: Boolean(isFilter) }));
          }
          const firstFormGroup = document.querySelectorAll("#root")[0];
          firstFormGroup.scrollIntoView({ behavior: "smooth" });
        } else {
          toast.error<void>(t("system_error,_please_try_again_later"));
          const firstFormGroup = document.querySelectorAll(".container")[0];
          firstFormGroup.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleChangeSelect = async (name: string, data: any, reason: string) => {
    if (name.includes("aspiration_id")) {
      updateHideAspiration();
    }
    if (name === "contact_source_id" && data !== ID_SOURCE_NHTS) {
      setValue("source_note", "");
    }
    const dataOption: LooseObject = {};
    if (reason === "clear") {
      if (name === "country_id") {
        dataOption.city = [];
        dataOption.district = [];
        dataOption.ward = [];
        resetField("city_id");
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "city_id") {
        dataOption.district = [];
        dataOption.ward = [];
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "district_id") {
        dataOption.ward = [];
        resetField("ward_id");
      }
    } else {
      if (name === "country_id") {
        const cities = await dispatch(getCities([data.id]));
        if (cities && Array.isArray(cities)) {
          dataOption.city = cities;
        }
        resetField("city_id");
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "city_id") {
        const district = await dispatch(getDistrict([data.id]));
        if (district && Array.isArray(district)) {
          dataOption.district = district;
        }
        resetField("district_id");
        resetField("ward_id");
      }
      if (name === "district_id") {
        const ward = await dispatch(getWard([data.id]));
        if (ward && Array.isArray(ward)) {
          dataOption.ward = ward;
        }
        resetField("ward_id");
      }
    }
    if (Object.keys(dataOption).length !== 0) {
      setOptions({ ...options, ...dataOption });
    }
  };

  const getDataForm = () => {
    const form: LooseObject = getValues();
    let data: LooseObject = {};
    Object.entries(form).forEach(([key, value]) => {
      if (key === "aspiration_registered") {
        if (Array.isArray(value)) {
          const arr: LooseObject[] = value.filter((item) => item?.aspiration_id || item?.contact_status_id);
          data[key] = arr;
        }
      } else if (key === "english_language_certificate") {
        if (value?.type !== "") {
          data[key] = value;
        }
      } else if (value) {
        data[key] = value;
      }
    });
    return data;
  };

  const handleClickBack = () => {
    const data: LooseObject = getDataForm();
    const isEqual = _.isEqual(initialValue, data);
    if (isEqual) {
      navigate("/studentContact");
    } else {
      setIsOpenModalConfirmBack(true);
    }
  };

  const handleClickPrevious = () => {
    const data: LooseObject = getDataForm();
    const isEqual = _.isEqual(initialValue, data);
    if (isEqual) {
      navigate(`/studentContact/${contact?.prev_id}`, { state: { isFilter: isFilter } });
    } else {
      setIsOpenModalConfirmPrevious(true);
    }
  };

  const handleClickNext = () => {
    const data: LooseObject = getDataForm();
    const isEqual = _.isEqual(initialValue, data);
    if (isEqual) {
      navigate(`/studentContact/${contact?.next_id}`, { state: { isFilter: isFilter } });
    } else {
      setIsOpenModalConfirmNext(true);
    }
  };

  const handleClickChangeState = (newState: LooseObject) => {
    if (currentContact?.reject_reason_id?.id) {
      return;
    }
    handleChangeState(newState);
  };

  const handleChangeState = async (newState: LooseObject) => {
    setLoading(true);
    try {
      const result = await dispatch(editFieldStudentContact({ id: id, value: { contact_state_id: newState?.id } }));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success<void>(t("edit_student_contact_successful"));
        if (isFilter) {
          const userInfor = await dispatch(getUserInfo());
          const isChangeFilter: Boolean =
            searchOption["states"].length > 0 &&
            !searchOption["states"].some((element: LooseObject) => element?.id === newState?.id)
              ? true
              : false;
          if (
            (userInfor?.user?.id !== contact?.user_id && selectedTab === 1) ||
            currentContact?.reject_reason_id?.id !== contact?.reject_reason_id ||
            isChangeFilter
          ) {
            dispatch(resetfieldState());
            dispatch(getStudentContact({ id: id }));
            setIsFilter(null);
          } else {
            dispatch(getStudentContact({ id: id, isFilter: Boolean(isFilter) }));
          }
        } else {
          dispatch(getStudentContact({ id: id, isFilter: Boolean(isFilter) }));
        }
        const firstFormGroup = document.querySelectorAll("#root")[0];
        firstFormGroup.scrollIntoView({ behavior: "smooth" });
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleChangeRejectReason = async (data: LooseObject) => {
    setLoading(true);
    try {
      const result = await dispatch(editFieldStudentContact({ id: id, value: { reject_reason_id: data?.id ?? null } }));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success<void>(t("edit_student_contact_successful"));
        if (isFilter) {
          const userInfor = await dispatch(getUserInfo());
          if (
            (userInfor?.user?.id !== contact?.user_id && selectedTab === 1) ||
            currentContact?.reject_reason_id?.id !== contact?.reject_reason_id
          ) {
            dispatch(resetfieldState());
            dispatch(getStudentContact({ id: id }));
            setIsFilter(null);
          } else {
            dispatch(getStudentContact({ id: id, isFilter: Boolean(isFilter) }));
          }
        } else {
          dispatch(getStudentContact({ id: id, isFilter: Boolean(isFilter) }));
        }
        const firstFormGroup = document.querySelectorAll("#root")[0];
        firstFormGroup.scrollIntoView({ behavior: "smooth" });
        if (data?.id) {
          setIsOpenModalChangeContactToFail(false);
        }
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(handleEditStudent)} noValidate>
        {contact.id && (
          <PagingDetail
            current={contact?.index + 1}
            total={contact?.total}
            onClickBack={handleClickBack}
            onClickPrevious={handleClickPrevious}
            onClickNext={handleClickNext}
            isShowBtnSave={hasPermissions(["student_contact_edit"])}
          />
        )}
        <div className={classes.wrapTopCard}>
          <div className={classes.wrapReason}>
            <BaseButton
              title={t("fail")}
              className={classes.btnFail}
              onClick={() => {
                if (currentContact?.reject_reason_id?.id) {
                  return;
                }
                setIsOpenModalChangeContactToFail(true);
              }}
              isDisabled={currentContact?.reject_reason_id?.id ? true : false}
            />
            {currentContact?.reject_reason_id?.id && (
              <>
                <Select
                  options={options?.rejectReason}
                  className={classes.inputSelectReason}
                  classNamePoper={classes.inputSelectPoper}
                  nameRegister={"reject_reason_id"}
                  control={control}
                  isForm
                  onchange={handleChangeSelect}
                  isFilterSelected={false}
                  disableClearable
                />
                <BaseButton
                  title={t("restore")}
                  className={classes.btnRestore}
                  onClick={() => {
                    handleChangeRejectReason({});
                  }}
                />
              </>
            )}
          </div>
          <div className={classes.wrapState}>
            <TabBar
              selected={getValues("contact_state_id") ?? 0}
              data={options?.state}
              handleClick={handleClickChangeState}
              isDisable={currentContact?.reject_reason_id?.id ? true : false}
            />
          </div>
        </div>
        <div className={classes.containerContent}>
          {fieldUpdate.map((item: LooseObject, index: number) => (
            <div className={classes.containerCard} key={`${index} - a`}>
              {item.title !== "aspiration_field" ? (
                <CardCollapse
                  title={t(item.title)}
                  className={classes.headerCard}
                  childrens={
                    <div className={classes.containerCardContent}>
                      {item?.option.map((field: LooseObject, indexL: number) => (
                        <Fragment key={`${indexL} - b`}>
                          <div
                            className={`${classes.formControl} 
                            ${field.title === "phone_number" ? "phone_number" : ""} 
                            ${field.title === "source_note" && contactSource !== ID_SOURCE_NHTS ? classes.hidden : ""}`}
                          >
                            <div className={classes.label}>
                              {t(field.title)} {field.required && <span className={classes.labelRequired}>*</span>}
                            </div>
                            <div className={classes.wrapValueItem}>
                              {field.type === "email" ? (
                                <InputEmail
                                  nameRegisterEmail={field.name}
                                  control={control}
                                  isVisibleIcon={!isEmpty(emailContact) && stateActionSupport.enableSendEmail}
                                  currentContact={currentContact}
                                />
                              ) : field.title === "phone_number" ? (
                                <InputPhoneNumber
                                  options={options["country"]}
                                  nameRegisterPhone={field.name}
                                  control={control}
                                  nameRegisterIso="country_iso"
                                  initIso={contact?.country_iso ?? INIT_COUNTRY_ISO_VN_DEFAULT}
                                  isDisableClearable
                                  className={classes.inputLogoPhoneNumber}
                                  isVisibleIcon={!isEmpty(phoneNumber)}
                                  isEnableCallLog={stateActionSupport.enableCallLog}
                                  isEnableSendSms={stateActionSupport.enableSendSms}
                                  currentContact={currentContact}
                                  statusCall={contact?.contacted}
                                />
                              ) : field.title === "english_language_certificate" ? (
                                <div className={classes.wrapEnglishlanguage}>
                                  <Controller
                                    control={control}
                                    defaultValue=""
                                    name="english_language_certificate.type"
                                    render={({ field: { ...field } }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        className={classes.wrapRadio}
                                        onClick={(e: any) => {
                                          const englishCertificate: any = getValues(
                                            "english_language_certificate.type"
                                          );
                                          if (englishCertificate === e.target.value) {
                                            setValue("english_language_certificate.type", "");
                                          }
                                          setValue("english_language_certificate.value", "");
                                        }}
                                      >
                                        <FormControlLabel
                                          value={"IELTS"}
                                          control={<Radio className={classes.radio} />}
                                          label={"IELTS"}
                                        />
                                        <FormControlLabel
                                          value={"TOEFL"}
                                          control={<Radio className={classes.radio} />}
                                          label={"TOEFL"}
                                        />
                                      </RadioGroup>
                                    )}
                                  ></Controller>
                                  {String(typeEnglishLanguageCertificate).toUpperCase() === "IELTS" && (
                                    <Select
                                      options={IELTS_MARKS}
                                      classNamePoper={classes.inputSelectPoper}
                                      nameRegister={"english_language_certificate.value"}
                                      control={control}
                                      isForm
                                      onchange={handleChangeSelect}
                                      isFilterSelected={false}
                                      disableClearable
                                    />
                                  )}
                                  {String(typeEnglishLanguageCertificate).toUpperCase() === "TOEFL" && (
                                    <input
                                      className={`${classes.input} ${classes.inputEnglish}`}
                                      {...register("english_language_certificate.value")}
                                      type={"text"}
                                    />
                                  )}
                                </div>
                              ) : field.title === "japanese_language_certificate" ? (
                                <Select
                                  options={JAPANESE_LANGUAGE_CERTIFICATE}
                                  className={classes.inputSelect}
                                  classNamePoper={classes.inputSelectPoper}
                                  nameRegister={field.name}
                                  control={control}
                                  isForm
                                  onchange={handleChangeSelect}
                                  isFilterSelected={false}
                                />
                              ) : field.type === "select" ? (
                                <Select
                                  options={options[field.option as keyof optionsStudentContact]}
                                  className={classes.inputSelect}
                                  classNamePoper={classes.inputSelectPoper}
                                  nameRegister={field.name}
                                  control={control}
                                  isForm
                                  onchange={handleChangeSelect}
                                />
                              ) : field.title === "source_information" ? (
                                <InputDisable value={currentContact?.contact_url_source_id?.source || ""} disabled />
                              ) : field.type === "date" ? (
                                <InputDate
                                  control={control}
                                  nameRegister={field.name}
                                  resetField={resetField}
                                  isPast={field?.isPast}
                                />
                              ) : (
                                <input className={classes.input} {...register(field.name)} type={field.type} />
                              )}
                              {
                                <div className={classes.error}>
                                  {field.title === "english_language_certificate"
                                    ? errors["english_language_certificate"]?.message ??
                                      errors["english_language_certificate"]?.value?.message
                                    : errors[field.name as KeyError]?.message}
                                </div>
                              }
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  }
                />
              ) : (
                <div className={classes.containerAspiration}>
                  <div className={classes.containerLeftAspiration}>
                    {item?.leftOption.map((field: LooseObject, indexL: number) => (
                      <Fragment key={`${indexL} - b`}>
                        <div className={`${classes.formControl}`}>
                          <div className={classes.labelAspiration}>{`${t(field.title)} ${field.index}`}</div>
                          <div className={classes.wrapValueItem}>
                            <div className={classes.wrapAspiration}>
                              <Select
                                options={options?.aspiration ?? []}
                                className={classes.inputSelectAspiration}
                                classNamePoper={classes.inputSelectPoper}
                                nameRegister={`aspiration_registered.${field.index - 1}.aspiration_id`}
                                control={control}
                                isForm
                                onchange={handleChangeSelect}
                                hiddenOptions={hideOptionAspiration}
                                placeholder={`${t("aspiration")} ${field.index}`}
                              />
                              {/*<Select*/}
                              {/*    options={options?.aspiration ?? []}*/}
                              {/*    className={classes.inputSelectAspiration}*/}
                              {/*    classNamePoper={classes.inputSelectPoper}*/}
                              {/*    nameRegister={`aspiration_registered.${field.index - 1}.admission_method_id`}*/}
                              {/*    control={control}*/}
                              {/*    isForm*/}
                              {/*    onchange={handleChangeSelect}*/}
                              {/*    hiddenOptions={hideOptionAspiration}*/}
                              {/*    placeholder={`${t("admission_method")} ${field.index}`}*/}
                              {/*/>*/}
                              <div className={classes.wrapAspirationStatusAndNumber}>
                                <Select
                                  options={options?.status}
                                  className={classes.inputSelectStatus}
                                  classNamePoper={classes.inputSelectPoper}
                                  nameRegister={`aspiration_registered.${field.index - 1}.contact_status_id`}
                                  control={control}
                                  isForm
                                  onchange={handleChangeSelect}
                                  placeholder={t("status")}
                                />
                                <input
                                  className={classes.inputAspirationNumber}
                                  {...register(`aspiration_registered.${field.index - 1}.note`)}
                                  type="text"
                                  placeholder={t("application_number")}
                                />
                              </div>
                            </div>
                            <div className={classes.error}>
                              {Array.isArray(errors["aspiration_registered"]) &&
                                errors["aspiration_registered"][field.index - 1]?.message}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                    <div className={classes.errorAspiration}>{errors["aspiration_registered"]?.message}</div>
                    {/*<BaseButton title={'Add more'} />*/}
                  </div>
                  <div className={classes.containerRightAspiration}>
                    {item?.rightOption.map((field: LooseObject, indexL: number) => (
                      <Fragment key={`${indexL} - b`}>
                        <div className={`${classes.formControlNote}`}>
                          <p className={classes.labelNote}>
                            {t(field.title)}
                            {field.required && <span className={classes.labelRequired}>*</span>}
                          </p>
                          <div className={classes.wrapValueItemNote}>
                            <textarea
                              rows={item?.leftOption.length}
                              className={classes.inputArea}
                              {...register(field.name)}
                            ></textarea>
                            {errors[field.name as KeyError] && (
                              <div className={classes.error}>{errors[field.name as KeyError]?.message}</div>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {contact.id && (
          <PagingDetail
            current={contact?.index + 1}
            total={contact?.total}
            onClickBack={handleClickBack}
            onClickPrevious={handleClickPrevious}
            onClickNext={handleClickNext}
            isShowBtnSave={hasPermissions(["student_contact_edit"])}
          />
        )}
      </form>
      {isOpenModalConfirmBack && (
        <ModalConfirm
          isOpen={isOpenModalConfirmBack}
          onClose={() => setIsOpenModalConfirmBack(false)}
          title={t("confirmation")}
          headerContent={t("leave_this_form?")}
          content={t("changes_you_made_may_not_be_saved.")}
          onConfirm={() => navigate("/studentContact")}
        />
      )}
      {isOpenModalConfirmPrevious && (
        <ModalConfirm
          isOpen={isOpenModalConfirmPrevious}
          onClose={() => setIsOpenModalConfirmPrevious(false)}
          title={t("confirmation")}
          headerContent={t("leave_this_form?")}
          content={t("changes_you_made_may_not_be_saved.")}
          onConfirm={() => {
            setIsOpenModalConfirmPrevious(false);
            navigate(`/studentContact/${contact?.prev_id}`, { state: { isFilter: isFilter } });
          }}
        />
      )}
      {isOpenModalConfirmNext && (
        <ModalConfirm
          isOpen={isOpenModalConfirmNext}
          onClose={() => setIsOpenModalConfirmNext(false)}
          title={t("confirmation")}
          headerContent={t("leave_this_form?")}
          content={t("changes_you_made_may_not_be_saved.")}
          onConfirm={() => {
            setIsOpenModalConfirmNext(false);
            navigate(`/studentContact/${contact?.next_id}`, { state: { isFilter: isFilter } });
          }}
        />
      )}
      {isOpenModalChangeContactToFail && (
        <ModalChangeStatusFail
          isOpen={isOpenModalChangeContactToFail}
          onClose={() => {
            setIsOpenModalChangeContactToFail(false);
          }}
          onConfirm={handleChangeRejectReason}
          options={options?.rejectReason}
        />
      )}
    </>
  );
}
