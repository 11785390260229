import { Box, Typography } from "@mui/material";
import BaseChart from "components/Charts";
import React from "react";
import { useStyles } from "./styles";
import { optionsLineChart } from "utils";

interface IProps {
  title: string;
  data: number | string;
}
const labels = ["", "", "", "", "", "", "", "", "", "", "", ""];

const CardInfoReport: React.FC<IProps> = (props) => {
  const { title, data } = props;

  const { classes } = useStyles();

  return (
    <Box className={classes.contentPage}>
      <Box>
        <Typography variant="body1" className={classes.titleText}>
          {title}
        </Typography>
        <Typography variant="h6">{data}</Typography>
      </Box>
      <Box sx={{ maxHeight: "100px" }}>
        <BaseChart
          type={"line"}
          datasets={[
            {
              data: labels.map(() => Math.ceil(Math.random() * 10)),
              fill: true,
              borderColor: "#4FDBA0",
              backgroundColor: "#4FDBA066",
              tension: 0.5,
              pointStyle: false,
            },
          ]}
          labels={labels}
          options={optionsLineChart}
        />
      </Box>
    </Box>
  );
};

export default CardInfoReport;
