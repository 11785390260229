import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    height: "44px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    color: colors.defaultColorText,
    "@media (max-width: 425px)": {
      fontSize: "10px",
    },
  },
  tab: {
    padding: "0 10px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      opacity: "0.8",
      transition: "ease-in 0.2s",
    },
    minHeight: "44px",
    position: "relative",
    textAlign: "center",
    "@media (max-width: 425px)": {
      padding: "0 5px",
    },
  },
  isDisable: {
    opacity: 0.6,
    cursor: "unset",
    "&:hover": {
      opacity: 0.6,
    },
  },
  tabStart: {
    paddingRight: "25px !important",
    borderLeft: "1.5px solid #D5D5D5",
    "&::after": {
      content: '""',
      width: "25px",
      height: "44px",
      clipPath:
        "polygon(calc(100% - 20px) 0, 24px 50%, calc(100% - 20px) 100%, calc(100% - 19px) 100%, 100% 50%, calc(100% - 19px) 0)",
      backgroundColor: "#D5D5D5",
      right: "0px",
      position: "absolute",
      "@media (max-width: 425px)": {
        clipPath:
          "polygon(calc(100% - 13px) 0, 24px 50%, calc(100% - 13px) 100%, calc(100% - 12px) 100%, 100% 50%, calc(100% - 12px) 0)",
      },
    },
    "@media (max-width: 425px)": {
      paddingRight: "15px !important",
    },
  },
  tabStartSelected: {
    color: "#FFFFFF !important",
    fontWeight: 700,
    backgroundColor: "#63727C",
    paddingRight: "25px !important",
    clipPath: "polygon(calc(100% - 18px) 0, 100% 50%, calc(100% - 18px) 100%, 0 100%, 0 50%, 0 0)",
    "@media (max-width: 425px)": {
      paddingRight: "10px !important",
      clipPath: "polygon(calc(100% - 12px) 0, 100% 50%, calc(100% - 12px) 100%, 0 100%, 0 50%, 0 0)",
    },
  },
  tabCenter: {
    paddingRight: "25px !important",
    "&::after": {
      content: '""',
      right: "0px",
      position: "absolute",
      clipPath:
        "polygon(calc(100% - 20px) 0, 24px 50%, calc(100% - 20px) 100%, calc(100% - 19px) 100%, 100% 50%, calc(100% - 19px) 0)",
      backgroundColor: "#D5D5D5",
      width: "25px",
      height: "44px",
      "@media (max-width: 425px)": {
        clipPath:
          "polygon(calc(100% - 13px) 0, 24px 50%, calc(100% - 13px) 100%, calc(100% - 12px) 100%, 100% 50%, calc(100% - 12px) 0)",
      },
    },
    "@media (max-width: 425px)": {
      paddingRight: "15px !important",
    },
  },
  tabCenterSelected: {
    color: "#FFFFFF !important",
    fontWeight: 700,
    backgroundColor: "#63727C",
    paddingRight: "25px !important",
    paddingLeft: "25px !important",
    clipPath: "polygon(calc(100% - 18px) 0, 100% 50%, calc(100% - 18px) 100%, 0 100%, 19px 50%, 0 0)",
    marginLeft: "-19px",
    "@media (max-width: 425px)": {
      paddingRight: "10px !important",
      paddingLeft: "15px !important",
      clipPath: "polygon(calc(100% - 12px) 0, 100% 50%, calc(100% - 12px) 100%, 0 100%, 12px 50%, 0 0)",
      marginLeft: "-13px",
    },
  },
  tabEndSelected: {
    color: "#FFFFFF !important",
    fontWeight: 700,
    backgroundColor: "#63727C",
    clipPath: "polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 19px 50%, 0 0)",
    marginLeft: "-19px",
    paddingLeft: "25px !important",
    "@media (max-width: 425px)": {
      paddingLeft: "15px !important",
      clipPath: "polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 12px 50%, 0 0)",
      marginLeft: "-13px",
    },
  },
}));
