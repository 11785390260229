import { Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

interface ButtonProp {
  title?: string;
  startIcon?: JSX.Element | null;
  link: string;
  className?: string;
  showToolTip?: boolean;
  textToolTip?: string;
  icon?: JSX.Element | null;
}

export default function LinkButton({ title, startIcon, className, link, showToolTip, textToolTip, icon }: ButtonProp) {
  const { classes } = useStyles();
  return (
    <Link to={link} className={classes.container}>
      <Tooltip enterTouchDelay={0} title={showToolTip ? textToolTip : ""}>
        <Button variant="contained" className={`${classes.btn} ${className}`} startIcon={startIcon}>
          {title}
          {icon}
        </Button>
      </Tooltip>
    </Link>
  );
}
