import { Box, Typography } from "@mui/material";
import BaseChart from "components/Charts";
import { useTranslation } from "react-i18next";
import colors, { colorsPaletteChart } from "utils/colors";
import { optionsBarChart } from "utils/optionsChart";

const StudentStatisticChartByTestScore = () => {
  const { t } = useTranslation();

  const labels = ["0 - 10", "20 - 30", "40 - 50", "60 - 70", "80 - 90"];

  const data = [
    {
      data: [1000, 10000, 20000, 5000, 1000],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];
  const data2 = [
    {
      data: [1000, 5000, 10000, 5000, 1000],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "50%" }}>
          <Typography variant="body1" sx={{ mb: "16px" }}>
            {t("DGNL_score")}
          </Typography>
          <Box sx={{ paddingX: "10px" }}>
            <BaseChart
              type={"bar"}
              datasets={data}
              labels={labels}
              options={optionsBarChart}
            />
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Typography variant="body1" sx={{ mb: "16px" }}>
            {t("THPT_score")}
          </Typography>
          <Box sx={{ paddingX: "10px" }}>
            <BaseChart
              type={"bar"}
              datasets={data2}
              labels={labels}
              options={optionsBarChart}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StudentStatisticChartByTestScore;
