import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import DropdownMenu from "../DropdownMenu";
import { useStyles } from "./styles";

export default function Sidebar(props: any) {
  const { isOpen, onClose, pages } = props;
  const { classes } = useStyles();

  return (
    <div>
      <Drawer
        open={Boolean(isOpen)}
        className={classes.containerSidebar}
        onClose={onClose}
        sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
      >
        <Box sx={{ width: "auto" }} role="presentation">
          {pages.map((page: any, index: any) => (
            <DropdownMenu key={index} page={page} onClose={onClose} />
          ))}
        </Box>
      </Drawer>
    </div>
  );
}
