import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PermissionForm from "components/Forms/PermissionForm";
import { useStyles } from "./styles";

interface ModalContacdtPointProp {
  isOpen: boolean;
  onClose: Function;
  onSubmitModal?: () => void;
  data?: any;
  control: any;
  name?: string;
}

export default function ModalShowPermissionsOfRole({
  isOpen,
  onClose,
  control,
  name = "permissions_of_roles",
}: ModalContacdtPointProp) {
  const { classes } = useStyles();
  const handleClose = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: "hidden", height: "auto" } }}
      >
        <DialogContent className={classes.containerContent}>
          <PermissionForm key={name} control={control} name={name} isDisabled={true} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
