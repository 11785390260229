import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IFilter, DefaultCollectionProp } from "models/common";
import { RootState } from "redux/store";
import { axiosClient } from "services/axiosClient";
import { API } from "utils/api";
import { STUDENT_CONTACT_TYPE } from "utils/constant";

interface IContactCategoryWithoutContactIdFilter extends IFilter {
  name?: string | null;
  without_contact_id?: number | null;
}

interface IContactCategoryByContactIdFilter extends IFilter {
  contact_id: number | null;
  name?: string | null;
}

export interface UnitTag {
  id?: number;
  name?: string;
}

export interface IContactCategory {
  id: number;
  name: string;
  source: string;
  created_date: string;
  unit_tags: UnitTag[];
}

export interface IUpdateContactCategory {
  id: number;
  name: string;
  source?: string;
  unit_tags: UnitTag[];
}

interface IContactCategoryList extends DefaultCollectionProp {
  data: IContactCategory[];
  name: string;
}

interface ContactCategoryState {
  filterDataByContactId: IContactCategoryByContactIdFilter;
  filterDataWithoutCurrentContactId: IContactCategoryWithoutContactIdFilter;
  contactCategories: IContactCategoryList;
  contactCategoriesWithoutCurrentContactId: IContactCategoryList[];
  contactCategory: IContactCategory;
  errors?: any;
  type: number;
}

const fetchContactCategories = async (
  paramsData:
    | IContactCategoryByContactIdFilter
    | IContactCategoryWithoutContactIdFilter
) => {
  const url: string = API.restfulContactCategory;
  const response = await axiosClient.get(url, {
    params: { ...paramsData },
  });
  return response?.data?.data;
};

export const getContactCategoryById = createAsyncThunk(
  "contactCategories/getContactCategoryById",
  async (id: number) => {
    const url: string = `${API.restfulContactCategory}/${id}`;
    const response = await axiosClient.get(url);
    return response?.data?.data;
  }
);

export const fetchContactCategoriesByContactId = createAsyncThunk(
  "contactCategories/fetchContactCategoriesByContactId",
  async (_, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const paramsData = {
        ...state.contactCategory.filterDataByContactId,
        type: state.contactCategory.type,
      };
      const data = await fetchContactCategories(paramsData);
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

export const updateContactCategory = createAsyncThunk(
  "contactCategories/updateContactCategory",
  async (
    data: IUpdateContactCategory,
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const paramsData = {
      name: data.name,
      source: data.source,
      unit_tags: data.unit_tags,
      type: state.contactCategory.type,
    };
    const url: string = `${API.restfulContactCategory}/${data.id}`;
    return await axiosClient
      .put(url, paramsData)
      .then((response) => {
        return fulfillWithValue(response?.data?.data);
      })
      .catch((error) => {
        return rejectWithValue(error?.response?.data?.errors);
      });
  }
);

export const fetchContactCategoriesWithoutCurrentContactId = createAsyncThunk(
  "contactCategories/fetchContactCategoriesWithoutCurrentContactId",
  async (_, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const paramsData = {
        ...state.contactCategory.filterDataWithoutCurrentContactId,
        type: state.contactCategory.type,
      };
      const data = await fetchContactCategories(paramsData);
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

const initialState: ContactCategoryState = {
  type: STUDENT_CONTACT_TYPE,
  filterDataByContactId: {
    page: 1,
    limit: 5,
    contact_id: null,
    total: 0,
  },
  filterDataWithoutCurrentContactId: {
    page: 1,
    limit: 5,
    without_contact_id: null,
    total: 0,
  },
  contactCategories: {
    data: [],
    name: "",
    current_page: 1,
    next_page_url: "",
    prev_page_url: "",
    total: 0,
  },
  contactCategoriesWithoutCurrentContactId: [],
  contactCategory: {
    id: 0,
    name: "",
    source: "",
    created_date: "",
    unit_tags: [],
  },
  errors: {},
};

const contactCategorySlice = createSlice({
  name: "contactCategories",
  initialState,
  reducers: {
    setTypeContactCategory: (state, action) => {
      state.type = action.payload;
    },
    resetFilter: (state) => {
      state.filterDataByContactId.page = 1;
    },
    setPage: (state, action) => {
      state.filterDataByContactId.page = action.payload;
    },
    setContactId: (state, action) => {
      state.filterDataByContactId.contact_id = action.payload;
    },
    setLimit: (state, action) => {
      state.filterDataByContactId.limit = action.payload;
    },
    setName: (state, action) => {
      state.filterDataByContactId.name = action.payload;
    },
    setFilterWithoutContactIdName: (state, action) => {
      state.filterDataWithoutCurrentContactId.name = action.payload;
    },
    setFilterWithoutContactIdLimit: (state, action) => {
      state.filterDataWithoutCurrentContactId.limit = action.payload;
    },
    setFilterWithoutContactId: (state, action) => {
      state.filterDataWithoutCurrentContactId.without_contact_id =
        action.payload;
    },
    setFilterWithoutCurrentContactIdPage: (state, action) => {
      state.filterDataWithoutCurrentContactId.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchContactCategoriesByContactId.fulfilled,
      (state, action) => {
        state.contactCategories = action.payload;
        state.filterDataByContactId.page = action.payload?.current_page;
        state.filterDataByContactId.limit = action.payload?.per_page;
        state.filterDataByContactId.total = action.payload?.total;
      }
    );
    builder.addCase(
      fetchContactCategoriesWithoutCurrentContactId.fulfilled,
      (state, action) => {
        state.contactCategoriesWithoutCurrentContactId = action.payload?.data;
        state.filterDataWithoutCurrentContactId.page =
          action.payload?.current_page;
        state.filterDataWithoutCurrentContactId.limit =
          action.payload?.per_page;
        state.filterDataWithoutCurrentContactId.total = action.payload?.total;
      }
    );
    builder.addCase(getContactCategoryById.fulfilled, (state, action) => {
      state.contactCategory = action.payload;
    });
    builder.addCase(updateContactCategory.rejected, (state, action) => {
      state.errors = action.payload;
    });
  },
});
export default contactCategorySlice;
export const contactCategoryListSelector = (state: RootState) =>
  state?.contactCategory?.contactCategories;
export const contactCategoryListWithoutCurrentContactIdSelector = (
  state: RootState
) => state?.contactCategory?.contactCategoriesWithoutCurrentContactId;
export const filterContactCategoryByContactIdSelector = (state: RootState) =>
  state?.contactCategory?.filterDataByContactId;
export const errorContactCategorySelector = (state: RootState) =>
  state?.contactCategory?.errors;
