import { useStyles } from "./styles";

interface InputDisableProps {
  className?: string;
  value?: string;
  disabled?: boolean;
}

export default function InputDisable({
  className,
  value,
  disabled,
}: InputDisableProps) {
  const { classes, } = useStyles();

  return (
    <input
      className={`${classes.inputDisable} ${className}`}
      type="text"
      value={value}
      disabled={disabled}
    />
  );
};