import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { UserAuth } from "../../models/common";
import { axiosClient } from "../../services/axiosClient";
import { API } from "../../utils/api";
import { AppDispatch } from "./../store";
import { removeUserInfo, saveUserInfo } from "./auth.service";

export interface AuthState {
  user: UserAuth | null;
  token: string | null;
  error?: string | null;
}

interface LoginPayLoad {
  email: string;
  password: string;
}

interface User {
  token: string;
  user: UserAuth;
  status: object;
  type?: object;
}

const initialState: AuthState = {
  user: null,
  token: null,
  error: null,
};

const usersSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logoutSuccess: (state) => {
      state.token = null;
      state.user = null;
    },
  },
});

export const { loginSuccess, logoutSuccess } = usersSlice.actions;

export const loginStandard = (user: LoginPayLoad) => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = API.loginStandard;

    const response = await axios.post(url, user, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (response.status === 200) {
      dispatch(loginSuccess(response.data));
      saveUserInfo(response.data.data);
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const logout = () => async (dispatch: AppDispatch, getState: any) => {
  try {
    const url = API.logout;
    const response = await axiosClient.post(url);

    if (response.status === 200) {
      dispatch(logoutSuccess());
      removeUserInfo();
      return true;
    } else {
      removeUserInfo();
      return false;
    }
  } catch (error) {
    removeUserInfo();
    return false;
  }
};

export default usersSlice;
