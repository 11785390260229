import { Avatar, Divider, List, ListItem, ListItemText } from "@mui/material";
import { isEmpty } from "lodash";
import { LooseObject } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  rowData: LooseObject;
}

const ExpandableTableRow: React.FC<IProps> = (props) => {
  const { rowData } = props;

  const { t } = useTranslation();

  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      {rowData &&
        Object.keys(rowData)
          .filter((data) => !isEmpty(rowData[data]) && data !== "image_path")
          .map((dataContact: string, index) => {
            if (typeof rowData[dataContact] === "object" && rowData[dataContact]) {
              return (
                <React.Fragment key={`${rowData.id}-${index}`}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t(dataContact)}
                      secondary={<>{rowData[dataContact]?.source ?? rowData[dataContact]?.name ?? ""}</>}
                    />
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={`${rowData.id}-${index}`}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={t(dataContact)}
                    secondary={
                      dataContact === "avatar" ? (
                        <>
                          <Avatar alt="avatar" src={rowData[dataContact]} />
                        </>
                      ) : (
                        <>{rowData[dataContact]}</>
                      )
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
              </React.Fragment>
            );
          })}
    </List>
  );
};

export default ExpandableTableRow;
