import { useStyles } from "./styles";
import {Controller} from "react-hook-form";

interface InputDisableProps {
    className?: string;
    disabled?: boolean;
    type?: string;
    control?: any;
    nameRegister?: string;
    data?: any;
    hidden?: boolean
}

export default function InputCustom({
     className,
     disabled,
     type,
     control,
     nameRegister,
     data,
     hidden
}: InputDisableProps) {
    const { classes, } = useStyles();
    if (nameRegister && control) {
        return (
            <Controller
                control={control}
                defaultValue={data}
                render={({ field: { value, onChange, } }) => (
                    <input
                        className={`${classes.inputDisable} ${className}`}
                        type={type ? type : 'text'}
                        value={value}
                        disabled={disabled}
                        onChange={(event: any) => {
                            onChange(event?.$d);
                        }}
                        hidden={!!hidden}
                    />
                )}
                name={nameRegister}
            />
        );
    } else {
        return (
            <input
                className={`${classes.inputDisable} ${className}`}
                type={type ? type : 'text'}
                value={data}
                name={nameRegister}
                disabled={disabled}
                hidden={!!hidden}
            />
        );
    }
}