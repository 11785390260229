import { Autocomplete, IconButton, InputAdornment, Popper, TextField } from "@mui/material";
import ModalSendSms from "components/Modal/ModalSendSms";
import { LooseObject, SelectDataProp } from "models/common";
import { useEffect, useState } from "react";
import ReactCountriesFlags from "react-countries-flags";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { setDataCallLog } from "store/callLogSlice";
import { CALL_LOG_TYPE } from "utils";
import { useStyles } from "./styles";

interface InputPhoneNumberProp {
  className?: string;
  classNamePoper?: string;
  data?: string;
  handleChange?: Function;
  nameRegisterPhone?: string;
  nameRegisterIso?: string;
  control?: any;
  options: LooseObject[];
  onchange?: Function;
  initIso?: string;
  isDisableClearable?: Boolean;
  isVisibleIcon?: Boolean;
  currentContact?: LooseObject[];
  typeCallLog?: (typeof CALL_LOG_TYPE)[number]["value"];
  isEnableSendSms?: boolean;
  isEnableCallLog?: boolean;
  statusCall?: number;
}

export default function InputPhoneNumber({
  className,
  data,
  handleChange,
  nameRegisterPhone,
  nameRegisterIso,
  control,
  options,
  onchange,
  initIso,
  classNamePoper,
  isDisableClearable,
  isVisibleIcon,
  currentContact,
  typeCallLog = CALL_LOG_TYPE[0].value,
  isEnableSendSms = true,
  isEnableCallLog = true,
  statusCall,
}: InputPhoneNumberProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<LooseObject>({});

  const [openDialogSendSms, setOpenDialogSendSms] = useState(false);
  const [dataSendSms, setDataSendSms] = useState<SelectDataProp[]>([]);

  const dispatch = useAppDispatch();

  const handleOpenModalCallLog = async (row: LooseObject) => {
    const dataCallLog = {
      isOpenModal: true,
      contactId: row?.id,
      user: {
        full_name: row?.full_name,
        phone_number: row?.phone_number,
      },
      type: typeCallLog,
    };
    dispatch(setDataCallLog(dataCallLog));
  };

  const handleOpenDialogSendSms = (row: LooseObject) => {
    setOpenDialogSendSms(true);
    setDataSendSms([{ id: row?.id, name: row?.phone_number }]);
  };

  const handleCloseDialogSendSms = () => {
    setOpenDialogSendSms(false);
  };

  useEffect(() => {
    if (initIso) {
      options.forEach((item: LooseObject) => {
        if (item?.iso === initIso) {
          setSelectedCountry(item);
          return;
        }
      });
    } else {
      setSelectedCountry({});
    }
  }, [initIso, options, navigate]);

  const handleGetValue = (value: any) => {
    return (
      options.find((option) => {
        return value === option?.iso;
      }) ?? null
    );
  };

  const MyPopper = function (props: any) {
    return <Popper {...props} className={`${classes.poper} ${classNamePoper}`} placement="bottom-start" />;
  };

  return (
    <>
      {control && nameRegisterPhone && nameRegisterIso ? (
        <Controller
          control={control}
          name={nameRegisterPhone}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              className={`${classes.container} ${className}`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={`${classes.inputSelect} logo-phonenumber`}>
                    <Controller
                      control={control}
                      name={nameRegisterIso}
                      render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
                        <Autocomplete
                          PopperComponent={MyPopper}
                          value={handleGetValue(value)}
                          {...field}
                          loadingText={`${t("loading")}...`}
                          onChange={(event, newValue, reason, details) => {
                            setSelectedCountry(newValue);
                            if (String(reason) === "clear") {
                              field.onChange(undefined);
                            } else if (Array.isArray(newValue)) {
                              field.onChange(newValue.map((item: LooseObject) => item?.id));
                            } else {
                              field.onChange("iso" in newValue && newValue?.iso);
                            }
                            if (reason === "removeOption") {
                              onchange && onchange(nameRegisterIso, details, String(reason));
                            } else {
                              onchange && onchange(nameRegisterIso, newValue, String(reason));
                            }
                          }}
                          fullWidth={true}
                          blurOnSelect={true}
                          noOptionsText={t("no_options")}
                          classes={{
                            option: classes.textField,
                            noOptions: classes.noOptions,
                          }}
                          options={options || []}
                          getOptionLabel={(option) => ""}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {selectedCountry?.iso && (
                                      <>
                                        <ReactCountriesFlags isoCode={selectedCountry?.iso} />
                                      </>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              inputRef={ref}
                            />
                          )}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option?.iso && (
                                  <>
                                    <ReactCountriesFlags isoCode={option?.iso} />
                                    <span> &nbsp;</span>
                                  </>
                                )}
                                {`${option?.name} (${option?.phone_code})` ?? ""}
                              </li>
                            );
                          }}
                          disableClearable={isDisableClearable ? true : false}
                        />
                      )}
                    />
                  </InputAdornment>
                ),
                endAdornment: isVisibleIcon ? (
                  <InputAdornment position="end">
                    {isEnableCallLog && (
                      <IconButton onClick={() => handleOpenModalCallLog(currentContact ?? {})}>
                        <span
                          className={`${
                            !statusCall
                              ? classes.noStatusCall
                              : statusCall === 1
                              ? classes.haveNotCall
                              : statusCall === 2
                              ? classes.failedCall
                              : classes.successfulCall
                          } ${classes.iconCall}`}
                        />
                      </IconButton>
                    )}
                    {isEnableSendSms && (
                      <IconButton onClick={() => handleOpenDialogSendSms(currentContact ?? {})}>
                        <span className={`${classes.iconMenu} ${classes.iconSms}`} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ) : null,
              }}
            />
          )}
        />
      ) : (
        <TextField
          className={`${classes.container} ${className}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={`${classes.inputSelect} logo-phonenumber`}>
                <Autocomplete
                  PopperComponent={MyPopper}
                  fullWidth={true}
                  value={handleGetValue(data)}
                  blurOnSelect={true}
                  disableClearable={true}
                  noOptionsText={t("no_options")}
                  classes={{ option: classes.textField, noOptions: classes.noOptions }}
                  options={options ?? []}
                  getOptionLabel={(option) => ""}
                  filterSelectedOptions
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {selectedCountry?.iso && (
                              <>
                                <ReactCountriesFlags isoCode={selectedCountry?.iso} />
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  onChange={(event, value: LooseObject) => {
                    onchange && onchange(value);
                  }}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option?.iso && (
                          <>
                            <ReactCountriesFlags isoCode={option?.iso} />
                            <span>&nbsp;</span>
                          </>
                        )}
                        {`${option?.name} (${option?.phone_code})` ?? ""}
                      </li>
                    );
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: isVisibleIcon ? (
              <InputAdornment position="end">
                {isEnableCallLog && (
                  <IconButton>
                    <span
                      className={`${
                        !statusCall
                          ? classes.noStatusCall
                          : statusCall === 1
                          ? classes.haveNotCall
                          : statusCall === 2
                          ? classes.failedCall
                          : classes.successfulCall
                      } ${classes.iconCall}`}
                    />
                  </IconButton>
                )}
                {isEnableSendSms && (
                  <IconButton>
                    <span className={`${classes.iconMenu} ${classes.iconSms}`} />
                  </IconButton>
                )}
              </InputAdornment>
            ) : null,
          }}
        />
      )}
      {openDialogSendSms && (
        <ModalSendSms isOpen={openDialogSendSms} onClose={handleCloseDialogSendSms} dataSendSms={dataSendSms} />
      )}
    </>
  );
}
