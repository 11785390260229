import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HTMLReactParser from "html-react-parser";
import { useStyles } from "./styles";
import { DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BaseButton from "components/Buttons/BaseButton";

interface ModalNotifyProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  content: string;
  title?: string;
  classNameHeader?: string;
  isShowFooterReminder?: boolean;
}

export default function ModalNotify({
  title,
  isOpen,
  onClose,
  content,
  classNameHeader,
  isShowFooterReminder,
  onConfirm,
}: ModalNotifyProp) {
  const { classes } = useStyles();
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        className={classes.container}
      >
        <div className={`${classes.title} ${classNameHeader}`}>
          <div>{title}</div>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.wrapContent}>
          {HTMLReactParser(content)}
        </DialogContent>
        {isShowFooterReminder && (
          <DialogActions className={classes.footer}>
            <BaseButton title={"Hủy bỏ"} className={classes.btnNo} onClick={() => onClose()} />
            <BaseButton title={"Đồng ý"} className={classes.btnYes} onClick={() => onConfirm()} />
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
