import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import BaseButton from "../../Buttons/BaseButton";
import { useState } from "react";
import Select from "components/Select";
import { LooseObject } from "models/common";

interface ModalChangeStatusFailProp {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  options: LooseObject[];
}

export default function ModalChangeStatusFail({ isOpen, onClose, onConfirm, options }: ModalChangeStatusFailProp) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [error, setError] = useState<string>("");
  const [reason, setReason] = useState<LooseObject>({});
  const handleClickSubmit = () => {
    if (Object.keys(reason).length === 0) {
      setError(t("this_field_is_required"));
      return;
    }
    onConfirm(reason);
  };

  const handleChange = (data: any) => {
    if (!data) {
      setReason({});
    } else {
      setReason(data);
    }
    setError("");
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        scroll={"paper"}
      >
        <div className={classes.title}>
          <p>{t("confirmation")}</p>
          <IconButton aria-label="close" onClick={() => onClose()} className={classes.btnClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.containerContent}>
          <div className={classes.formControl}>
            <p className={`${classes.label}`}>
              {`${t("reject_reason")}: `}
              <span>*</span>
            </p>
            <Select
              data={reason}
              options={options}
              className={classes.inputSelect}
              onchange={(data: any) => handleChange(data)}
              disablePortal={false}
              isFilterSelected={false}
              isObjectSubmit
            />
          </div>
          {error && <div className={classes.error}>{error}</div>}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("no")} className={classes.btnNo} onClick={() => onClose()} />
          <BaseButton title={t("yes")} className={classes.btnYes} onClick={() => handleClickSubmit()} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
