import { makeStyles } from "tss-react/mui";
import colors from "../../../../../utils/colors";

export const useStyles = makeStyles()(() => ({
  btnCreate: {
    backgroundColor: colors.btnCreate,
    marginRight: "16px",
    "&:hover": {
      backgroundColor: colors.btnCreate,
    },
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  contentPage: {
    backgroundColor: "#FFF",
    margin: "16px",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "16px",
  },
}));
