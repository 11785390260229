import BaseButton from "components/Buttons/BaseButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

interface ButtonCancelFormProps {
  onClick: () => void;
}

export default function ButtonBackForm({ onClick }: ButtonCancelFormProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <BaseButton
      sx={{ width: "93px" }}
      title={t("back")}
      className={classes.btn}
      startIcon={<ArrowBackIosNewRoundedIcon className={classes.backIcon} />}
      onClick={onClick}
    />
  );
}
