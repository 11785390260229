import Comment from "components/Comment";
import LogDetail from "components/DetailLog";
import HeaderCard from "components/HeaderCard";
import Loading from "components/Loading/Loading";
import PagingDetail from "components/PagingDetail";
import { ImageComment, LooseObject } from "models/common";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "redux/store";
import { useContextIsFilterStudent } from "..";
import { useStyles } from "./styles";
import { setSelectedTab } from "store/detailStudentContactSlice";
import { addLog, fetchLogs, resetLogs } from "store/logsSlice";
import { toast } from "react-toastify";

export default function ActivityLogStudent() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const currentContact = useSelector((state: RootState) => state.detailStudentContact.contact);
  const [contact, setContact] = useState<LooseObject>({});
  const { state } = useLocation();
  const [data, setData] = useState<LooseObject[]>([]);
  const [isFilter, setIsFilter] = useContextIsFilterStudent();
  const logsState = useSelector((state: RootState) => state.logs.logs);

  useEffect(() => {
    if (state?.isFilter) {
      setIsFilter(state?.isFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    dispatch(setSelectedTab("activity_log"));
    return () => {
      dispatch(resetLogs());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await dispatch(fetchLogs(id));
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    setData(logsState);
  }, [logsState]);

  useEffect(() => {
    if (currentContact) {
      setContact(currentContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContact]);

  const handleSubmitLog = async (data: any, listImage: ImageComment[]) => {
    setLoading(true);
    const result = await dispatch(addLog(id, data, listImage));
    if (result) {
      toast.success<void>(t("add_comment_successful"));
      fetchData();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
    return result;
  };

  return (
    <>
      {loading && <Loading />}
      <PagingDetail
        current={contact?.index + 1}
        total={contact?.total}
        onClickBack={() => navigate("/studentContact")}
        onClickPrevious={() =>
          navigate(`/studentContact/${contact?.prev_id}/activityLog`, { state: { isFilter: isFilter } })
        }
        onClickNext={() =>
          navigate(`/studentContact/${contact?.next_id}/activityLog`, { state: { isFilter: isFilter } })
        }
      />
      <div className={classes.containerContent}>
        <div className={classes.wrapContent}>
          <HeaderCard title={t("activity_log")} />
          <div className={classes.wrapContentLog}>
            <div className={classes.contentLog}>
              {data.length !== 0 ? (
                <>
                  {data.map((item: LooseObject) => (
                    <Fragment key={`${item.id} - b`}>
                      <LogDetail log={item} />
                    </Fragment>
                  ))}
                </>
              ) : (
                <div className={classes.wrapNodata}>
                  <div className={classes.noData}>{t("no_data_found")}</div>
                </div>
              )}
            </div>
          </div>
          <Comment
            onClose={() => {}}
            onsubmit={handleSubmitLog}
            className={classes.addLog}
            isLog={true}
            placeholder={t("enter_activity")}
          />
        </div>
      </div>
      <PagingDetail
        current={contact?.index + 1}
        total={contact?.total}
        onClickBack={() => navigate("/studentContact")}
        onClickPrevious={() =>
          navigate(`/studentContact/${contact?.prev_id}/activityLog`, { state: { isFilter: isFilter } })
        }
        onClickNext={() =>
          navigate(`/studentContact/${contact?.next_id}/activityLog`, { state: { isFilter: isFilter } })
        }
      />
    </>
  );
}
