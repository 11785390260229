import { makeStyles } from "tss-react/mui";
import colors from "../../../../../utils/colors";
import { Theme } from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      margin: "8px",
      padding: "8px",
    },
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  headerCard: {
    backgroundColor: "#63727C !important",
    borderRadius: "4px 4px 0 0 !important",
  },
  arrowDown: {
    color: "#FFF",
    fontSize: "30px",
  },
  formControl: {
    display: "flex",
    width: "100%",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "200px",
    paddingTop: "6px",
  },
  input: {
    width: "100%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  inputArea: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "4px",
    padding: "5px 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
    resize: "none",
  },
  inputSelect: {
    width: "100%",
  },
  inputSelectPoper: {
    zIndex: 1000,
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    minHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "10px",
    },
  },
  containerCard: {
    marginBottom: "16px",
  },
  containerCardContent: {
    columnCount: 3,
    columnGap: "32px",
    backgroundColor: "#FAFAFA",
    padding: "16px",
    overflowY: "auto",
    [theme.breakpoints.down("xl")]: {
      columnCount: 2,
    },
    [theme.breakpoints.down("md")]: {
      columnCount: 1,
    },
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  btnCancel: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "16px",
    height: "42px",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
  btnCreate: {
    backgroundColor: "#6A7A83",
    marginLeft: "16px",
    height: "42px",
    "&:hover": {
      backgroundColor: "#6A7A83",
    },
  },
  labelRequired: {
    color: "#FF0000",
  },
  wrapValueItem: {
    width: "100%",
    breakInside: "avoid",
  },
  wrapAspiration: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  wrapAspirationStatusAndNumber: {
    width: "45%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inputSelectAspiration: {
    width: "55%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px 0 0 5px !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px !important",
      },
    },
  },
  inputSelectStatus: {
    width: "70%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px 0 0 5px !important",
      },
    },
  },
  inputAspirationNumber: {
    width: "30%",
    height: "30px",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DEDEDE",
    borderRadius: "0 5px 5px 0",
    padding: "0 8px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #202020",
    },
  },
  containerAspiration: {
    padding: "0 16px",
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  containerLeftAspiration: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
  },
  containerRightAspiration: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginLeft: "16px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  labelAspiration: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "200px",
    paddingTop: "6px",
    [theme.breakpoints.down("lg")]: {
      minWidth: "120px",
    },
  },
  labelNote: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      minWidth: "120px",
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      marginRight: "10px",
    },
  },
  formControlNote: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    height: "100%",
    paddingLeft: "16px",
    boxSizing: "border-box",
    marginBottom: "19px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      paddingLeft: "0",
      marginTop: "6px",
    },
  },
  wrapValueItemNote: {
    width: "100%",
    height: "100%",
  },
  errorAspiration: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "200px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "120px",
    },
  },
  inputLogoPhoneNumber: {
    "& .logo-phonenumber .MuiOutlinedInput-root": {
      width: "70px !important",
    },
  },
  hidden: {
    display: "none",
  },
  wrapRadio: {
    "& .MuiFormControlLabel-label": {
      fontSize: "14px !important",
    },
  },
  radio: {
    height: "30px",
    color: "#80868B",
    "&.Mui-checked": {
      color: "#4A4358",
    },
  },
  wrapEnglishlanguage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    breakInside: "avoid",
  },
  inputEnglish: {
    width: "50% !important",
  },
}));
