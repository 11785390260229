import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useStyles } from "./styles";

interface InputDateProp {
  className?: string;
  data?: string;
  handleChange?: Function;
  nameRegister?: string;
  control?: any;
  isPast?: boolean;
  isFuture?: boolean;
  resetField?: Function;
  minDate?: any;
  formatDate?: string;
  isNotReadOnly?: boolean;
  isInputDateRegister?: boolean;
}

export default function InputDate({
  className,
  data,
  handleChange,
  nameRegister,
  control,
  isPast,
  resetField,
  minDate,
  isFuture = true,
  formatDate,
  isNotReadOnly,
  isInputDateRegister,
}: InputDateProp) {
  const { classes } = useStyles();
  const [openDate, setOpenDay] = useState<boolean>(false);

  if (isInputDateRegister && nameRegister)
    return (
      <Controller
        control={control}
        name={nameRegister}
        render={({ field: { value, onChange } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              open={openDate}
              onOpen={() => setOpenDay(true)}
              onClose={() => setOpenDay(false)}
              format={formatDate ? formatDate : "YYYY-MM-DD"}
              className={`${classes.container} ${className}`}
              value={value || value ? dayjs(value) : null}
              onChange={(event: any) => {
                onChange(event?.$d);
              }}
              slotProps={{
                field: { clearable: true, onClear: () => resetField && resetField(nameRegister) },
              }}
              disablePast={isPast ? false : true}
              disableFuture={isFuture ? false : true}
              closeOnSelect
              minDate={minDate ? minDate : ""}
            />
          </LocalizationProvider>
        )}
      />
    );

  return control && nameRegister ? (
    <Controller
      control={control}
      name={nameRegister}
      render={({ field: { value, onChange } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            open={openDate}
            onOpen={() => setOpenDay(true)}
            onClose={() => setOpenDay(false)}
            format={formatDate ? formatDate : "YYYY-MM-DD"}
            className={`${classes.container} ${className}`}
            value={value || value ? dayjs(value) : null}
            onChange={(event: any) => {
              onChange(event?.$d);
            }}
            slotProps={{
              textField: {
                InputProps: { placeholder: "", readOnly: isNotReadOnly ? false : true },
                onClick: () => {
                  if (!isNotReadOnly) {
                    setOpenDay(true);
                  }
                },
                onKeyDown: (e) => {
                  if (!isNotReadOnly) {
                    e.preventDefault();
                  }
                },
              },
              field: { clearable: true, onClear: () => resetField && resetField(nameRegister) },
            }}
            disablePast={isPast ? false : true}
            disableFuture={isFuture ? false : true}
            closeOnSelect
            minDate={minDate ? minDate : ""}
          />
        </LocalizationProvider>
      )}
    />
  ) : (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={openDate}
        onOpen={() => setOpenDay(true)}
        onClose={() => setOpenDay(false)}
        format={formatDate ? formatDate : "YYYY-MM-DD"}
        className={`${classes.container} ${className}`}
        value={data ?? ""}
        onChange={(event) => handleChange && handleChange(event)}
        slotProps={{
          textField: {
            InputProps: { placeholder: "", readOnly: isNotReadOnly ? false : true },
            onClick: () => {
              if (!isNotReadOnly) {
                setOpenDay(true);
              }
            },
            onKeyDown: (e) => {
              if (!isNotReadOnly) {
                e.preventDefault();
              }
            },
          },
          field: { clearable: true },
        }}
        disablePast={isPast ? false : true}
        disableFuture={isFuture ? false : true}
        closeOnSelect
      />
    </LocalizationProvider>
  );
}
