import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bottom: 0,
    "& p": {
      color: "#666",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "700",
    },
    "& div": {
      margin: "16px",
      borderRadius: "8px",
      height: "50px",
      backgroundColor: "#E9E9E9",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));
