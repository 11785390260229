import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ImageComment, LooseObject } from "../models/common";
import { axiosClient } from "../services/axiosClient";
import { API } from "../utils/api";
import { AppDispatch } from "redux/store";

export interface loginStandardState {
  logs: LooseObject[];
}

const initialState: loginStandardState = {
  logs: [],
};

const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    resetLogs: (state) => {
      state.logs = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchLogs.fulfilled, (state, action) => {
      state.logs = action.payload.data;
    });
  },
});

export const { resetLogs } = logsSlice.actions;

export const fetchLogs = createAsyncThunk(
  "logs/getLogs",
  async (id: any, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosClient.get(API.contactLog, { params: { limit: "*", contact_id: id } });
      return fulfillWithValue(response.data.data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addLog =
  (id: any, data: any, listImage: ImageComment[]) => async (dispatch: AppDispatch, getState: any) => {
    try {
      const url = API.contactLog + "/manual-log";
      let bodyFormData = new FormData();
      bodyFormData.append("contact_id", id);
      bodyFormData.append("comment", data);
      listImage.forEach((item: any) => {
        bodyFormData.append(`images[${item.name}]`, item.src);
      });
      const response = await axiosClient.post(url, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export default logsSlice;
