import { Box } from "@mui/material";
import BaseChart from "components/Charts";
import { useTranslation } from "react-i18next";
import { colorsPaletteChart } from "utils/colors";

const labels = [
  "mr.1",
  "mr.2",
  "mr.3",
  "mr.4",
  "mrs.5",
  "mr.6",
  "mrs.7",
  "mr.8",
  "mr.9",
];
const option = {
  responsive: true,
  aspectRatio: 6,
  plugins: {},
  scales: {
    x: {
      display: true,
    },
    y: {
      display: true,
      ticks: {
        beginAtZero: true,
      },
    },
  },
};

const StatisticChartToTheStaffInCharge = () => {
  const { t } = useTranslation();

  const data = [
    {
      label: t("total_students_and_masters"),
      data: labels.map(() => Math.ceil(Math.random() * 10) * 1000),
      borderColor: colorsPaletteChart.default[0],
      backgroundColor: colorsPaletteChart.default_opacity[0],
      borderWidth: 2,
      borderSkipped: false,
    },
    {
      label: t("responded"),
      data: labels.map(() => Math.ceil(Math.random() * 10) * 1500),
      borderColor: colorsPaletteChart.default[2],
      backgroundColor: colorsPaletteChart.default_opacity[2],
      borderWidth: 2,
      borderSkipped: false,
    },
    {
      label: t("not_responded"),
      data: labels.map(() => Math.ceil(Math.random() * 10) * 1750),
      borderColor: colorsPaletteChart.default[4],
      backgroundColor: colorsPaletteChart.default_opacity[4],
      borderWidth: 2,
      borderSkipped: false,
    },
  ];

  return (
    <Box>
      <Box>
        <Box sx={{ margin: "16px 0 30px 0" }}>
          <Box sx={{ my: "16px" }}>
            <BaseChart
              type="bar"
              datasets={data}
              labels={labels}
              options={option}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatisticChartToTheStaffInCharge;
