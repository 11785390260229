import Select from "@mui/material/Select/Select";
import { useStyles } from "./styles";
import { Box, MenuItem } from "@mui/material";
import { SelectDataProp } from "models/common";
import ErrorMessageForm from "components/Forms/ErrorMessageForm";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface SelectFormProp {
  title?: any;
  data: SelectDataProp[];
  isRequired?: boolean;
  widthLabel?: number;
  register?: any;
  errors?: any;
  isMultiple?: boolean;
  name?: any;
  defaultValue?: any;
  onChange?: any;
  control?: any;
  fullWidth?: boolean;
}

export default function SelectForm({
  title,
  data,
  isRequired,
  widthLabel,
  errors,
  isMultiple,
  name,
  onChange: onChangeParent,
  control,
  fullWidth,
}: SelectFormProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrap}>
          <Box sx={{ width: `${widthLabel ?? 80}px`, marginRight: "60px" }}>
            <p style={{ width: widthLabel ?? 80 }} className={classes.label}>
              {title} {isRequired && <span>*</span>}
            </p>
          </Box>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                onChange={(e) => {
                  onChange(e.target.value);
                  if (onChangeParent) {
                    onChangeParent();
                  }
                }}
                className={[classes.input, fullWidth ? classes.flexGrow : ""].join(" ")}
                multiple={isMultiple}
                value={value}
                {...field}
              >
                {data.length > 0 ? (
                  data?.map((item: SelectDataProp) => (
                    <MenuItem key={item.value ?? item.id} value={item.value ?? item.id} style={{ fontSize: 14 }}>
                      {t(item.name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem style={{ fontSize: 14 }}>{t("no_options")}</MenuItem>
                )}
              </Select>
            )}
          />
        </div>
        <ErrorMessageForm errors={errors} name={name} widthLabel={widthLabel} />
      </div>
    </>
  );
}
