import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    zIndex: 9001,
  },
  title: {
    backgroundColor: "#FFF",
    color: "#413C51",
    height: "40px",
    fontSize: "18px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "20px",
  },
  btnClose: {
    backgroundColor: "#FFF",
    color: "#4A4358",
  },
  wrapContent: {
    textAlign: "center",
    color: "#413C51",
    fontSize: "14px",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    height: "30px",
  },
  btnYes: {
    backgroundColor: "#AB2523",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#AB2523",
    },
  },
  btnNo: {
    backgroundColor: colors.btnCancel,
    color: colors.defaultColorText,
    marginRight: "10px",
    border: "1px solid rgba(26, 24, 79, 0.10)",
    "&:hover": {
      backgroundColor: colors.btnCancel,
    },
  },
}));
