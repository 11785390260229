import Card from "components/Card";
import RoleForm from "components/Forms/RoleForm";
import Loading from "components/Loading/Loading";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { createRole, resetRoleStateError } from "store/roleSliceV2";
import { useStyles } from "./styles";
import { toast } from "react-toastify";

export default function CreateRole() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetRoleStateError());
  }, [dispatch]);

  const handleCreateRole = useCallback(
    async (data: any) => {
      setLoading(true);

      const dataSubmit = {
        name: data?.name ?? "",
        permissions: data?.permissions ?? [],
      };
      const response = await dispatch(createRole(dataSubmit));
      if (response?.meta?.requestStatus === "fulfilled") {
        toast.success<void>(t("create_role_successful"));
        navigate("/role");
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
      setLoading(false);
    },
    [dispatch, navigate, t]
  );

  return (
    <>
      <Helmet>
        <title>{t("create_a_new_role")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <div className={classes.containerContent}>
          <Card title={t("create_a_new_role")} titleText={t("please_fill_role_name_and_permissions")}>
            <RoleForm onSubmit={handleCreateRole} />
          </Card>
        </div>
      </div>
    </>
  );
}
