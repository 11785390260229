import { useStyles } from "./styles";
import { Chip as MuiChip } from '@mui/material';

interface ChipProp {
  label: string;
  handleClick: Function;
  className?: string;
  isDisabled?: boolean;
  isActive?: boolean;
}

export default function Chip({
  label,
  handleClick,
  className,
  isDisabled,
  isActive
}: ChipProp) {
  const { classes } = useStyles();
  return (
    <MuiChip
      label={label}
      className={`${classes.container} ${className} ${isActive && classes.active}`}
      onClick={() => handleClick()}
    />
  );
}
