import BaseButton from "components/Buttons/BaseButton";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ImageComment } from "models/common";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

interface CommentProp {
  childrens?: JSX.Element;
  className?: string;
  onClose: Function;
  onsubmit: Function;
  isLog?: Boolean;
  placeholder?: string;
}

export default function Comment({ childrens, className, onClose, onsubmit, isLog, placeholder }: CommentProp) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [content, setContent] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [listImage, setListImage] = useState<ImageComment[]>([]);

  useEffect(() => {
    return () => {
      listImage.forEach((item: ImageComment) => {
        URL.revokeObjectURL(item.name);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (!content) {
      setError(t("this_field_is_required"));
    } else {
      let htmlString = content;
      const parser = new DOMParser();
      const html = parser.parseFromString(htmlString, "text/html");
      const list = html.querySelectorAll("img");
      const listNameImage: string[] = [];
      const listFileSubmit: ImageComment[] = [];
      list.forEach((item: any) => {
        const file = item.getAttribute("src");
        listNameImage.push(file);
      });
      listImage.forEach((item: ImageComment) => {
        if (listNameImage.includes(item.name)) {
          listFileSubmit.push(item);
        }
        htmlString = htmlString.replace(item.name, `\${${item.name}}`);
      });
      const result = await onsubmit(htmlString, listFileSubmit);
      if (result) {
        setContent("");
        listImage.forEach((item: ImageComment) => {
          URL.revokeObjectURL(item.name);
        });
        setListImage([]);
      }
    }
  };

  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file: File) => {
            const url = URL.createObjectURL(file);
            resolve({
              default: url,
            });
            setListImage((prod) => [...prod, { src: file, name: url }]);
          });
        });
      },
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className={`${classes.container} ${className}`}>
      <div>
        <div className={classes.editor}>
          <CKEditor
            editor={DecoupledEditor as any}
            config={{
              toolbar: {
                shouldNotGroupWhenFull: true,
              },
              extraPlugins: [uploadPlugin],
              placeholder: `${placeholder ?? ""}`,
            }}
            data={content}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              if (error) {
                setError("");
              }
              setContent(data);
            }}
            onReady={(editor) => {
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
            }}
          />
        </div>
        <div className={classes.error}>{error}</div>
      </div>
      {isLog ? (
        <div className={classes.wrapBtnLog}>
          <BaseButton title={t("save")} className={classes.btnYesLog} onClick={() => handleSubmit()} />
        </div>
      ) : (
        <div className={classes.wrapBtn}>
          <BaseButton
            title={t("cancel")}
            className={classes.btnNo}
            onClick={() => {
              listImage.forEach((item: ImageComment) => {
                URL.revokeObjectURL(item.name);
              });
              onClose();
            }}
          />
          <BaseButton title={t("save")} className={classes.btnYes} onClick={() => handleSubmit()} />
        </div>
      )}
    </div>
  );
}
