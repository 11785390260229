import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    boxSizing: "border-box",
  }
}));
