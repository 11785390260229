import { makeStyles } from "tss-react/mui";
import iconEmail from "../../assets/images/email.svg"

export const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    padding: 0,
    "& .MuiInputBase-input": {
      width: "100%",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      height: "30px",
      backgroundColor: "#F5F5F5",
      borderRadius: "4px",
      boxSizing: "border-box",
      padding: "0px 8px",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F5F5F5",
      padding: 0,
      "& fieldset": {
        border: "1px solid #DEDEDE",
      },
      "&:hover fieldset": {
        border: "1px solid #202020",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #1070bd",
        outline: "none",
      },
    },
    "& .MuiIconButton-root": {
      marginRight: "-2px",
      marginTop: "5px"
    }
  },
  iconMenu: {
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundColor: '#413C51',
  },
  iconEmail: {
    maskImage: `url(${iconEmail})`,
    maskRepeat: "no-repeat",
    maskSize: "contain"
  },
}));
