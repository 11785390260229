import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../redux/auth/auth.service";
import { useStyles } from "./styles";
import ModalCallLog from "components/Modal/ModalCallLog";
import { useSelector } from "react-redux";
import { isOpenCallLogModalSelector } from "store/callLogSlice";
import { COPYRIGHT } from "utils";

export default function Footer() {
  const { classes } = useStyles();
  const userInfor = getUserInfo();
  const navigate = useNavigate();
  const isOpenCallLogModal = useSelector(isOpenCallLogModalSelector);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/registerForm" && !location.pathname.includes("edit-documents") && !userInfor) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, userInfor]);
  return (
    <>
      {location.pathname !== "/registerForm" && !location.pathname.includes("edit-documents") && userInfor && (
        <Box className={classes.container} component="footer">
          <Container maxWidth={false}>
            <Typography>{COPYRIGHT}</Typography>
          </Container>
        </Box>
      )}
      {isOpenCallLogModal && <ModalCallLog />}
    </>
  );
}
