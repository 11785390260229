import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth.slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import roleSliceV2 from "store/roleSliceV2";
import roleSlice from "store/roleSlice";
import userSlice from "store/userSlice";
import permissionSlice from "store/permissionSlice";
import detailStudentContactSlice from "store/detailStudentContactSlice";
import contactCategorySlice from "store/contactCategorySlice";
import actionContactSlice from "store/actionContactSlice";
import applicationSlice from "store/applicationSlice";
import unitTagSlice from "store/unitTagSlice";
import studentContactSlice from "store/studentContactSlice";
import masterContactSlice from "store/masterContactSlice";
import contactPointSlice from "store/contactPointSlice";
import taskSlice from "store/taskSlice";
import notificationSlice from "store/notifcationSlice";
import landingPageReferralLinkSlice from "store/landingPageReferralLinkSlice";
import studentListsSlice from "store/listStudentContactSlice";
import masterListsSlice from "store/listMasterContactSlice";
import detailMasterContactSlice from "store/detailMasterContactSlice";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import reportSlice from "store/reportSlice";
import studentTaskListSlice from "store/studentTaskListSlice";
import masterTaskListSlice from "store/masterTaskListSlice";
import sendSmsAndEmailSlice from "store/sendSmsAndEmailSlice";
import callLogSlice from "store/callLogSlice";
import actionSupportSlice from "store/actionSupportSlice";
import querySlice from "store/querySlice";
import registerFormSlice from "store/registerFormSlice";
import locationSlice from "store/locationSlice";
import logsSlice from "store/logsSlice";
import studentAdmissionPeriodSlice from "store/actionStudentAdmissionPeriodSlice";

const studentContactsConfig = {
  key: "studentcontacts",
  storage: storage,
  whitelist: ["limit", "page", "searchOption", "selectedTab"],
  stateReconciler: autoMergeLevel2,
};

const masterContactsConfig = {
  key: "mastercontacts",
  storage: storage,
  whitelist: ["limit", "page", "searchOption", "selectedTab"],
  stateReconciler: autoMergeLevel2,
};

const rootReducer: any = combineReducers({
  auth: authSlice.reducer,
  roles: roleSlice.reducer,
  users: userSlice.reducer,
  studentcontacts: persistReducer<RootState>(studentContactsConfig, studentContactSlice.reducer),
  permissions: permissionSlice.reducer,
  rolesV2: roleSliceV2.reducer,
  detailStudentContact: detailStudentContactSlice.reducer,
  contactCategory: contactCategorySlice.reducer,
  actionContact: actionContactSlice.reducer,
  applications: applicationSlice.reducer,
  unitTag: unitTagSlice.reducer,
  mastercontacts: persistReducer<RootState>(masterContactsConfig, masterContactSlice.reducer),
  studentAdmissionPeriods: studentAdmissionPeriodSlice.reducer,
  contactPoint: contactPointSlice.reducer,
  tasks: taskSlice.reducer,
  notification: notificationSlice.reducer,
  studentLists: studentListsSlice.reducer,
  masterLists: masterListsSlice.reducer,
  detailMasterContact: detailMasterContactSlice.reducer,
  landingPageReferralLink: landingPageReferralLinkSlice.reducer,
  reports: reportSlice.reducer,
  studentTaskList: studentTaskListSlice.reducer,
  masterTaskList: masterTaskListSlice.reducer,
  sendSmsAndEmail: sendSmsAndEmailSlice.reducer,
  callLog: callLogSlice.reducer,
  actionSupport: actionSupportSlice.reducer,
  query: querySlice.reducer,
  registerForm: registerFormSlice.reducer,
  location: locationSlice.reducer,
  logs: logsSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
