import { makeStyles } from "tss-react/mui";
import colors from "../../utils/colors";

export const useStyles = makeStyles()(() => ({
  container: {
    backgroundColor: "#4A4358",
    minHeight: "60px",
    "& .MuiToolbar-root": {
      minHeight: "60px",
    },
  },
  logoDeha: {
    width: "80px",
    marginRight: "10px",
  },
  navbar: {
    paddingLeft: "14px",
  },
  menuAvatar: {
    marginTop: "35px",
    maxHeight: "450px",
    overflow: "auto",
  },
  avatar: {
    width: "30px",
    height: "30px",
    marginLeft: "15px",
  },
  wrapLanguage: {
    marginLeft: "15px",
  },
  language: {
    width: "22px",
    height: "16px",
    borderRadius: 2,
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
    },
  },
  activeLanguage: {
    opacity: 1,
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  wrapBell: {
    width: "35px",
    height: "35px",
    marginLeft: "15px",
  },
  bell: {
    color: "#FFFFFF",
    width: "24px",
    height: "24px",
  },
  itemLanguage: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuItem: {
    minWidth: "80px",
    "& p": {
      color: colors.defaultColorText,
    },
  },
  wrapNotification: {
    display: "flex",
    position: "relative",
  },
  numberNotify: {
    fontSize: "10px",
    fontWeight: 500,
    backgroundColor: "red",
    width: "15px",
    height: "15px",
    color: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: "-1px",
    bottom: 0,
  },
  menuItemNotify: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "6px 6px",
    margin: "0 10px",
    "& p": {
      fontSize: "14px !important",
    },
  },
  boderItem: {
    borderBottom: "1px solid #D3D3D3",
  },
  viewed: {
    opacity: 0.6,
    backgroundColor: "#FAFAFA",
  },
  nameFile: {
    color: "#F2756F",
    fontStyle: "italic",
    fontWeight: 400,
  },
  textBold: {
    fontWeight: 700,
  },
  infor: {
    width: "24px",
    height: "24px",
    color: "#1172BA",
    marginRight: "3px",
  },
  nameNotify: {
    fontWeight: 700,
  },
  iconButton: {
    fontWeight: 600,
    color: "#F2756F",
    paddingRight: "3px",
  },
  textSuccess: {
    color: "green !important",
    fontWeight: "bold !important",
  },
  textFailed: {
    color: "red !important",
    fontWeight: "bold !important",
  },
  wrapLongTextFail: {
    maxWidth: "300px",
  },
  wrapLongTextSuccess: {
    maxWidth: "350px",
  },
}));
