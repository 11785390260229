import { yupResolver } from "@hookform/resolvers/yup";
import Card from "components/Card";
import AutoCompleteForm from "components/Forms/AutoCompleteForm";
import ButtonBackForm from "components/Forms/ButtonBackForm";
import ButtonSaveForm from "components/Forms/ButtonSaveForm";
import InputTextForm from "components/Forms/InputTextForm";
import SelectForm from "components/Forms/SelectForm";
import Loading from "components/Loading/Loading";
import ModalConfirmLeaveForm from "components/ModalConfirmLeaveForm";
import { LooseObject } from "models/common";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "redux/store";
import { getRoles, roleListSelector } from "store/roleSliceV2";
import { resetUserError, userErrorsSelector } from "store/userSlice";
import { USER_STATUS, USER_TYPE } from "utils/constant";
import * as Yup from "yup";
import { StringSchema } from "yup";
import { useStyles } from "./styles";
import PermissionForm from "components/Forms/PermissionForm";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalShowPermissionsOfRole from "components/Modal/ModalShowPermissionsOfRole";

interface IProp {
  userData?: UserData;
  onSubmitData: Function;
  isUpdateMode?: boolean;
}

interface UserData {
  name?: string;
  email?: string;
  type?: number;
  status?: number;
  password?: string;
  roles?: LooseObject[];
  permissions?: LooseObject[];
  [key: string]: any;
}

export default function UserForm({ onSubmitData, userData = {}, isUpdateMode }: IProp) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isOpenModalConfirmLeaveForm, setIsOpenModalConfirmLeaveForm] = useState(false);
  const onSubmitHandle = async (data: FormData) => {
    setLoading(true);
    const userData = {
      email: data?.email ?? "",
      name: data?.name ?? "",
      password: data?.password ?? "",
      type: data?.type ?? 0,
      status: data?.status ?? 0,
      roles: data?.roles?.map((role: LooseObject) => role?.name) ?? [],
      permissions: data?.permissions ?? [],
    };

    onSubmitData(userData);
    setLoading(false);
  };

  useEffect(() => {
    dispatch(resetUserError());
    setLoading(true);
    dispatch(getRoles({ limit: "*" }));
    setLoading(false);
  }, [dispatch]);

  const roleDefaultValue =
    userData?.roles?.map((item: LooseObject) => ({
      value: item.id,
      label: item.name,
    })) ?? [];

  const roles = useSelector(roleListSelector);
  const errorFromApis = useSelector(userErrorsSelector);

  const handleClickBack = () => {
    isDirty ? setIsOpenModalConfirmLeaveForm(true) : navigate("/user");
  };

  type FormData = Yup.InferType<typeof validationSchema>;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("email_is_required"))
      .email(t("email_is_invalid"))
      .max(255, t("email_must_not_exceed_255_characters")),
    password: Yup.string().when(["type"], {
      is: (type: number) => type === 0 && !isUpdateMode,
      then: (schema: StringSchema) =>
        schema.required(t("password_is_required")).max(255, t("password_must_not_exceed_255_characters")),
      otherwise: (schema: StringSchema) => schema.notRequired(),
    }),
    name: Yup.string().when("type", {
      is: 0,
      then: (schema: StringSchema) =>
        schema.required(t("full_name_is_required")).max(255, t("full_name_must_not_exceed_255_characters")),
      otherwise: (schema: StringSchema) => schema,
    }),
    type: Yup.number().typeError(t("user_type_is_required")).required(t("user_type_is_required")),
    status: Yup.number().typeError(t("status_is_required")).required(t("status_is_required")),
    roles: Yup.array()
      .typeError(t("roles_is_required"))
      .required(t("roles_is_required"))
      .min(1, t("roles_is_required")),
    permissions: Yup.array().notRequired(),
    permissions_of_roles: Yup.array().notRequired(),
  });

  const {
    getValues,
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    setValue,
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: isUpdateMode ? "" : "",
      name: "",
      type: USER_TYPE[0].value as number,
      status: USER_STATUS[0].value as number,
      roles: roleDefaultValue,
      permissions: [],
      permissions_of_roles: [],
    },
  });

  const getPermissionIdsByListRole = useCallback((roles: any) => {
    const permissionIds: Number[] = [];
    roles?.forEach((item: any) => {
      item?.permissions?.forEach((permission: any) => {
        permissionIds.push(permission?.id);
      });
    });
    return permissionIds;
  }, []);

  useEffect(() => {
    if (!loading && Object.keys(userData).length !== 0) {
      setValue("email", userData?.email || "");
      setValue("name", userData.name || "");
      setValue("type", userData?.type as number);
      setValue("status", userData?.status as number);
      setValue("roles", userData?.roles || []);
      setIsStandardForm(userData?.type === USER_TYPE[0].value);
      const permissionIds = userData?.permissions?.map((item: LooseObject) => item.id) ?? [];
      setValue("permissions", permissionIds);
      setValue("permissions_of_roles", getPermissionIdsByListRole(userData?.roles || []));
    }
  }, [userData, loading, setValue, dispatch, getPermissionIdsByListRole]);

  const onTypeChange = async () => {
    await trigger("type");
    setIsStandardForm(getValues("type") === USER_TYPE[0].value);
  };

  const [isStandardForm, setIsStandardForm] = useState(true);

  const getErrorsValidate = useCallback(
    (name: any) => {
      return Object.keys(errors).length !== 0
        ? errors[name as keyof typeof errors]?.message
        : errorFromApis?.[name]?.[0];
    },
    [errors, errorFromApis]
  );

  const [isOpenModalShowPermissionsOfRole, setIsOpenModalShowPermissionsOfRole] = useState(false);

  const onChangeRole = useCallback(
    (value: any) => {
      setValue("permissions_of_roles", getPermissionIdsByListRole(value));
    },
    [setValue, getPermissionIdsByListRole]
  );

  return (
    <>
      {loading && <Loading />}
      <div className={classes.container}>
        <div className={classes.contentPage}>
          <form onSubmit={handleSubmit(onSubmitHandle)}>
            <Card title={t("profile")} titleText={t("title_create_profile_user")}>
              <SelectForm
                title={t("type")}
                isRequired={true}
                data={USER_TYPE}
                errors={getErrorsValidate("type")}
                name="type"
                control={control}
                onChange={onTypeChange}
              />
              <SelectForm
                title={t("status")}
                isRequired={true}
                data={USER_STATUS}
                name="status"
                control={control}
                errors={getErrorsValidate("status")}
              />
              {isStandardForm && (
                <InputTextForm
                  name={"name"}
                  title={t("full_name")}
                  isRequired={true}
                  register={register}
                  errors={getErrorsValidate("name")}
                />
              )}
              <InputTextForm
                title={t("email")}
                isRequired={true}
                register={register}
                name="email"
                errors={getErrorsValidate("email")}
              />
              {isStandardForm && (
                <InputTextForm
                  type="password"
                  title={t("password")}
                  isRequired={!isUpdateMode}
                  register={register}
                  name="password"
                  errors={getErrorsValidate("password")}
                />
              )}
            </Card>

            <Card title={t("permissions")} titleText={t("please_fill_role_name_and_permissions")}>
              <div style={{ display: "flex" }}>
                <AutoCompleteForm
                  title={t("role")}
                  data={roles?.data}
                  isMultiple={true}
                  isRequired={true}
                  name="roles"
                  register={register}
                  key={"roles"}
                  control={control}
                  defaultValue={roleDefaultValue}
                  errors={getErrorsValidate("roles")}
                  onChangeSearch={() => {}}
                  onChangeValue={onChangeRole}
                />
                <VisibilityIcon
                  sx={{ pl: "5px", pt: "3px", ":hover": { cursor: "pointer" } }}
                  onClick={() => setIsOpenModalShowPermissionsOfRole(true)}
                />
              </div>
              <div className={classes.titleText}>{t("permissions")}</div>
              <div style={{ margin: "20px" }}>
                <PermissionForm key={"permissions"} control={control} />
              </div>
              <div className={classes.containerBtn}>
                <ButtonBackForm onClick={handleClickBack} />
                <ButtonSaveForm />
              </div>
            </Card>
          </form>
        </div>
      </div>
      <ModalShowPermissionsOfRole
        isOpen={isOpenModalShowPermissionsOfRole}
        onClose={() => setIsOpenModalShowPermissionsOfRole(false)}
        control={control}
      />
      <ModalConfirmLeaveForm
        isOpen={isOpenModalConfirmLeaveForm}
        onClose={() => {
          navigate("/user");
        }}
        onConfirm={() => {
          setIsOpenModalConfirmLeaveForm(false);
        }}
      />
    </>
  );
}
