import { Theme } from "@mui/material";
import colors from "../../utils/colors";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  containerGrid: {
    backgroundColor: colors.backgroundDark,
    height: "100%",
  },
  container: {
    width: "100vw",
    height: "100vh",
    "@media (max-width: 425px)": {
      flexDirection: "column-reverse",
      overflow: "auto",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  contentLeft: {
    height: "100%",
    backgroundColor: colors.backgroundLight,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 50px",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "80px",
      "@media (max-width: 425px)": {
        paddingTop: "unset",
      },
    },
    "& form": {
      width: "100%",
    },
    "@media (max-width: 768px)": {
      flex: 1,
    },
    "@media (max-width: 425px)": {
      flex: "unset",
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "448px",
    },
  },
  wrapTitleAndLogo: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "448px",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "30px",
    position: "relative",
  },
  title: {
    color: "#858585",
    position: "absolute",
    top: "145px",
    [theme.breakpoints.between("xs", "sm")]: {
      top: "105px",
      fontSize: "1rem",
    },
  },
  contentRight: {
    backgroundColor: colors.backgroundDark,
    display: "flex",
    width: "auto",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      flex: 1,
    },
    "@media (max-width: 425px)": {
      flex: "unset",
    },
  },
  imgLogin: {
    [theme.breakpoints.between("xs", "md")]: {
      padding: "30px 0",
    },
    width: "60%",
  },
  dehaAMS: {
    width: "70%",
  },
  wrapLoginSocial: {
    width: "100%",
    display: "flex",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  btnGoogle: {
    backgroundColor: colors.backgroundDark,
    fontWeight: "bold",
    flex: 1,
    height: "40px",
    boxShadow: "none",
    borderRadius: "10px",
    textTransform: "none",
    "& a": {
      color: colors.defaultColorText,
      fontSize: "16px",
      fontWeight: 400,
      textDecoration: "none",
    },
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  btnMicrosoft: {
    backgroundColor: colors.backgroundDark,
    fontWeight: "bold",
    flex: 1,
    height: "40px",
    boxShadow: "none",
    borderRadius: "10px",
    textTransform: "none",
    "& p": {
      color: colors.defaultColorText,
      fontSize: "16px",
      fontWeight: 400,
      textDecoration: "none",
    },
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  iconGoogle: {
    width: "18px",
    height: "18px",
    marginRight: "8px",
  },
  containerLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& div": {
      width: "42%",
    },
    "& hr": {
      border: "1px solid #e6e6ea",
    },
  },
  formControl: {
    width: "100%",
  },
  label: {
    width: "100%",
  },
  input: {
    width: "100%",
    height: "50px",
    backgroundColor: colors.backgroundDark,
    border: "1px solid #F7F7F8",
    borderRadius: "10px",
    padding: "0 20px",
    boxSizing: "border-box",
    "&:focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
  },
  containerPassword: {
    position: "relative",
    "& input": {
      paddingRight: "45px",
    },
  },
  btnHide: {
    position: "absolute",
    right: "6px",
    bottom: "8px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  caption: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "40px",
    fontSize: "14px",
    fontWeight: 400,
    "& p": {
      color: "#F2756F",
    },
    "& label": {
      marginLeft: "4px",
    },
  },
  btnLogin: {
    width: "100%",
    height: "40px",
    borderRadius: "10px",
    color: "#FFFFFF",
    backgroundColor: colors.btnDark,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Roboto",
    "&:hover": {
      backgroundColor: colors.btnDark,
      opacity: 0.7,
      transition: "ease-in 0.2s",
    },
  },
  footer: {
    color: "#666",
    fontSize: "16px",
    fontWeight: 500,
  },
  error: {
    color: "#F2756F",
    fontSize: "14px",
    fontWeight: 400,
  },
}));
