import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    minWidth: "200px",
    "& .MuiOutlinedInput-root": {
      height: "42px",
      borderRadius: "8px",
      fontSize: "16px",
    },
    "& .MuiAutocomplete-root fieldset": {
      border: "1px solid #A0A0A0",
    },
    "& .Mui-focused fieldset": {
      border: "1px solid #1070bd !important",
    },
  },
}));
