import { yupResolver } from "@hookform/resolvers/yup";
import { Close, PaidOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

import ErrorMessageForm from "components/Forms/ErrorMessageForm";
import InputDateTime from "components/inputDateTime";
import { LooseObject } from "models/common";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import BaseButton from "../../Buttons/BaseButton";
import { useStyles } from "./styles";
import { useAppDispatch } from "redux/store";
import { confirmApplicationFee, getAllCandidates } from "store/registerFormSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";

interface IProps {
  isOpen: boolean;
  onClose: Function;
  dataDetail: LooseObject;
}

export default function ModalConfirmIsPaid({ isOpen, onClose, dataDetail }: IProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const validation = Yup.object().shape({
    time_stamps: Yup.date().required().required(t("this_field_is_required")),
  });

  type ConfirmIsPaidForm = Yup.InferType<typeof validation>;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ConfirmIsPaidForm>({
    mode: "onChange",
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    setValue("time_stamps", dataDetail.application_fee_payment_timestamp ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetail.application_fee_payment_timestamp]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: ConfirmIsPaidForm) => {
    const dataNew = {
      application_ids: [dataDetail?.id],
      time_stamps: [moment(data.time_stamps).format("YYYY-MM-DD HH:mm")],
    };

    const response = await dispatch(confirmApplicationFee(dataNew));
    await dispatch(getAllCandidates());
    if (response.meta.requestStatus === "fulfilled") {
      toast.success<void>(t("change_result_successful"));
      handleClose();
    } else {
      console.error(response.payload.message);
      toast.error<void>(t("failed"));
    }
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"sm"} onClose={() => onClose()} open={isOpen}>
        <div className={classes.headerModal}>
          <div className={classes.wrapTitle}>
            <PaidOutlined />
            <p>{t("edit_payment_status")}</p>
          </div>
          <IconButton aria-label="close" onClick={handleClose} className={classes.btnClose}>
            <Close />
          </IconButton>
        </div>

        <DialogContent dividers className={classes.containerContent}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="body1" sx={{ minWidth: "180px" }}>
                {t("payment_time")} <span style={{ color: "red" }}>*</span>
              </Typography>
              <InputDateTime control={control} nameRegister="time_stamps" isPast={true} />
            </Box>
            <ErrorMessageForm errors={errors["time_stamps"]?.message} widthLabel={120} />
          </Box>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <BaseButton title={t("cancel")} className={classes.btnCancel} onClick={handleClose} />
          <BaseButton
            title={t("save")}
            className={classes.btnSearch}
            isSubmit={false}
            onClick={handleSubmit(onSubmit)}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
