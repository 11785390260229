import HeaderCard from "components/HeaderCard";
import Loading from "components/Loading/Loading";
import { LooseObject } from "models/common";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/store";
import { getAllNotification, getNotifyById } from "store/notifcationSlice";
import { useStyles } from "./styles";
import HTMLReactParser from "html-react-parser";
import BaseButton from "components/Buttons/BaseButton";

export default function NotifyDetail() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<LooseObject>({});

  const fetchData = async () => {
    setLoading(true);
    const data = await dispatch(getNotifyById(id));
    dispatch(getAllNotification());
    if (data) {
      setData(data);
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
      navigate("/");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleClickUser = (contactId: number, contactType: number) => {
    if (contactType === 1) {
      navigate(`/studentContact/${contactId}`);
    } else if (contactType === 2) {
      navigate(`/masterContact/${contactId}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("detail_notify")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <BaseButton title={t("back")} className={classes.btnBack} onClick={() => navigate(-1)} />
        <div className={classes.containerContent}>
          <div className={classes.wrapContent}>
            <HeaderCard title={t("notification_details")} />
            <div className={classes.content}>
              <div className={`${classes.formControl}`}>
                <div className={classes.label}>{`${t("event_name")}:`}</div>
                <div className={classes.wrapValueItem}>
                  {data?.event === "EMAIL_BATCH_COMPLETED" ? t("send_email") : t("send_sms")}
                </div>
              </div>
              <div className={`${classes.formControl}`}>
                <div className={classes.label}>{`${t("time")}:`}</div>
                <div className={classes.wrapValueItem}>{data?.send_at}</div>
              </div>
              <div className={`${classes.formControl}`}>
                <div className={classes.label}>{`${t("result")}:`}</div>
                <div className={classes.wrapValueItem}>
                  {`${t("completed")} `}
                  <span className={classes.success}>{`${
                    Array.isArray(data?.success_data) && data?.success_data.length
                  }/${data?.total_recipients}`}</span>
                  {`, ${t("uncompleted")}  `}
                  <span className={classes.fail}>{`${Array.isArray(data?.failed_data) && data?.failed_data.length}/${
                    data?.total_recipients
                  }`}</span>
                </div>
              </div>
              <div className={`${classes.formControl}`}>
                <div className={classes.label}>{`${t("completed")}:`}</div>
                <div className={classes.wrapValueItem}>
                  {Array.isArray(data?.success_data) &&
                    data?.success_data.map((item: LooseObject, index: number) => (
                      <span key={`${item?.contact_id} ${index} - e`}>
                        <span className={classes.successContactLink}>{index !== 0 ? ", " : ""}</span>
                        <span
                          className={`${classes.successContactLink}`}
                          onClick={() => handleClickUser(item?.contact_id, item?.contact_type)}
                        >
                          {item?.email ?? item?.phone_number ?? ""}
                        </span>
                      </span>
                    ))}
                </div>
              </div>
              <div className={`${classes.formControl}`}>
                <div className={classes.label}>{`${t("uncompleted")}:`}</div>
                <div className={classes.wrapValueItem}>
                  {Array.isArray(data?.failed_data) &&
                    data?.failed_data.map((item: LooseObject, index: number) => (
                      <span key={`${item?.contact_id} ${index}- e`}>
                        <span className={classes.failContactLink}>{index !== 0 ? ", " : ""}</span>
                        <span
                          className={`${classes.failContactLink}`}
                          onClick={() => handleClickUser(item?.contact_id, item?.contact_type)}
                        >
                          {item?.email ?? item?.phone_number ?? ""}
                        </span>
                      </span>
                    ))}
                </div>
              </div>
              <div className={`${classes.formControl}`}>
                <div className={classes.label}>{`${t("reason")}:`}</div>
                <div className={classes.wrapValueItem}>
                  {Array.isArray(data?.errors) &&
                    data?.errors.map((item: string, index: number) => <div key={` ${index}- f`}>{item ?? ""}</div>)}
                </div>
              </div>
              <div className={`${classes.formControl}`}>
                <div className={classes.label}>{`${t("content")}:`}</div>
                <div className={classes.wrapValueItem}>{HTMLReactParser(data?.content ?? "")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
