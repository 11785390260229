import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BaseButton from "components/Buttons/BaseButton";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { IModuleField, LooseObject } from "models/common";
import { useState } from "react";
import { Link } from "react-router-dom";
import readXlsxFile from "read-excel-file";

interface ImportStep1Prop {
  HandleNextStep2: Function;
  className?: string;
  file: File | null;
  setFile: Function;
  setModuleFile: Function;
  setLoading: Function;
  linkTemplete: string;
}

export default function ImportStep1({
  className,
  HandleNextStep2,
  file,
  setFile,
  setModuleFile,
  setLoading,
  linkTemplete,
}: ImportStep1Prop) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<string>("");

  const validateFile = async (file: File) => {
    let error = "";

    if (file.size / (1024 * 1024) > 10) {
      return `the_file_must_not_be_greater_than_10MB`;
    } else if (
      file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "application/vnd.ms-excel"
    ) {
      return `the_file_must_be_a_file_of_type_xlsx_xls`;
    }
    return error;
  };

  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileInput = e.target.files[0];
      setFile(fileInput);
      e.target.value = "";
      const err = await validateFile(fileInput);
      setError(err);
    }
  };

  const handleClickNext = async () => {
    if (error) {
      return;
    }
    if (!file) {
      setError("this_field_is_required");
    } else {
      setLoading(true);
      let result: LooseObject = [];
      file &&
        (await readXlsxFile(file).then((rows) => {
          result = rows;
        }));
      if (result.length < 2) {
        setError("the_file_has_no_data");
        setLoading(false);
        return;
      } else {
        let module: IModuleField[] = [];
        for (let index = 0; index < result[0].length; index++) {
          module.push({
            id: index,
            label: result[0][index],
            row: result[1][index],
          });
        }
        module.push({
          id: -1,
          label: t("not_selected"),
          row: "",
        });
        setModuleFile(module);
      }
      HandleNextStep2();
    }
  };

  return (
    <div className={`${classes.container} ${className}`}>
      <p className={classes.explainStep}>{t("explain_import_step_1")}</p>
      <p className={classes.labelStep}>{`${t("step")} 1: ${t("upload_import_file")}`}</p>
      <hr className={classes.hr} />
      <Link to={linkTemplete} className={classes.linkDownload}>
        <p className={classes.downloadTemplate}>
          {t("download_import_file_template")} <InfoOutlinedIcon className={classes.infor} />
        </p>
      </Link>
      <p className={classes.fileUpload}>{t("file_upload")}</p>
      <div className={classes.wrapbtnInput}>
        <label className={classes.btnInputFile} onClick={() => setError("")}>
          {t("select_file")}
          <input type="file" accept=".xlsx, .xls" onChange={handleFileSelected} />
        </label>
        {file && (
          <div>
            {file?.name}{" "}
            <IconButton
              className={classes.iconDelete}
              onClick={() => {
                if (error) {
                  setError("");
                }
                setFile(null);
              }}
            >
              {" "}
              <CancelIcon />
            </IconButton>
          </div>
        )}
      </div>
      {error && <div className={classes.error}>{t(error as any)}</div>}
      <p className={classes.warningFile}>{t("this_can_only_be_alphanumeric")}</p>
      <hr className={classes.hr} />
      <p className={classes.question}>{t("what_would_you_like_to_do_with_the_imported_data")}</p>
      <p className={classes.note}>{t("note_the_system_will_create_new_records_and_update_existing_records")}</p>
      <BaseButton
        title={t("next")}
        endIcon={<ArrowForwardIosRoundedIcon />}
        className={classes.btnNext}
        onClick={() => handleClickNext()}
      />
    </div>
  );
}
