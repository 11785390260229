const colors = {
  backgroundDark: "#FAFAFA",
  backgroundLight: "#FFF",
  btnDark: "#4A4358",
  btnCreate: "#F2857F",
  btnPrimary: "#413C51",
  btnCancel: "#FFFFFF",
  defaultColorText: "#413C51",
  danger: "#F2756F",
  borderBlack: "#00000033",
  whiteF5: "#F5F5F5",
  selectedItem: "#605BFF1A",
  borderColumn: "#d3d3d3",
  buttonClear: "#D63916",
};

export const colorsPaletteChart = {
  default: [
    "#B80E1E",
    "#3D8341",
    "#F8AF1A",
    "#785DD0",
    "#1a86e4",
    "#FABE98",
    "#98e297",
    "#d1e6f9",
    "#a3cef4",
    "#0f5089",
    "#489ee9",
    "#75b6ef",
  ],
  default_opacity: [
    "#B80E1Ecc",
    "#3D8341cc",
    "#F8AF1Acc",
    "#785DD0cc",
    "#1a86e4cc",
    "#FABE98cc",
    "#98e297cc",
    "#d1e6f9cc",
    "#a3cef4cc",
    "#0f5089cc",
    "#489ee9cc",
    "#75b6efcc",
  ],
  paletteClassicBlue: ["#0f5089", "#156bb6", "#1a86e4", "#489ee9", "#75b6ef", "#a3cef4", "#d1e6f9"],
  paletteClassicBlue_opacity: [
    "#0f5089cc",
    "#156bb6cc",
    "#1a86e4cc",
    "#489ee9cc",
    "#75b6efaa",
    "#a3cef4aa",
    "#d1e6f9aa",
  ],
  paletteLivingCoral: ["#fa7268", "#fa9668", "#fabb68", "#fadf68", "#effa68", "#cbfa68"],
  paletteLivingCoral_opacity: ["#fa7268cc", "#fa9668cc", "#fabb68cc", "#fadf68cc", "#effa68cc", "#cbfa68cc"],
  paletteVivaMagenta: ["#551120", "#881c34", "#B80E1E", "#dd5775", "#e88ca0"],
  paletteVivaMagenta_opacity: ["#55112055", "#881c3455", "#B80E1E33", "#dd577533", "#e88ca033"],
  paletteVJU: ["#3D8341", "#B80E1E"],
  paletteShadesViva: ["#be3455", "#cd5b6f", "#db7d8a", "#e69da6", "#f0bec3", "#f9dee0"],
  paletteShadesViva_opacity: ["#be3455aa", "#cd5b6faa", "#db7d8aaa", "#e69da6aa", "#f0bec3aa", "#f9dee0aa"],
};

export default colors;
