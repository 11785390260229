import { Box, Typography } from "@mui/material";
import BaseChart from "components/Charts";
import { useTranslation } from "react-i18next";
import colors, { colorsPaletteChart } from "utils/colors";
import { optionsBarChart } from "utils/optionsChart";

const StatisticChartByResults = () => {
  const { t } = useTranslation();

  const labels = [t("passed"), t("failed"), t("absent")];

  const data = [
    {
      data: [40, 50, 10],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];
  const data2 = [
    {
      data: [23, 3, 10],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "50%" }}>
          <Typography variant="body1" sx={{ mb: "16px" }}>
            {t("student")}
          </Typography>
          <Box sx={{ paddingX: "10px" }}>
            <BaseChart
              type={"bar"}
              datasets={data}
              labels={labels}
              options={optionsBarChart}
            />
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Typography variant="body1" sx={{ mb: "16px" }}>
            {t("master")}
          </Typography>
          <Box sx={{ paddingX: "10px" }}>
            <BaseChart
              type={"bar"}
              datasets={data2}
              labels={labels}
              options={optionsBarChart}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StatisticChartByResults;
