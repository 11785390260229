import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  wrapBtnInputFile: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  btnInputFile: {
    width: "200px",
    minWidth: "200px",
    height: "32px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#007f3e",
    fontSize: "14px",
    fontWeight: 400,
    color: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.25)",
    marginRight: "10px",
    "& input[type='file']": {
      opacity: 0,
      width: 0,
      height: 0,
    },
    "&:hover": {
      backgroundColor: "#007f3e",
      opacity: 0.8,
      transition: "ease-in 0.2s",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0",
    },
  },
  inputFile: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  numberOfImage: {
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  previewContainer: {
    display: "flex",
    overflowX: "auto",
    maxWidth: "100%",
  },
  wrapImagePreview: {
    width: "100px",
    minWidth: "100px",
    height: "100px",
    marginRight: "5px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid f1f1f1",
    backgroundColor: "#f1f1f1",
    borderRadius: "4px",
  },
  imagePreview: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  btnRemove: {
    position: "absolute",
    right: "0",
    top: "0",
    color: "#ef1a1a",
    padding: "4px",
    opacity: 0.7,
  },
  iconRemove: {
    fontSize: "20px",
  },
  disabled: {
    cursor: "not-allowed",
    backgroundColor: "#9fa3a9",
    "&:hover": {
      backgroundColor: "#9fa3a9",
    },
  },
}));
