import { makeStyles } from "tss-react/mui";
import iconEmail from "../../../../../../assets/images/email.svg";
import iconSms from "../../../../../../assets/images/sms.svg";
import iconCall from "../../../../../../assets/images/call.svg";

export const useStyles = makeStyles()(() => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
  },
  iconAdd: {
    fontSize: "29px !important",
    marginRight: "-4px",
  },
  headerCard: {
    borderRadius: "4px 4px 0 0 !important",
  },
  arrowDown: {
    color: "#FFF",
    fontSize: "30px",
  },
  containerCardContent: {
    backgroundColor: "#FAFAFA",
    paddingLeft: "16px!important",
    margin: 0,
    width: "unset",
  },
  itemContent: {
    flexGrow: 1,
    display: "grid",
    gridRowGap: "10px",
  },
  itemHeader: {
    color: "#000",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "230px",
  },
  itemSource: {
    color: "#7C8286",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    paddingTop: "3px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "230px",
  },
  itemAttribute: {
    color: "#413C51",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "230px",
  },
  containerData: {
    display: "grid",
    gridTemplateColumns: "100%",
    gridRowGap: "10px",
    marginTop: "10px",
  },
  mbCard: {
    marginBottom: "16px!important",
  },
  itemContentContainer: {
    minHeight: "132px",
    borderRadius: "5px",
    border: "0.5px dashed #413C51",
    padding: "10px!important",
    display: "flex",
    // marginBottom: "20px!important",
    // marginRight: "20px",
  },
  editBtn: {
    width: "24px",
    height: "24px",
    color: "#413C51",
    flexShrink: 0,
  },
  deleteBtn: {
    width: "24px",
    height: "24px",
    color: "#FF0000",
    flexShrink: 0,
  },
  addBtn: {
    borderRadius: "5px",
    background: "#413C51",
    padding: "6px 16px!important",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapBtn: {
    display: "flex",
  },
  horizontalIcons: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  verticalIcons: {
    display: "flex",
    flexDirection: "column",
    "& button": {
      padding: "4px 2px !important",
    }
  },
  wrapIconEmail: {
    display:"flex",
    justifyContent: "end"
  },
  horizontalIconSmsPhone: {
    display: "flex",
  },
  iconContainer: {
    display: "flex",
  },
  iconMenu: {
    display: "block",
    width: "20px",
    height: "20px",
    backgroundColor: "#e74c3c",
  },
  iconEmail: {
    maskImage: `url(${iconEmail})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
  iconSms: {
    maskImage: `url(${iconSms})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
  iconCall: {
    maskImage: `url(${iconCall})`,
    maskRepeat: "no-repeat",
    maskSize: "contain",
  },
}));
