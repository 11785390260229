import { Box, Typography } from "@mui/material";
import BaseChart from "components/Charts";
import React from "react";
import { useTranslation } from "react-i18next";
import colors, { colorsPaletteChart } from "utils/colors";
import { optionsPieChart } from "utils/optionsChart";

const StatisticChartByCertificate = () => {
  const { t } = useTranslation();

  const labels = [t("english_certificate"), t("japanese_certificate")];

  const data = [
    {
      data: [40, 60],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];
  const data2 = [
    {
      data: [80, 20],
      borderColor: colors.backgroundLight,
      backgroundColor: colorsPaletteChart.default,
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "50%" }}>
          <Typography variant="body1">{t("student")}</Typography>
          <BaseChart
            type={"pie"}
            datasets={data}
            labels={labels}
            options={optionsPieChart}
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <Typography variant="body1">{t("master")}</Typography>
          <BaseChart
            type={"pie"}
            datasets={data2}
            labels={labels}
            options={optionsPieChart}
          />
        </Box>
      </Box>
    </>
  );
};

export default StatisticChartByCertificate;
