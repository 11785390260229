import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinkButton from "../../../../../components/Buttons/LinkButton";
import HeaderPage from "../../../../../components/HeaderPage";
import Table from "../../../../../components/Table/index";
import { LooseObject } from "../../../../../models/common";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { fetchRoles, setPageSize } from "../../../../../store/roleSlice";
import { LIST_ROLE_COLUMN } from "../../../../../utils/constant";
import { sortTable } from "../../../../../utils/helper";
import Loading from "./../../../../../components/Loading/Loading";
import { useStyles } from "./styles";
import RoleBaseComponent from "components/RoleBaseComponent";

export default function Role() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<LooseObject[]>([]);
  const roles = useSelector((state: RootState) => state.roles.roles);
  const page = useSelector((state: RootState) => state.roles.page);
  const total = useSelector((state: RootState) => state.roles.total);
  const pageSize = useSelector((state: RootState) => state.roles.limit);
  const [limit, setLimit] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState<number>(page);

  useEffect(() => {
    setLoading(true);
    setCurrentPage(page);
    navigate(`?page=${page}`);
    dispatch(fetchRoles(page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch, navigate, page, pageSize]);

  useEffect(() => {
    setData(roles);
  }, [roles]);

  const handlePageClick = (page: number = 1) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
    setLoading(true);
    dispatch(fetchRoles(page))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const handleSortTable = useCallback(
    async (field: string, type: string) => {
      const newData: LooseObject[] = sortTable(data, field, type);
      setData(newData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [data]
  );

  const handleChangeLimit = useCallback(async (limit: number) => {
    setLimit(limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(async (page: number) => {
    handlePageClick(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetPageSize = useCallback(async (data: number) => {
    dispatch(setPageSize(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("role")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <HeaderPage title={t("user_role_list")}>
          <RoleBaseComponent permissions={["role_create"]}>
            <LinkButton
              title={t("create")}
              className={classes.btnCreate}
              startIcon={<AddRoundedIcon className={classes.iconAdd} />}
              link="/role/create"
            />
          </RoleBaseComponent>
        </HeaderPage>
        <div className={classes.contentPage}>
          {!loading && (
            <Table
              data={data}
              columns={LIST_ROLE_COLUMN}
              total={total}
              limit={limit}
              pageSize={pageSize}
              currentPage={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
              handleSortTable={handleSortTable}
              setPageSize={handleSetPageSize}
              disableCheckbox
            />
          )}
        </div>
      </div>
    </>
  );
}
