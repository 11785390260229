import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  containerContent: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    boxSizing: "border-box",
  },
  containerBtn: {
    display: "flex",
    justifyContent: "center"
  },
  titleText: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#413C51",
    marginBottom: "25px",
  }
}));
