import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import colors from "utils/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    paddingBottom: "30px",
    "& .MuiOutlinedInput-root": {
      minHeight: "30px !important",
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "8px !important",
      fontSize: "14px!important",
      fontWeight: "400!important",
      border: "none",
      borderRadius: "5px",
      backgroundColor: colors.whiteF5,
    },
    "& .MuiAutocomplete-tag": {
      height: "20px",
    },
    "& .MuiChip-deleteIcon": {
      fontSize: "14px !important",
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: 400,
    },
    "& .MuiAutocomplete-input": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "0 !important",
      fontSize: "14px ! important",
    },
    "& .MuiAutocomplete-root fieldset": {
      border: "1px solid #DDD",
    },
    "& .Mui-focused fieldset": {
      border: "1px solid #1070bd !important",
    },
    "& .MuiAutocomplete-tagSizeMedium": {
      borderRadius: 5,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10px",
    },
  },
  title: {
    borderRadius: "4px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#7C8286",
    "& p": {
      fontSize: "18px",
      fontWeight: 400,
      color: colors.backgroundLight,
      marginLeft: "15px",
    },
  },
  content: {
    borderRadius: "0 0 4px 4px",
    padding: "0 16px",
  },
  wrapInput: {
    display: "flex",
    flexDirection: "column",
    width: "387px",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
  input: {
    maxWidth: "100%",
    ":focus": {
      border: "1px solid #1070bd",
      outline: "none",
    },
    ":hover": {
      borderColor: "1px solid #1070bd",
      cursor: "pointer",
    },
  },
  wrap: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 400,
    "& p": {
      marginRight: "60px",
      "& span": {
        color: "#F00",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& p": {
        marginRight: 0,
        marginBottom: "5px",
      },
    },
  },
  label: {
    margin: 0,
  },
  textField: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
  },
  noOptions: {
    fontSize: "14px",
    fontWeight: "400",
  },
  flexGrow: {
    flexGrow: 1,
  },
}));
