import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "30px !important",
  },
  wrapLanguage: {
    width: "25px",
    height: "18px",
    overflow: "hidden",
    border: "0.5px solid #4A435833",
    display: "flex",
    borderRadius: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  language: {
    width: "auto",
    height: "100%",
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
    },
  },
  activeLanguage: {
    opacity: `1 !important`,
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  itemLanguage: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
