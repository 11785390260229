import NoteAltIcon from "@mui/icons-material/NoteAlt";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import CardCollapse from "components/CardCollapse";
import Select from "components/Select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import InputFile from "../../../../components/InputFile/InputFile";
import PaymentInformation from "../PaymentInformation";
import { useStyles } from "./styles";
import { useMediaQuery, useTheme } from "@mui/material";
import {split} from "lodash";
import { useEffect, useMemo } from "react";
interface RegistrationDossierProp {
  isShowApplication: boolean;
  control: any;
  register: any;
  setValue: Function;
  watch: any;
  admissionMethodId: number;
  errors: any;
  handleChangeAdmissionMethod: Function;
  isUpdate: boolean;
  setError: Function;
}

const RegistrationDossier = ({
  isShowApplication,
  control,
  register,
  setValue,
  watch,
  admissionMethodId,
  errors,
  handleChangeAdmissionMethod,
  isUpdate,
  setError,
}: RegistrationDossierProp) => {
  const ignoreAdmissionMethods = split(process.env.REACT_APP_HIDE_ADMISSION_METHOD || "", ',').map((item) => Number(item));
  const hideAspirations = split(process.env.REACT_APP_HIDE_ASPIRATION || "", ',').map((item) => Number(item));
  const hideLanguageSkill = Number(process.env.REACT_APP_HIDE_LANGUAGE_SKILL) || 0;

  const { t } = useTranslation(["register", "home"]);
  const { classes } = useStyles();
  const options = useSelector((state: RootState) => state.registerForm.options);

  const aspirationId = watch("aspiration_id");
  const citizenId = watch("citizen_id");

  const aspirationShortName = useMemo(() => {
    return options.aspirations.filter((aspiration: any) => aspiration.id === aspirationId);
  }, [aspirationId, options]);

  const alternativeAdmissionPeriod = useMemo( () => {
     return options.admissionPeriod;
  }, [options]);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  useEffect(() => {
    if(options && options.languageSkill) {
      setValue('language_assessment', options.languageSkill[0].id);
    }
  }, [options, setValue]);

  return (
    <div className={classes.container}>
      <CardCollapse
        title={t("training_program_admission_method") + " (" + t("Lưu ý: Thí sinh chỉ đăng ký xét tuyển bổ sung 1 ngành" + ")")}
        className={classes.headerCard}
        iconTittle={<RoomPreferencesIcon className={classes.iconStep2} />}
        childrens={
          <div className={classes.containerStep2}>
            <div className={classes.formControlStep2}>
              <div className={classes.title}>{t("training_program_for_applicants")}</div>
              <div className={classes.wrapDetail}>
                <Select
                  options={options.aspirations.filter((item:any) => !hideAspirations.includes(item?.id)) ?? []}
                  className={classes.inputSelect}
                  nameRegister={"aspiration_id"}
                  control={control}
                  isForm
                  disableClearable
                  isFilterSelected={false}
                  isDisabled={isUpdate}
                  isDisableSearch={isMobileScreen}
                />
                {errors["aspiration_id"] && <div className={classes.error}>{errors["aspiration_id"]?.message}</div>}
              </div>
            </div>
            <div className={classes.formControlStep2}>
              <div className={classes.title}>{t("admission_methods")}</div>
              <div className={classes.wrapDetail}>
                <Select
                  options={options.admissionMethods ? options.admissionMethods.filter((item:any) => !ignoreAdmissionMethods.includes(item?.id)) : []}
                  className={classes.inputSelect}
                  nameRegister={"admission_method_id"}
                  control={control}
                  isForm
                  disableClearable
                  isFilterSelected={false}
                  onchange={handleChangeAdmissionMethod}
                  isDisabled={isUpdate}
                  isDisableSearch={isMobileScreen}
                />
                {errors["admission_method_id"] && (
                  <div className={classes.error}>{errors["admission_method_id"]?.message}</div>
                )}
                {admissionMethodId && (
                  <div className={classes.noteBank}>{`LƯU Ý: LỆ PHÍ XÉT TUYỂN LÀ ${
                    admissionMethodId === 1 ? "580.000đ" : "30.000đ"
                  }`}</div>
                )}
              </div>
            </div>
          </div>
        }
      />
      {isShowApplication && (
        <CardCollapse
          title={t("registration_dossier")}
          className={classes.headerCard}
          iconTittle={<NoteAltIcon className={classes.iconStep2} />}
          childrens={
            <div className={classes.containerStep3}>
              {admissionMethodId === 1 && (
                <div className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    Đợt xét tuyển bổ sung
                    <span style={{ color: "red" }}>*</span>
                    {errors["language_assessment"] && (
                      <div className={classes.error}>{errors["language_assessment"]?.message}</div>
                    )}
                  </div>
                  <Select
                    options={alternativeAdmissionPeriod || (options.listAdmissionRoundPerAspiration[aspirationId] || [])}
                    className={classes.inputSelect}
                    classNamePoper={classes.inputSelectPoper}
                    nameRegister="admission_period_id"
                    control={control}
                    isForm
                    isFilterSelected={false}
                    disableClearable
                    isDisableSearch
                    isDisabled={isUpdate}
                  />
                </div>
              )}
              {admissionMethodId === 1 && (
                <div style={hideLanguageSkill ? { display: "none" } : {}} className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    {t("language_skill", { ns: "home" })}
                    <span style={{ color: "red" }}>*</span>
                    {errors["language_assessment"] && (
                      <div className={classes.error}>{errors["language_assessment"]?.message}</div>
                    )}
                  </div>
                  <Select
                    style={hideLanguageSkill ? { display: "none" } : {}}
                    options={options.languageSkill}
                    className={classes.inputSelect}
                    classNamePoper={classes.inputSelectPoper}
                    nameRegister="language_assessment"
                    control={control}
                    isForm
                    isFilterSelected={false}
                    disableClearable
                    isDisableSearch
                  />
                </div>
              )}
              <div className={classes.formControl}>
                <div className={classes.titleStep3}>
                  {t("photo_of_citizen_id")}
                  <span style={{ color: "red" }}>*</span>
                  {errors["citizen_image"] && <div className={classes.error}>{errors["citizen_image"]?.message}</div>}
                </div>
                <InputFile
                  register={register}
                  nameRegister={"citizen_image"}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  max={2}
                />
              </div>
              {admissionMethodId === 6 && (
                  <div className={classes.formControl}>
                    <div className={classes.titleStep3}>
                      {t("Highschool_graduation_examp_test_result")} <span style={{ color: "red" }}>*</span>
                      {errors["highschool_graduation_exam_test_result_image"] && (
                          <div className={classes.error}>{errors["highschool_graduation_exam_test_result_image"]?.message}</div>
                      )}
                    </div>
                    <InputFile
                        register={register}
                        nameRegister={"highschool_graduation_exam_test_result_image"}
                        setValue={setValue}
                        watch={watch}
                        setError={setError}
                        max={10}
                    />
                  </div>
              )}
              <div className={classes.formControl}>
                <div className={classes.titleStep3}>
                  {t("academic_transcript")}
                  {` (${t("photograph_all_pages")}) `}
                  <span style={{ color: "red" }}>*</span>
                  {errors["transcript_image"] && (
                    <div className={classes.error}>{errors["transcript_image"]?.message}</div>
                  )}
                </div>
                <InputFile
                  register={register}
                  nameRegister={"transcript_image"}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  max={20}
                />
              </div>
              {admissionMethodId === 5 && (
                <div className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    {t("awards_evidence")}
                    <span style={{ color: "red" }}>*</span>
                    {errors["award_image"] && <div className={classes.error}>{errors["award_image"]?.message}</div>}
                  </div>
                  <InputFile
                    register={register}
                    nameRegister={"award_image"}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    max={10}
                  />
                </div>
              )}
              {admissionMethodId === 4 && (
                <div className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    {t("National_University_Entrance_Exam_Scores_Hanoi/Ho_Chi_Minh_City_National_University")}
                    <span style={{ color: "red" }}>*</span>
                    {errors["competency_assessment_score_image"] && (
                      <div className={classes.error}>{errors["competency_assessment_score_image"]?.message}</div>
                    )}
                  </div>
                  <InputFile
                    register={register}
                    nameRegister={"competency_assessment_score_image"}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    max={10}
                  />
                </div>
              )}
              {admissionMethodId === 1 && (
                <div className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    {t("language_proficiency_certificate")}
                    {` (${t("if_available")}) `}
                    {errors["language_certificate_image"] && (
                      <div className={classes.error}>{errors["language_certificate_image"]?.message}</div>
                    )}
                  </div>
                  <InputFile
                    register={register}
                    nameRegister={"language_certificate_image"}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    max={10}
                  />
                </div>
              )}
              {admissionMethodId === 3 && (
                <div className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    {t("language_proficiency_certificate")}
                    {` (IELTS, TOEFL, JLPT, NATTEST) `}
                    <span style={{ color: "red" }}>*</span>
                    {errors["ielts_toefl_certificate_image"] && (
                      <div className={classes.error}>{errors["ielts_toefl_certificate_image"]?.message}</div>
                    )}
                  </div>
                  <InputFile
                    register={register}
                    nameRegister={"ielts_toefl_certificate_image"}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    max={10}
                  />
                </div>
              )}
              {admissionMethodId === 2 && (
                <div className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    {t("International_Certificates")} <span style={{ color: "red" }}>*</span>
                    {errors["national_certificate_image"] && (
                      <div className={classes.error}>{errors["national_certificate_image"]?.message}</div>
                    )}
                  </div>
                  <InputFile
                    register={register}
                    nameRegister={"national_certificate_image"}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    max={10}
                  />
                </div>
              )}
              <div className={classes.formControl}>
                <div className={classes.titleStep3}>
                  {t("photo_(4x6cm)")} <span style={{ color: "red" }}>*</span>
                  {errors["personal_image"] && <div className={classes.error}>{errors["personal_image"]?.message}</div>}
                </div>
                <InputFile
                  register={register}
                  nameRegister={"personal_image"}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  max={1}
                />
              </div>
              {admissionMethodId !== 5 && admissionMethodId !== 6 && (
                <div className={classes.formControl}>
                  <div className={classes.titleStep3}>
                    {t("other_priority_certificates/awards")}
                    {` (${t("if_available")}) `}
                    {errors["award_image"] && <div className={classes.error}>{errors["award_image"]?.message}</div>}
                  </div>
                  <InputFile
                    register={register}
                    nameRegister={"award_image"}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    max={10}
                  />
                </div>
              )}
              {admissionMethodId === 6 && (
                  <div className={classes.formControl}>
                    <div className={classes.titleStep3}>
                      {t("Extra_International_Certificates")}
                      {` (${t("if_available")}) `}
                      {errors["extra_international_certificate_images"] && <div className={classes.error}>{errors["extra_international_certificate_images"]?.message}</div>}
                    </div>
                    <InputFile
                        register={register}
                        nameRegister={"extra_international_certificate_images"}
                        setValue={setValue}
                        watch={watch}
                        setError={setError}
                        max={10}
                    />
                  </div>
              )}
              <div className={classes.formControl}>
                <div className={classes.titleStep3}>
                  {t("proof_of_transfer_of_admission_fee")}
                  <span style={{ color: "red" }}>*</span>
                  {errors["fee_payment_proof_image"] && (
                    <div className={classes.error}>{errors["fee_payment_proof_image"]?.message}</div>
                  )}
                </div>
                <InputFile
                  register={register}
                  nameRegister={"fee_payment_proof_image"}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  max={2}
                />
              </div>
              <div className={classes.formControl}>
                <div className={classes.titleStep3}>
                  {t("personal_opinion")}
                  {` (${t("if_available")}) `}
                  {errors["personal_opinion"] && (
                    <div className={classes.error}>{errors["personal_opinion"]?.message}</div>
                  )}
                </div>
                <textarea rows={5} className={classes.inputArea} {...register("personal_opinion")}></textarea>
              </div>
              <PaymentInformation
                admissionMethodId={admissionMethodId}
                aspirationShortedName={aspirationShortName[0]?.code}
                citizenId={citizenId}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default RegistrationDossier;
