import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    "& .MuiOutlinedInput-root": {
      minHeight: "30px !important",
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "8px !important",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      border: "none",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5",
    },
    "& .MuiAutocomplete-tag": {
      height: "20px",
    },
    "& .MuiChip-deleteIcon": {
      fontSize: "14px !important",
    },
    "& .MuiChip-label": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
    },
    "& .MuiAutocomplete-input": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "0 !important",
    },
    "& .MuiAutocomplete-root fieldset": {
      border: "1px solid #DDD",
    },
    "& .Mui-focused fieldset": {
      border: "1px solid #1070bd !important",
    },
  },
  textField: {
    fontFamily: "Roboto !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    zIndex: 99,
  },
  noOptions: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
  },
  groupName: {
    color: "#1070bd",
    padding: "4px 10px",
  },
  groupItem: {
    padding: "0 5px",
  },
  poper: {
    zIndex: 1300,
  },
}));
