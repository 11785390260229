import React from "react";

interface IProps {
  children?: React.ReactNode;
  value?: string;
  onCopy?: () => void;
}

export const copyToClipboard =
  (value: string, onCopy: (() => void) | undefined) => () => {
    const textarea = document.createElement("textarea");
    textarea.innerText = value || "";

    document.body.appendChild(textarea);
    textarea.select();

    document.execCommand("copy");
    textarea.remove();

    if (onCopy) {
      onCopy();
    }
  };

const CopyToClipboard: React.FC<IProps> = (props) => {
  const { children, value, onCopy } = props;

  return <div onClick={copyToClipboard(value || "", onCopy)}>{children}</div>;
};
export default CopyToClipboard;
