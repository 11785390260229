import { debounce } from "@mui/material";
import Loading from "components/Loading/Loading";
import ModalTask from "components/Modal/ModalTask";
import PagingDetail from "components/PagingDetail";
import RoleBaseComponent from "components/RoleBaseComponent";
import TaskDetail from "components/TaskDetail";
import { ImageComment, LooseObject } from "models/common";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import { setSelectedTab } from "store/detailStudentContactSlice";
import { getStudentContactForOption, resetfieldState } from "store/studentContactSlice";
import {
  addComment,
  changeResult,
  createTask,
  editTask,
  fetchTasks,
  getTaskResults,
  getTaskTypes,
  resetTasks,
  setContactsForTask,
} from "store/taskSlice";
import { STUDENT_CONTACT_TYPE } from "utils/constant";
import { useContextIsFilterStudent } from "..";
import BaseButton from "./../../../../../../components/Buttons/BaseButton/index";
import { useStyles } from "./styles";

export default function TaskStudent() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const currentContact = useSelector((state: RootState) => state.detailStudentContact.contact);
  const [contact, setContact] = useState<LooseObject>({});
  const [isShowModalAddTask, setIsShowModalAddTask] = useState<boolean>(false);
  const [isSearchContact, setIsSearchContact] = useState<boolean>(false);
  const { state } = useLocation();
  const [data, setData] = useState<LooseObject[]>([]);
  const taskResult = useSelector((state: RootState) => state.tasks.taskResult);
  const tasks = useSelector((state: RootState) => state.tasks.tasks);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const initialized = useRef(false);
  const [idTaskNavigate, setIdTaskNavigate] = useState<number | null>(null);
  const [listIdTaskShowAddCmt, setListIdTaskShowAddCmt] = useState<number[]>([]);
  const [isShowModalEditTask, setIsShowModalEditTask] = useState<boolean>(false);
  const [currentTask, setCurrentTask] = useState<LooseObject | null>(null);
  const [isFilter, setIsFilter] = useContextIsFilterStudent();

  useEffect(() => {
    if (state?.isFilter) {
      setIsFilter(state?.isFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getDataOption = async () => {
    setLoading(true);
    await dispatch(getTaskTypes());
    await dispatch(getTaskResults());
    setIsFirstLoad(false);
    setLoading(false);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDataOption();
    }
    dispatch(setSelectedTab("tasks"));
    return () => {
      dispatch(resetTasks());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await dispatch(fetchTasks(id));
    if (!isFirstLoad) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    if (currentContact) {
      setContact(currentContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContact]);

  useEffect(() => {
    let list: LooseObject[] = [];
    tasks.forEach((item: LooseObject, index: number) => {
      let obj = JSON.parse(JSON.stringify(item));
      if (index < 3 || (state?.idTaskNavigate && state?.idTaskNavigate === item?.id)) {
        obj.show = true;
      } else {
        obj.show = false;
      }
      list.push(obj);
    });
    setData(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scroll = useCallback(
    debounce((idTaskNavigate) => {
      const element = document.getElementById(`task_${idTaskNavigate}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      setIdTaskNavigate(null);
    }, 500),
    []
  );

  useEffect(() => {
    if (state?.idTaskNavigate) {
      setIdTaskNavigate(state?.idTaskNavigate);
    }
  }, [state]);

  useEffect(() => {
    if (data.length !== 0 && idTaskNavigate) {
      scroll(idTaskNavigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSearchContact = async (text: string) => {
    setIsSearchContact(true);
    const contacts = await dispatch(getStudentContactForOption(text));
    if (contacts) {
      let listContact: LooseObject[] = [];
      contacts.forEach((item: LooseObject) => {
        listContact.push({ id: item?.id, label: item?.full_name });
      });
      dispatch(setContactsForTask(listContact));
    }
    setIsSearchContact(false);
  };

  const handleCreateTask = async (data: LooseObject) => {
    setLoading(true);
    let newData = { ...data };
    newData.contact_type = STUDENT_CONTACT_TYPE;
    const response = await dispatch(createTask(newData));
    if (response) {
      toast.success<void>(t("create_task_successful"));
      setIsShowModalAddTask(false);
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleEditTask = async (id: number, data: LooseObject) => {
    setLoading(true);
    let newData = { ...data };
    newData.contact_type = STUDENT_CONTACT_TYPE;
    const response = await dispatch(editTask(id, newData));
    if (response) {
      toast.success<void>(t("edit_task_successful"));
      setIsShowModalEditTask(false);
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  const handleChangeTask = (task: LooseObject) => {
    const index = data.findIndex((item: LooseObject) => item?.id === task?.id);
    if (index !== -1) {
      const newData = [...data];
      newData[index] = task;
      setData(newData);
    }
  };

  const handleTongleCmt = (taskId: number) => {
    let list: number[] = [...listIdTaskShowAddCmt];
    if (list.includes(taskId)) {
      list = list.filter((item: number) => item !== taskId);
    } else {
      list.push(taskId);
    }
    setListIdTaskShowAddCmt(list);
  };

  const handleAddComment = async (taskId: any, data: any, listImage: ImageComment[]) => {
    setLoading(true);
    const result = await dispatch(addComment(taskId, data, listImage));
    if (result) {
      toast.success<void>(t("add_comment_successful"));
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
    return result;
  };

  const handleOpenModalEdit = (task: LooseObject) => {
    setCurrentTask(task);
    setIsShowModalEditTask(true);
  };

  const handleClickUser = (contactId: number, taskId: number) => {
    dispatch(resetfieldState());
    navigate(`/studentContact/${contactId}/tasks`, {
      state: { idTaskNavigate: taskId },
    });
  };

  const handleChangeResult = async (taskId: number, result: LooseObject) => {
    setLoading(true);
    const data: LooseObject = {
      type: result?.task_type_id,
      result: result?.id,
    };
    const check = await dispatch(changeResult(taskId, data));
    if (check) {
      toast.success<void>(t("change_result_successful"));
      fetchData();
    } else {
      toast.error<void>(t("system_error,_please_try_again_later"));
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <PagingDetail
        current={contact?.index + 1}
        total={contact?.total}
        onClickBack={() => navigate("/studentContact")}
        onClickPrevious={() => navigate(`/studentContact/${contact?.prev_id}/tasks`, { state: { isFilter: isFilter } })}
        onClickNext={() => navigate(`/studentContact/${contact?.next_id}/tasks`, { state: { isFilter: isFilter } })}
      />
      <div className={classes.containerContent}>
        <div className={classes.wrapHeader}>
          <div className={classes.labelHeader}>{t("tasks")}</div>
          <RoleBaseComponent permissions={["student_contact_edit"]}>
            <BaseButton
              title={t("add_task")}
              startIcon={<span className={classes.checkList}></span>}
              className={classes.btnAddTask}
              onClick={() => setIsShowModalAddTask(true)}
            />
          </RoleBaseComponent>
        </div>
        {taskResult.length !== 0 &&
          (data.length !== 0 ? (
            <>
              {data.map((item: LooseObject) => (
                <span id={`task_${item?.id}`} key={`${item.id} - b`}>
                  <TaskDetail
                    task={item}
                    taskResult={taskResult}
                    handleChangeTask={handleChangeTask}
                    handleTongleCmt={handleTongleCmt}
                    handleAddComment={handleAddComment}
                    listIdTaskShowAddCmt={listIdTaskShowAddCmt}
                    handleOpenModalEdit={handleOpenModalEdit}
                    handleClickUser={handleClickUser}
                    permissions={["student_contact_edit"]}
                    handleChangeResult={handleChangeResult}
                  />
                </span>
              ))}
            </>
          ) : (
            <div className={classes.wrapNodata}>
              <div className={classes.noData}>{t("no_data_found")}</div>
            </div>
          ))}
      </div>
      <PagingDetail
        current={contact?.index + 1}
        total={contact?.total}
        onClickBack={() => navigate("/studentContact")}
        onClickPrevious={() => navigate(`/studentContact/${contact?.prev_id}/tasks`, { state: { isFilter: isFilter } })}
        onClickNext={() => navigate(`/studentContact/${contact?.next_id}/tasks`, { state: { isFilter: isFilter } })}
      />
      {isShowModalAddTask && (
        <ModalTask
          isOpen={isShowModalAddTask}
          title="create_task"
          onClose={() => setIsShowModalAddTask(false)}
          onConfirm={handleCreateTask}
          handleSearchContact={handleSearchContact}
          isSearchContact={isSearchContact}
          contact={[{ id: contact?.id, label: contact?.full_name }]}
        />
      )}
      {isShowModalEditTask && (
        <ModalTask
          isOpen={isShowModalEditTask}
          title="edit_task"
          onClose={() => setIsShowModalEditTask(false)}
          onConfirm={handleEditTask}
          handleSearchContact={handleSearchContact}
          isSearchContact={isSearchContact}
          contact={[{ id: contact?.id, label: contact?.full_name }]}
          task={currentTask ?? {}}
        />
      )}
    </>
  );
}
