import {Checkbox, Divider} from "@mui/material";
import BaseButton from "components/Buttons/BaseButton";
import Select from "components/Select";
import { IModuleField, LooseObject } from "models/common";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import moment from "moment";

interface ImportStep2Prop {
  moduleField: IModuleField[];
  handleNextStep3: Function;
  handleBackToStep1: Function;
  handleSearchUnit: Function;
  handleSearchListContact: Function;
  className?: string;
  listUnit: LooseObject[];
  listContact: LooseObject[];
  fieldCreate: LooseObject[];
}

export default function ImportStep2({
  className,
  handleNextStep3,
  moduleField,
  handleBackToStep1,
  handleSearchUnit,
  listUnit,
  listContact,
  handleSearchListContact,
  fieldCreate,
}: ImportStep2Prop) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<LooseObject>({});
  const [formMap, setFormMap] = useState<LooseObject[]>([]);
  const [isCreateNewList, setIsCreateNewList] = useState<boolean>(true);
  const [importAndUpdate, setImportAndUpdate] = useState<boolean>(true);
  const [isSearchUnit, setIsSearchUnit] = useState<boolean>(false);
  const [isSearchListContact, setIsSearchListContact] = useState<boolean>(false);
  const [listName, setListName] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [unit, setUnit] = useState<LooseObject[]>([]);
  const [listContactValue, setlistContactValue] = useState<LooseObject[]>([]);
  const [optionListContact, setOptionListContact] = useState<LooseObject[]>([]);
  const [optionUnit, setOptionUnit] = useState<LooseObject[]>([]);
  const [shouldOverwriteAspirations, setshouldOverwriteAspirations] = useState<boolean>(false);

  useEffect(() => {
    let option: LooseObject[] = [];
    listContactValue.forEach((item: LooseObject) => {
      if (listContact.findIndex((element: LooseObject) => element?.id === item?.id) === -1) {
        option.push(item);
      }
    });
    setOptionListContact([...listContact, ...option]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listContact]);

  useEffect(() => {
    let option: LooseObject[] = [];
    unit.forEach((item: LooseObject) => {
      if (listUnit.findIndex((element: LooseObject) => element?.id === item?.id) === -1) {
        option.push(item);
      }
    });
    setOptionUnit([...listUnit, ...option]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listUnit]);

  const handleChangeUnit = (newUnit: LooseObject[]) => {
    let option: LooseObject[] = [];
    newUnit.forEach((item: LooseObject) => {
      if (listUnit.findIndex((element: LooseObject) => element?.id === item?.id) === -1) {
        option.push(item);
      }
    });
    setOptionUnit([...listUnit, ...option]);
    setUnit(newUnit);
  };

  useEffect(() => {
    const form = [...fieldCreate];
    const rangeModule = moduleField.length;
    form.forEach((item: LooseObject) => {
      if (item?.defautIndex < rangeModule) {
        item.row = moduleField[item?.defautIndex];
      } else {
        item.row = {
          id: -1,
          label: t("not_selected"),
          row: "",
        };
      }
    });
    console.log({form});
    setFormMap(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSelect = (index: number, value: LooseObject) => {
    const err = { ...error };
    delete err["form"];
    setError(err);
    const newForm = [...formMap];
    if (value === null) {
      newForm[index].row = {
        id: -1,
        label: t("not_selected"),
        row: "",
      };
    } else {
      newForm[index].row = value;
    }
    setFormMap(newForm);
  };

  const handleChangeUnitSearch = async (text: string) => {
    setIsSearchUnit(true);
    await handleSearchUnit(text);
    setIsSearchUnit(false);
  };

  const handleSearchList = async (text: string) => {
    setIsSearchListContact(true);
    await handleSearchListContact(text);
    setIsSearchListContact(false);
  };

  const validate = () => {
    let err: LooseObject = {};
    let fieldMap: LooseObject[] = [];
    formMap.forEach((item: LooseObject) => {
      if (item.require && item?.row?.id === -1) {
        err.form = "please_choose_the_mapping_of_require_field";
        setError(err);
        return;
      }
      fieldMap.push({ name: item?.name, id: item?.row?.id });
    });

    if (isCreateNewList) {
      if (!listName || listName.trim() === "") {
        err.listName = "this_field_is_required";
      } else if (listName.trim().length > 255) {
        err.listName = "this_field_must_not_exceed_255_characters";
      }
      if (source.trim().length > 255) {
        err.source = "this_field_must_not_exceed_255_characters";
      }
      if (Array.isArray(unit)) {
        unit.forEach((item: LooseObject) => {
          if (String(item?.id).trim().length > 255) {
            err.unit = "this_field_must_not_exceed_255_characters";
            return;
          }
        });
      }
    } else if (importAndUpdate) {

    } else if (!isCreateNewList && listContactValue.length === 0) {
      err.listContactValue = "this_field_is_required";
    }
    setError(err);
    if (Object.keys(err).length === 0) {
      return fieldMap;
    } else {
      return false;
    }
  };

  const handleImportContact = async () => {
    const field = await validate();
    if (field) {
      let data: LooseObject[] = [
        { name: "step", value: 2 },
        { name: "mapping", value: field },
        { name: "should_overwrite_aspirations", value: shouldOverwriteAspirations}
      ];

      if (isCreateNewList) {
        data.push({ name: "is_new_list", value: 1 });
        data.push({ name: "name", value: listName.trim() });
        data.push({ name: "tags", value: unit.map((item) => item?.id) });
        data.push({ name: "source", value: source.trim() });
      } else if (importAndUpdate) {

      } {
        data.push({ name: "contact_categories", value: listContactValue.map((item: LooseObject) => item?.id) });
      }
      handleNextStep3(data);
    }
  };

  return (
    <div className={`${classes.container} ${className}`}>
      <p className={classes.labelStep}>{`${t("step")} 2: ${t("confirm_field_mappings")}`}</p>
      <p className={classes.explainStep}>{t("explain_import_step_2")}</p>
      <hr className={classes.hr} />
      <table>
        <thead>
          <tr>
            <th>{t("module_field")}</th>
            <th>{t("header_row")}</th>
            <th>{t("row")}</th>
          </tr>
        </thead>
        <tbody>
          {formMap.map((item: LooseObject, index: number) => (
            <tr key={`${item.name} - ${index}`}>
              <td>
                {t(item.label)}
                {item.require && <span className={classes.require}>{` *`}</span>}
              </td>
              <td>
                <Select
                  options={moduleField ?? []}
                  data={item?.row}
                  className={classes.inputSelect}
                  onchange={(value: LooseObject) => handleChangeSelect(index, value)}
                  isObjectSubmit
                />
              </td>
              <td className={classes.rowInfor} title={item?.row?.row}>
                {item?.row?.row &&
                  (typeof item?.row?.row === "string"
                    ? item?.row?.row
                    : item?.label === "date_of_acceptance" &&
                      typeof item?.row?.row === "object" &&
                      moment(item?.row?.row).isValid()
                    ? moment(item?.row?.row).format("YYYY-MM-DD")
                    : String(item?.row?.row))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {error && error?.form && <div className={classes.wrapErrorForm}>{t(error?.form)}</div>}
      <div className={classes.containerList}>
        <div className={classes.wrapChooseList}>
          <div className={`${!isCreateNewList && importAndUpdate && classes.isActiveTab}`}
               onClick={() => {
                 setIsCreateNewList(false);
                 setImportAndUpdate(true);
               }}
          >
            {t('import_and_update')}
          </div>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <div
            className={`${!isCreateNewList && !importAndUpdate && classes.isActiveTab} ${classes.titleAddExistList}`}
            onClick={() => {
              setIsCreateNewList(false);
              setImportAndUpdate(false);
            }}
          >
            {t("add_to_existing_list")}
          </div>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <div
            className={`${isCreateNewList  && !importAndUpdate && classes.isActiveTab} ${classes.titleCreateList}`}
            onClick={() => {
              setIsCreateNewList(true);
              setImportAndUpdate(false);
            }}
          >
            {t("create_new_list")}
          </div>
        </div>
        {isCreateNewList ? (
          <div>
            <div className={classes.formControl}>
              <div className={classes.label}>
                {t("list_name")} <span className={classes.require}>{` *`}</span>
              </div>
              <input
                type="text"
                className={classes.input}
                value={listName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const err = { ...error };
                  delete err["listName"];
                  setError(err);
                  setListName(e.target.value);
                }}
              />
            </div>
            {error && error?.listName && <div className={classes.wrapErrorList}>{t(error?.listName)}</div>}
            <div className={classes.formControl}>
              <div className={classes.label}>{t("unit")}</div>
              <Select
                options={optionUnit ?? []}
                className={classes.inputSelectList}
                onChangeSearch={handleChangeUnitSearch}
                isMultiple
                data={unit}
                onchange={(value: any) => {
                  handleChangeUnit(value);
                  const err = { ...error };
                  delete err["unit"];
                  setError(err);
                }}
                isSearching={isSearchUnit}
                isAddOption={true}
                isObjectSubmit
              />
            </div>
            {error && error?.unit && <div className={classes.wrapErrorList}>{t(error?.unit)}</div>}
            <div className={classes.formControl}>
              <div className={classes.label}>{t("source")}</div>
              <input
                type="text"
                className={classes.input}
                value={source}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSource(e.target.value);
                  const err = { ...error };
                  delete err["source"];
                  setError(err);
                }}
              />
            </div>
            {error && error?.source && <div className={classes.wrapErrorList}>{t(error?.source)}</div>}
          </div>
        ) : importAndUpdate ? (
           <div className={classes.formControl}>
             <input type={'checkbox'} name={'should_overwrite_aspiration'} id={'should_overwrite_aspiration'} onChange={() => setshouldOverwriteAspirations(!shouldOverwriteAspirations)}/>
             <label htmlFor={'should_overwrite_aspiration'}>{t('should_overwrite_aspiration')}</label>
           </div>
        ) : (
          <>
            <div className={classes.formControl}>
              <div className={classes.label}>
                {t("list_name")} <span className={classes.require}>{` *`}</span>
              </div>
              <Select
                options={optionListContact ?? []}
                className={classes.inputSelectList}
                onChangeSearch={handleSearchList}
                data={listContactValue}
                isMultiple
                isSearching={isSearchListContact}
                onchange={(value: any) => {
                  setlistContactValue(value);
                  const err = { ...error };
                  delete err["listContactValue"];
                  setError(err);
                }}
                isObjectSubmit
              />
            </div>
            {error && error?.listContactValue && (
              <div className={classes.wrapErrorList}>{t(error?.listContactValue)}</div>
            )}
          </>
        )}
      </div>
      <BaseButton title={t("back")} className={classes.btnBack} onClick={() => handleBackToStep1()} />
      <BaseButton title={t("import")} className={classes.btnNext} onClick={handleImportContact} />
    </div>
  );
}
