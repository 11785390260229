import ModalConfirm from "components/Modal/ModalConfirm";
import RoleBaseComponent from "components/RoleBaseComponent";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState, useAppDispatch } from "redux/store";
import { getStudentContact, setContactDetail, setSelectedTab } from "store/detailStudentContactSlice";
import { deleteStudentContact } from "store/studentContactSlice";
import logoFail from "../../../../../assets/images/FAILED.svg";
import logoSucces from "../../../../../assets/images/SUCCEEDED.svg";
import Loading from "../../../../../components/Loading/Loading";
import { LooseObject } from "../../../../../models/common";
import BaseButton from "./../../../../../components/Buttons/BaseButton/index";
import { useStyles } from "./styles";

type ContextType = [isFilter: boolean | null, setIsFilter: Function];

export function useContextIsFilterStudent() {
  return useOutletContext<ContextType>();
}

export default function DetailStudentContact() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentContact, setCurrentContact] = useState<LooseObject>({});
  const selectedTab = useSelector((state: RootState) => state.detailStudentContact.selectedTab);
  const contact = useSelector((state: RootState) => state.detailStudentContact.contact);
  const [isOpenModalConfirmDelete, setIsOpenModalConfirmDelete] = useState<boolean>(false);
  const { state } = useLocation();
  const getData = async () => {
    setLoading(true);
    const response = await dispatch(getStudentContact({ id: id, isFilter: state?.isFilter }));
    if (response.meta.requestStatus === "rejected") {
      toast.error<void>(t("no_data_found"));
    }
    setLoading(false);
  };
  const [isFilter, setIsFilter] = useState<boolean | null>(state?.isFilter);

  useEffect(() => {
    if (state?.idTaskNavigate) {
      setIsFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    if (Object.keys(contact).length !== 0) {
      setCurrentContact(contact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedTab(""));
      dispatch(setContactDetail({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteContact = async () => {
    setLoading(true);
    try {
      const result = await dispatch(deleteStudentContact([id]));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success<void>(t("delete_student_contact_successful"));
        navigate("/studentContact");
      } else {
        toast.error<void>(t("system_error,_please_try_again_later"));
      }
    } catch (error) {
      setLoading(false);
      setIsOpenModalConfirmDelete(false);
    }
    setIsOpenModalConfirmDelete(false);
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{t("contact_details")}</title>
      </Helmet>
      {loading && <Loading />}
      <div className="container">
        <div className={classes.headerPage}>
          <div className={classes.wrapTittle}>
            <p>{currentContact?.full_name}</p>
            {selectedTab === "" && contact?.is_success && !contact?.reject_reason_id?.id && (
              <img src={logoSucces} alt="logoSuccess" className={classes.logo} />
            )}
            {selectedTab === "" && contact?.reject_reason_id?.id && (
              <img src={logoFail} alt="logoFail" className={classes.logo} />
            )}
          </div>
          <div>
            <BaseButton
              title={t("profile")}
              className={`${classes.btn} ${classes.btnNormal} ${selectedTab === "" && classes.isActiveBtn}`}
              onClick={() => {
                if (selectedTab === "") {
                  return;
                }
                navigate(`/studentContact/${id}`, { state: { isFilter: isFilter } });
              }}
            />
            <BaseButton
              title={t("list_contact_point")}
              className={`${classes.btn} ${classes.btnNormal} ${
                selectedTab === "list_contact_point" && classes.isActiveBtn
              }`}
              onClick={() => {
                if (selectedTab === "list_contact_point") {
                  return;
                }
                navigate(`/studentContact/${id}/listContactPoint`, { state: { isFilter: isFilter } });
              }}
            />
            <BaseButton
              title={t("tasks")}
              className={`${classes.btn} ${classes.btnNormal} ${selectedTab === "tasks" && classes.isActiveBtn}`}
              onClick={() => {
                if (selectedTab === "tasks") {
                  return;
                }
                navigate(`/studentContact/${id}/tasks`, { state: { isFilter: isFilter } });
              }}
            />
            <BaseButton
              title={t("activity_log")}
              className={`${classes.btn} ${classes.btnLog} ${selectedTab === "activity_log" && classes.isActiveBtn}`}
              onClick={() => {
                if (selectedTab === "activity_log") {
                  return;
                }
                navigate(`/studentContact/${id}/activityLog`, { state: { isFilter: isFilter } });
              }}
            />
            <RoleBaseComponent permissions={["student_contact_delete"]}>
              <BaseButton
                title={t("delete")}
                className={`${classes.btn} ${classes.btnDelete} `}
                onClick={() => setIsOpenModalConfirmDelete(true)}
              />
            </RoleBaseComponent>
          </div>
        </div>
        <Outlet context={[isFilter, setIsFilter]} />
      </div>
      {isOpenModalConfirmDelete && (
        <ModalConfirm
          isOpen={isOpenModalConfirmDelete}
          title={t("confirmation")}
          htmlContent={
            <p>
              {t("are_you_sure_to_remove")} <span className={classes.nameDelete}>{`${t("this_contact")}?`}</span>
            </p>
          }
          onClose={() => {
            setIsOpenModalConfirmDelete(false);
          }}
          onConfirm={() => handleDeleteContact()}
        />
      )}
    </>
  );
}
