import { LooseObject } from "models/common";
import { useStyles } from "./styles";
import moment from "moment";
import HTMLReactParser from "html-react-parser";

interface LogDetailProp {
  log: LooseObject;
  className?: string;
}

export default function LogDetail({ log, className }: LogDetailProp) {
  const { classes } = useStyles();
  return (
    <div className={`${classes.container} ${className} ${log?.type === 1 ? classes.line : ""}`}>
      <div className={classes.time}>{log?.time && moment(log?.time).format("YYYY-MM-DD HH:mm")}</div>
      <div className={classes.note}>
        {Array.isArray(log?.note)
          ? log?.note.map((item: LooseObject, index: number) => <li key={index}>{HTMLReactParser(String(item))}</li>)
          : HTMLReactParser(String(log?.note))}
        {log?.file?.webhook_recoding_file && (
          <audio controls className={classes.audio}>
            <source src={log?.file?.webhook_recoding_file} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
    </div>
  );
}
